import { 
    SET_CREATE_FILE_LOADING,
    SET_CREATE_FILE_SUCCESS,
    SET_CREATE_FILE_ERROR,
    SET_LIST_FILES_LOADING,
    SET_LIST_FILES_SUCCESS,
    SET_LIST_FILES_ERROR,
    SET_UPDATE_FILE_LOADING,
    SET_UPDATE_FILE_SUCCESS,
    SET_UPDATE_FILE_ERROR,
    SET_GET_FILE_LOADING,
    SET_GET_FILE_SUCCESS,
    SET_GET_FILE_ERROR
} from './constants';

// Create File Actions
export function setCreateFileLoading() {
    return {
        type: SET_CREATE_FILE_LOADING
    }
}

export function setCreateFileSuccess(payload: any, metadata?: any) {
    return {
        type: SET_CREATE_FILE_SUCCESS,
        payload,
        metadata
    }
}

export function setCreateFileError(error: any) {
    return {
        type: SET_CREATE_FILE_ERROR,
        error
    }
}

// List Files Actions
export function setListFilesLoading() {
    return {
        type: SET_LIST_FILES_LOADING
    }
}

export function setListFilesSuccess(payload: any, metadata?: any) {
    return {
        type: SET_LIST_FILES_SUCCESS,
        payload,
        metadata
    }
}

export function setListFilesError(error: any) {
    return {
        type: SET_LIST_FILES_ERROR,
        error
    }
}

// Update File Actions
export function setUpdateFileLoading() {
    return {
        type: SET_UPDATE_FILE_LOADING
    }
}

export function setUpdateFileSuccess(payload: any, metadata?: any) {
    return {
        type: SET_UPDATE_FILE_SUCCESS,
        payload,
        metadata
    }
}

export function setUpdateFileError(error: any) {
    return {
        type: SET_UPDATE_FILE_ERROR,
        error
    }
}

// Get File Actions
export function setGetFileLoading() {
    return {
        type: SET_GET_FILE_LOADING
    }
}

export function setGetFileSuccess(payload: any, metadata?: any) {
    return {
        type: SET_GET_FILE_SUCCESS,
        payload,
        metadata
    }
}

export function setGetFileError(error: any) {
    return {
        type: SET_GET_FILE_ERROR,
        error
    }
}
