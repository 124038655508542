import { Outlet, useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "./lib/hooks";
import { getOrganization, listOrganizations } from "./lib/app/organization/thunk";
import { useEffect } from "react";
import { getOrganizationTeams } from "./lib/app/teams/thunk";
import { loadUserProfile } from "./lib/app/platform/thunk";

export default function Wrapper () {

    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { organization } = useParams()
    const organizations = useAppSelector(state => state.app.organizations.list)
    const _organization = useAppSelector(state => state.app.organizations.get)
    const profile = useAppSelector((state) => state.app.platform.profile);

    useEffect(() => {
        if (profile.state === "success" && profile.data.id) {
            dispatch(listOrganizations(profile.data.id))
        }
    }, [ profile ])


  useEffect(() => {
    if (organization) {
        dispatch(getOrganization(organization))
        dispatch(getOrganizationTeams(organization))
    }
}, [ organization ])


    useEffect(() => {
      dispatch(loadUserProfile())
  }, [])

    useEffect(() => {
        if (organizations.state === "error") {
            navigate('/welcome')   
        }
    }, [ organizations ])

    return (
        <Outlet />
    )
}