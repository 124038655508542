import { BaseObjectState, BaseArrayState } from "../../types";
import {
    SET_FEEDBACK_CREATE_LOADING,
    SET_FEEDBACK_CREATE_SUCCESS,
    SET_FEEDBACK_CREATE_ERROR,
    SET_FEEDBACK_UPDATE_LOADING,
    SET_FEEDBACK_UPDATE_SUCCESS,
    SET_FEEDBACK_UPDATE_ERROR,
    SET_FEEDBACK_DELETE_LOADING,
    SET_FEEDBACK_DELETE_SUCCESS,
    SET_FEEDBACK_DELETE_ERROR,
    SET_FEEDBACK_LIST_LOADING,
    SET_FEEDBACK_LIST_SUCCESS,
    SET_FEEDBACK_LIST_ERROR,
    SET_FEEDBACK_GET_LOADING,
    SET_FEEDBACK_GET_SUCCESS,
    SET_FEEDBACK_GET_ERROR
} from "./constants";

interface FeedbackState {
    create: BaseObjectState,
    update: BaseObjectState,
    delete: BaseObjectState,
    list: BaseArrayState,
    get: BaseObjectState
}

const INITIAL_STATE: FeedbackState = {
    create: {
        state: "pending",
        data: {},
        error: {}
    },
    update: {
        state: "pending",
        data: {},
        error: {}
    },
    delete: {
        state: "pending",
        data: {},
        error: {}
    },
    list: {
        state: "loading",
        data: [],
        error: {},
        metadata: {}
    },
    get: {
        state: "loading",
        data: {},
        error: {}
    }
}

export const FeedbackReducer = (state = INITIAL_STATE, action: any) => {
    switch (action.type) {
        case SET_FEEDBACK_CREATE_LOADING:
            return {
                ...state,
                create: {
                    state: "loading",
                    data: {},
                    error: ''
                }
            }
        case SET_FEEDBACK_CREATE_SUCCESS:
            return {
                ...state,
                create: {
                    state: "success",
                    data: action.payload,
                    error: ''
                }
            }
        case SET_FEEDBACK_CREATE_ERROR:
            return {
                ...state,
                create: {
                    state: "error",
                    data: {},
                    error: action.error || 'Something went wrong'
                }
            }
        case SET_FEEDBACK_UPDATE_LOADING:
            return {
                ...state,
                update: {
                    state: "loading",
                    data: {},
                    error: ''
                }
            }
        case SET_FEEDBACK_UPDATE_SUCCESS:
            return {
                ...state,
                update: {
                    state: "success",
                    data: action.payload,
                    error: ''
                }
            }
        case SET_FEEDBACK_UPDATE_ERROR:
            return {
                ...state,
                update: {
                    state: "error",
                    data: {},
                    error: action.error || 'Something went wrong'
                }
            }
        case SET_FEEDBACK_DELETE_LOADING:
            return {
                ...state,
                delete: {
                    state: "loading",
                    data: {},
                    error: ''
                }
            }
        case SET_FEEDBACK_DELETE_SUCCESS:
            return {
                ...state,
                delete: {
                    state: "success",
                    data: action.payload,
                    error: ''
                }
            }
        case SET_FEEDBACK_DELETE_ERROR:
            return {
                ...state,
                delete: {
                    state: "error",
                    data: {},
                    error: action.error || 'Something went wrong'
                }
            }
        case SET_FEEDBACK_LIST_LOADING:
            return {
                ...state,
                list: {
                    state: "loading",
                    data: [],
                    error: '',
                    metadata: {}
                }
            }
        case SET_FEEDBACK_LIST_SUCCESS:
            return {
                ...state,
                list: {
                    state: "success",
                    data: action.payload,
                    error: '',
                    metadata: action.metadata
                }
            }
        case SET_FEEDBACK_LIST_ERROR:
            return {
                ...state,
                list: {
                    state: "error",
                    data: [],
                    error: action.error || 'Something went wrong',
                    metadata: {}
                }
            }
        case SET_FEEDBACK_GET_LOADING:
            return {
                ...state,
                get: {
                    state: "loading",
                    data: {},
                    error: ''
                }
            }
        case SET_FEEDBACK_GET_SUCCESS:
            return {
                ...state,
                get: {
                    state: "success",
                    data: action.payload,
                    error: ''
                }
            }
        case SET_FEEDBACK_GET_ERROR:
            return {
                ...state,
                get: {
                    state: "error",
                    data: {},
                    error: action.error || 'Something went wrong'
                }
            }
        default:
            return state;
    }
}
