import {
    Box,
    Divider,
    Flex,
    Grid,
    Heading,
    IconButton,
    Stack,
    TabProps,
    Text,
    useDisclosure,
  } from "@chakra-ui/react";
  import { ReactElement, ReactNode, useEffect } from "react";
  import { Outlet, useNavigate, useParams } from "react-router-dom";
  import { useAppDispatch, useAppSelector } from "~/lib/hooks";
  import { loadUserProfile } from "~/lib/app/platform/thunk";
  import {
    ArrowUpRightIcon,
    BlocksIcon,
    BrainIcon,
    HeadsetIcon,
    LayoutDashboardIcon,
    PhoneCallIcon,
    PlusIcon,
    PresentationIcon,
    Settings2Icon,
    Users2Icon,
    ZapIcon,
  } from "lucide-react";
  import { useCookies } from "react-cookie";
  import _ from "lodash";
  import { Button } from "~/components/ui/button";
  import { HomeSidebar } from "~/@components/SidebarV2";
  import { getOrganization, listOrganizations } from "~/lib/app/organization/thunk";
  import { getOrganizationTeams } from "~/lib/app/teams/thunk";
import { CommandDialog, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList, CommandSeparator } from "~/components/ui/command";
  
  interface TabItemProps extends TabProps {
    icon?: ReactNode;
  }
  
  export function TabItem(props: TabItemProps) {
    return (
      <Flex h={10} align={"center"} gap={2}>
        {props.icon}
        <Text fontWeight={"semibold"} fontSize={14}>
          {props.title}
        </Text>
      </Flex>
    );
  }
  
  export default function OrganizationShell() {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { organization } = useParams()
    const organizations = useAppSelector(state => state.app.organizations.list)
    const _organization = useAppSelector(state => state.app.organizations.get)
    const profile = useAppSelector((state) => state.app.platform.profile.data);
    const { isOpen, onOpen, onClose, onToggle } = useDisclosure()
    const [cookies, setCookie, removeCookie] = useCookies([
      "accessToken",
      "refreshToken",
    ]);

    useEffect(() => {
      const down = (e: KeyboardEvent) => {
        console.log(e)
         if (e.key === 'k' && (e.metaKey || e.ctrlKey)) {
            e.preventDefault()
            onToggle()
         }
      }
 
      document.addEventListener('keydown', down)
      return () => document.removeEventListener('keydown', down)
   }, [])
  
    return (
      <Flex>
        <CommandDialog open={isOpen} onOpenChange={onToggle}>
            <CommandInput placeholder="Type a command or search..." />
            <CommandList>
               <CommandEmpty>No actions found.</CommandEmpty>
               <CommandGroup heading="Suggestions">
                  <CommandItem onClick={() => {
                    navigate(`/app/${organization}/home`)
                  }}>
                     <BlocksIcon className="mr-2 size-4" />
                     <span>Home</span>
                  </CommandItem>
                  <CommandItem onClick={() => {
                    navigate(`/app/${organization}/member-access`)
                  }}>
                     <Users2Icon className="mr-2 size-4" />
                     <span>Team & Access</span>
                  </CommandItem>
                  <CommandItem onClick={() => {
                    navigate(`/app/${organization}/settings`)
                  }}>
                     <Settings2Icon className="mr-2 size-4" />
                     <span>Settings</span>
                  </CommandItem>
               </CommandGroup>
               <CommandSeparator />
               
            </CommandList>
         </CommandDialog>
        <Box w={"240px"}>
          <HomeSidebar />
        </Box>
        <Stack flex={1}>
          <Outlet />
        </Stack>
      </Flex>
    );
  }
  