import { 
    SET_ORGANIZATION_CREATE_LOADING,
    SET_ORGANIZATION_CREATE_SUCCESS,
    SET_ORGANIZATION_CREATE_ERROR,
    SET_ORGANIZATION_LIST_LOADING,
    SET_ORGANIZATION_LIST_SUCCESS,
    SET_ORGANIZATION_LIST_ERROR,
    SET_ORGANIZATION_UPDATE_LOADING,
    SET_ORGANIZATION_UPDATE_SUCCESS,
    SET_ORGANIZATION_UPDATE_ERROR,
    SET_ORGANIZATION_GET_LOADING,
    SET_ORGANIZATION_GET_SUCCESS,
    SET_ORGANIZATION_GET_ERROR
} from './constants';

// Organization Create Actions
export function setOrganizationCreateLoading() {
    return {
        type: SET_ORGANIZATION_CREATE_LOADING
    }
}

export function setOrganizationCreateSuccess(payload: any, metadata?: any) {
    return {
        type: SET_ORGANIZATION_CREATE_SUCCESS,
        payload,
        metadata
    }
}

export function setOrganizationCreateError(error: any) {
    return {
        type: SET_ORGANIZATION_CREATE_ERROR,
        error
    }
}

// Organization List Actions
export function setOrganizationListLoading() {
    return {
        type: SET_ORGANIZATION_LIST_LOADING
    }
}

export function setOrganizationListSuccess(payload: any, metadata?: any) {
    return {
        type: SET_ORGANIZATION_LIST_SUCCESS,
        payload,
        metadata
    }
}

export function setOrganizationListError(error: any) {
    return {
        type: SET_ORGANIZATION_LIST_ERROR,
        error
    }
}

// Organization Update Actions
export function setOrganizationUpdateLoading() {
    return {
        type: SET_ORGANIZATION_UPDATE_LOADING
    }
}

export function setOrganizationUpdateSuccess(payload: any, metadata?: any) {
    return {
        type: SET_ORGANIZATION_UPDATE_SUCCESS,
        payload,
        metadata
    }
}

export function setOrganizationUpdateError(error: any) {
    return {
        type: SET_ORGANIZATION_UPDATE_ERROR,
        error
    }
}

// Organization Get Actions
export function setOrganizationGetLoading() {
    return {
        type: SET_ORGANIZATION_GET_LOADING
    }
}

export function setOrganizationGetSuccess(payload: any, metadata?: any) {
    return {
        type: SET_ORGANIZATION_GET_SUCCESS,
        payload,
        metadata
    }
}

export function setOrganizationGetError(error: any) {
    return {
        type: SET_ORGANIZATION_GET_ERROR,
        error
    }
}
