import { AppDispatch } from "~/lib/store";
import { setCreateTaskRunsLoading, setCreateTaskRunsSuccess, setDeleteTaskRunsLoading, setDeleteTaskRunsSuccess, setGetTaskRunsLoading, setGetTaskRunsSuccess, setListTaskRunsLoading, setListTaskRunsSuccess } from "./actions";
import { axios } from "~/utils/axios.loader";

export const listTaskRuns = (organization_id: string, task_id: string, { size = 10, page = 1 }) => async (dispatch: AppDispatch) => {
    dispatch(setListTaskRunsLoading())
    try {
        const request = await axios.get(`/tasks/organizations/${organization_id}/${task_id}/runs?page=${page}&page_size=${size}`)
        dispatch(setListTaskRunsSuccess(request.data.tasks || []))
    } catch (err) {

    }
}

export const listTaskRunsByAssistant = (organization_id: string, assistant_id: string, { size = 10, page = 1 }) => async (dispatch: AppDispatch) => {
    dispatch(setListTaskRunsLoading())
    try {
        const request = await axios.get(`/tasks/organizations/${organization_id}/assistants/${assistant_id}/runs/assistant-runs?page=${page}&page_size=${size}&sort_value=-created_at`)
        dispatch(setListTaskRunsSuccess(request.data.tasks || []))
    } catch (err) {

    }
}

export const triggerTaskRun = (organization_id: string, task_id: string, callback?: () => any) => async (disaptch: AppDispatch) => {
    disaptch(setCreateTaskRunsLoading())
    try {
        const request = await axios.post(`/tasks/organizations/${organization_id}/${task_id}/runs`, {
            "task_id": task_id
        })
        disaptch(setCreateTaskRunsSuccess(request.data))
        callback?.()
    } catch (err) {

    }
}

export const getTaskRun = (organization_id: string, task_id: string, run_id: string) => async (dispatch: AppDispatch) => {
    dispatch(setGetTaskRunsLoading())
    try {
        const request = await axios.get(`/tasks/organizations/${organization_id}/${task_id}/runs/${run_id}`)
        dispatch(setGetTaskRunsSuccess(request.data))
    } catch (err) {

    }
}

export const deleteTaskRun = (organization_id: string, task_id: string, run_id: string) => async (dispatch: AppDispatch) => {
    dispatch(setDeleteTaskRunsLoading())
    try {
        await axios.delete(`/tasks/organizations/${organization_id}/${task_id}/runs/${run_id}`)
        dispatch(setDeleteTaskRunsSuccess(run_id))
    } catch (err) {

    }
}