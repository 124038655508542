import {
    SET_CREATE_TASK_RUNS_LOADING,
    SET_CREATE_TASK_RUNS_SUCCESS,
    SET_CREATE_TASK_RUNS_ERROR,
    SET_UPDATE_TASK_RUNS_LOADING,
    SET_UPDATE_TASK_RUNS_SUCCESS,
    SET_UPDATE_TASK_RUNS_ERROR,
    SET_DELETE_TASK_RUNS_LOADING,
    SET_DELETE_TASK_RUNS_SUCCESS,
    SET_DELETE_TASK_RUNS_ERROR,
    SET_GET_TASK_RUNS_LOADING,
    SET_GET_TASK_RUNS_SUCCESS,
    SET_GET_TASK_RUNS_ERROR,
    SET_LIST_TASK_RUNS_LOADING,
    SET_LIST_TASK_RUNS_SUCCESS,
    SET_LIST_TASK_RUNS_ERROR
} from './constants';

// Action Creators for task_runs
export const setCreateTaskRunsLoading = () => ({
    type: SET_CREATE_TASK_RUNS_LOADING,
});

export const setCreateTaskRunsSuccess = (taskRun: any) => ({
    type: SET_CREATE_TASK_RUNS_SUCCESS,
    payload: taskRun,
});

export const setCreateTaskRunsError = (error: string) => ({
    type: SET_CREATE_TASK_RUNS_ERROR,
    payload: error,
});

export const setUpdateTaskRunsLoading = () => ({
    type: SET_UPDATE_TASK_RUNS_LOADING,
});

export const setUpdateTaskRunsSuccess = (taskRun: any) => ({
    type: SET_UPDATE_TASK_RUNS_SUCCESS,
    payload: taskRun,
});

export const setUpdateTaskRunsError = (error: string) => ({
    type: SET_UPDATE_TASK_RUNS_ERROR,
    payload: error,
});

export const setDeleteTaskRunsLoading = () => ({
    type: SET_DELETE_TASK_RUNS_LOADING,
});

export const setDeleteTaskRunsSuccess = (taskRunId: string) => ({
    type: SET_DELETE_TASK_RUNS_SUCCESS,
    payload: taskRunId,
});

export const setDeleteTaskRunsError = (error: string) => ({
    type: SET_DELETE_TASK_RUNS_ERROR,
    payload: error,
});

export const setGetTaskRunsLoading = () => ({
    type: SET_GET_TASK_RUNS_LOADING,
});

export const setGetTaskRunsSuccess = (taskRun: any) => ({
    type: SET_GET_TASK_RUNS_SUCCESS,
    payload: taskRun,
});

export const setGetTaskRunsError = (error: string) => ({
    type: SET_GET_TASK_RUNS_ERROR,
    payload: error,
});

export const setListTaskRunsLoading = () => ({
    type: SET_LIST_TASK_RUNS_LOADING,
});

export const setListTaskRunsSuccess = (taskRuns: any[]) => ({
    type: SET_LIST_TASK_RUNS_SUCCESS,
    payload: taskRuns,
});

export const setListTaskRunsError = (error: string) => ({
    type: SET_LIST_TASK_RUNS_ERROR,
    payload: error,
});
