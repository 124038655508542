import { axios, axiosv2 } from "../../../utils/axios.loader";
import { AppDispatch } from "../../store";
// import { setAgentGetLoading, setAgentGetSuccess } from "../assistants/actions";
import { setCompany, setConfigError, setConfigLoading, setConfigSuccess, setListAgentsLoading, setListAgentsSuccess, setUserProfile } from "./actions";

export const loadUserProfile = () => async (dispatch: AppDispatch) => {
    try {
        const request = await axios.get('/users/me')
        const response = request.data;
        dispatch(setUserProfile(response))
    } catch (err) {

    }
}

export const loadCompanyByUser = () => async (dispatch: AppDispatch) => {
    try {
        const request = await axios.get('/company/')
        const response = request.data;
        dispatch(setCompany(response))
        dispatch(loadAgents(response.id))
    } catch (err) {

    }
}

export const loadAgents = (organization: string, page = 1, size = 25, keyword = '', filters: { status?: "ONLINE" | "OFFLINE"} = {}, sort: string = 'status,-updated_at', team?: string) => async (dispatch: AppDispatch) => {
    dispatch(setListAgentsLoading())
    try {
        const request = await axiosv2.get(`/agent/organization/${organization}?page=${page}&page_size=${size}&sort_value=${sort}${keyword ? `&filters=name[contains]=${keyword}` : ''}${filters.status ? `&filters=status[eq]=${filters.status}` : ''}${team ? `&filters=team_id[eq]=${team}` : ''}`)
        const response = request.data;
        dispatch(setListAgentsSuccess(response))
    } catch (err) {

    }
}

export const searchAgents = async (organization: string, page = 1, size = 25, keyword = '', filters: { status?: "ONLINE" | "OFFLINE"} = {}, sort: string = 'status,-updated_at') => {
    try {
        const request = await axiosv2.get(`/agent/organization/${organization}?page=${page}&page_size=${size}&sort_value=${sort}${keyword ? `&filters=name[contains]=${keyword}` : ''}${filters.status ? `&filters=status[eq]=${filters.status}` : ''}`)
        const response = request.data;
        return response?.agents?.map((v: any) => {
            return {
                label: v.name,
                value: v.id,
                providerId: v.provider_id
            }
        })
    } catch (err) {
        return []
    }
}

export const loadConfig = () => async (dispatch: AppDispatch) => {
    dispatch(setConfigLoading())
    try {
        const request = await axios.get(`/agent/model-config`)
        const response = request.data;
        dispatch(setConfigSuccess(response))
    } catch (err) {
        dispatch(setConfigError())
    }
}