import { SET_GET_VOICES_ERROR, SET_GET_VOICES_LOADING, SET_GET_VOICES_SUCCESS } from "./constants";

export function setGetVoicesLoading () {
    return {
        type: SET_GET_VOICES_LOADING
    }
}

export function setGetVoicesSuccess (payload: any, metadata?: any) {
    return {
        type: SET_GET_VOICES_SUCCESS,
        payload,
        metadata
    }
}

export function setGetVoicesError (error: any) {
    return {
        type: SET_GET_VOICES_ERROR,
        error
    }
}