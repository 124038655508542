import { Avatar, Box, Center, Flex, IconButton, Image, Img, Spinner, Stack, Text, useToast } from "@chakra-ui/react";
import Vapi from "@vapi-ai/web";
import Lottie from "lottie-react";
import { MicIcon, MicOffIcon, PhoneOffIcon } from "lucide-react";
import { useCallback, useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import AIAnimation from "../../assets/animation/ai.json"
import { axios } from "../../utils/axios.loader";
import Logo from "../../assets/img/logo-full.png";

export default function Demo () {
    const [hasCallStarted, setHasCallStarted] = useState(false);
    const [isMuted, setIsMuted] = useState(false);
    const vapi = useRef<Vapi | undefined>();
    const [params] = useSearchParams()
    const [time, setTime] = useState(0);
    const timer = useRef<NodeJS.Timeout | number | undefined>();
    const [isTestLoading, setTestLoading] = useState(false);
    const toast = useToast()
    const [assistant, setAssistantDetails] = useState<any>({})
    const [hasDisconnected, setHasDisconnected] = useState(false)

    const getAssistantDetails = async () => {
        const response = await axios.get(`/agent/${params.get("assistantId")}`, {
            headers: {
                Authorization: `Bearer demo-key`
            }
        })
        setAssistantDetails(response.data)
    }

    useEffect(() => {
        getAssistantDetails()
        if (!vapi.current) {
          if (params.get("key")) {
            vapi.current = new Vapi(
                params.get("key")!
            );
            vapi.current.on("call-end", () => {
              if (timer.current !== null) {
                clearInterval(timer.current);
              }
              setHasCallStarted(false);
            });
          }
        }
      }, []);

      const initiateCall =  useCallback(async () => {
        await vapi.current?.start(
            params.get("agent")!
          );
          setTestLoading(false);
          setHasCallStarted(true);
          if (!timer.current) {
            timer.current = setInterval(() => {
              setTime((v) => v + 1);
            }, 1000);
          }
        //   toast({
        //     title: `Call started. You can now speak with ${
        //       assistant.data?.name || ""
        //     }`,
        //   });
      }, [])

      useEffect(() => {
        if (vapi.current) {
            setTestLoading(true);
            initiateCall()
            vapi.current?.on("call-start", () => {
                setHasCallStarted(true);
            })
        }
      }, [vapi.current])

    return (
        <Box pos={"relative"}>
            <Box
                zIndex={4}
                backdropFilter={"blur(5px)"}
                background={"transparent"}
                display={"block"}
                pos={"absolute"}
                w={"100vw"}
                h={"100vh"}
            >
                <Center flexDirection={"column"} h={"80vh"}>
                    <Image mb={4} w={250} src={Logo} />
                    {/* {isTestLoading ? <Box textAlign={"center"}>
                        <Spinner size={"sm"} />
                        <Text>Please wait</Text>
                    </Box> :  */}
                    {!hasDisconnected ? <><Box cursor={"pointer"} _before={{
                    pos: "absolute",
                    background: `linear-gradient(to left, #DD71B8 10%, #FDDE3C')`,
                    filter: 'blur(10px)',
                    left: 0,
                    borderRadius: "full",
                    top: 0,
                    content: '" "',
                    h: "full",
                    w: "full"
                    }} pos={"relative"} height={12}>
                    <Box 
                        height={250}
                        width={250}>
                        <Lottie
                        animationData={AIAnimation}
                    />
                    </Box>
                    </Box>
                    <Avatar size={"sm"} src={assistant?.profile_pic} name={assistant?.name} />
                    <Text mt={2} fontWeight={600}>You are now talking with {assistant?.name}</Text>
                    <Text>{assistant?.organization?.name}</Text></> : <Stack alignItems={"center"}>
                      <Image h={100} src="https://i.guim.co.uk/img/static/sys-images/Guardian/Pix/pictures/2015/4/21/1429629869293/d8e257bb-a76d-49e7-989a-39e07196a915-2060x1236.jpeg?width=620&quality=85&auto=format&fit=max&s=bcefd8888dd640e9225928efda75f2cd" />
                      <Text size={"xl"} fontWeight={600}>Thank you. Have a great day</Text>
                      </Stack>}
                </Center>
            </Box>
            {!hasDisconnected ? <Flex
                alignItems={"center"}
                zIndex={4}
                gap={6}
                rounded={36}
                background={"white"}
                left={["calc(50% - 92px)", "calc(50% - 90px)"]}
                display={hasCallStarted ? "flex" : "none"}
                bottom={12}
                py={2}
                px={2}
                border={"1px solid #cacaca"}
                pos={"fixed"}
            >
                <IconButton
                onClick={() => {
                    if (isMuted) {
                    vapi?.current?.setMuted(false);
                    setIsMuted(false);
                    } else {
                    vapi?.current?.setMuted(true);
                    setIsMuted(true);
                    }
                }}
                variant={"outline"}
                border={"1px solid #cacaca"}
                colorScheme={isMuted ? "primary" : "none"}
                rounded={"full"}
                icon={isMuted ? <MicOffIcon size={20} /> : <MicIcon size={20} />}
                aria-label=""
                />
                <Text fontWeight={700}>{`${String(
                Math.floor((time % 3600) / 60)
                ).padStart(2, "0")}:${String(time % 60).padStart(2, "0")}`}</Text>
                <IconButton
                colorScheme="red"
                onClick={() => {
                  setHasDisconnected(true)
                    vapi.current?.stop();
                    if (timer.current !== null) {
                    clearInterval(timer.current);
                    }
                    setHasCallStarted(false);
                }}
                rounded={"full"}
                icon={<PhoneOffIcon size={20} />}
                aria-label=""
                />
            </Flex> : <></>}
        </Box>
    )
}