import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Stack,
  Text,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import Page from "../../@components/Page";
import { Field, Form, Formik } from "formik";
import { useAppDispatch, useAppSelector } from "../../lib/hooks";
import { updateOrganization } from "../../lib/app/organization/thunk";
import { useEffect } from "react";
import {
  getTeam
} from "../../lib/app/team/thunk";

export default function Settings() {
  const _organization = useAppSelector((state) => state.app.organizations.get);
  const _organizationUpdated = useAppSelector(
    (state) => state.app.organizations.update
  );
  const dispatch = useAppDispatch();
  const toast = useToast();

  useEffect(() => {
    if (_organization.data.id) {
      // dispatch(getTeam(_organization.data.id));
    }
  }, [_organization.data]);

  return (
    <Page subtitle="Change the details for your current organization" title="Settings">
      <Stack px={4} mt={3}>
        <Formik
          enableReinitialize
          onSubmit={(values) => {
            dispatch(
              updateOrganization(_organization.data?.id, {
                ..._organization.data,
                updated_at: new Date().toISOString(),
                name: values.name,
                description: values.description,
              })
            );
            toast({
              title: "Your details have been updated",
              status: "success",
            });
          }}
          initialValues={{
            name: _organization.data?.name,
            description: _organization.data?.description,
          }}
        >
          {({ errors, touched, values }) => (
            <Form
              style={{
                width: "40%",
              }}
            >
              <Stack mt={4}>
                <FormControl isInvalid={!!errors.name}>
                  <FormLabel fontSize={"sm"}>
                    Organization Name
                  </FormLabel>
                  <Field
                    fontSize={"sm"}
                    placeholder={"Enter your organisation name"}
                    validate={(v: string) => {
                      let error;
                      if (!v) {
                        error = "Organization name is required";
                      }
                      return error;
                    }}
                    as={Input}
                    name="name"
                  />
                  <FormErrorMessage>
                    {errors.name as string}
                  </FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={!!errors.name}>
                  <FormLabel fontSize={"sm"}>About</FormLabel>
                  <Field
                    fontSize={"sm"}
                    placeholder={"Enter about your organisation"}
                    validate={(v: string) => {
                      let error;
                      if (!v) {
                        error = "Description is required";
                      }
                      return error;
                    }}
                    as={Textarea}
                    rows={4}
                    name="description"
                  />
                  <FormErrorMessage>
                    {errors.name as string}
                  </FormErrorMessage>
                </FormControl>
                <Flex justifyContent={"end"}>
                  <Button
                    isLoading={_organizationUpdated.state === "loading"}
                    colorScheme="primary"
                    fontSize={"sm"}
                    type="submit"
                  >
                    Update
                  </Button>
                </Flex>
              </Stack>
            </Form>
          )}
        </Formik>
      </Stack>
    </Page>
  );
}
