import { CLEAR_MESSAGES, DISPATCH_MESSAGE, RECIEVE_MESSAGE, SET_SOCKET_CONNECTING, SET_SOCKET_ERROR, SET_SOCKET_SUCCESS } from "./constants";

export function setSocketConnecting () {
    return {
        type: SET_SOCKET_CONNECTING
    }
}

export function setSocketSuccess () {
    return {
        type: SET_SOCKET_SUCCESS
    }
}

export function setSocketError () {
    return {
        type: SET_SOCKET_ERROR
    }
}

export function onDispatchMessage (message: string) {
    return {
        type: DISPATCH_MESSAGE,
        payload: message
    }
}

export function resetPlayground () {
    return {
        type: CLEAR_MESSAGES
    }
}

export function onRecieveMessage (message: string) {
    return {
        type: RECIEVE_MESSAGE,
        payload: message
    }
}