import { BaseObjectState, BaseArrayState } from "../../types";
import {
    SET_TEAM_CREATE_LOADING,
    SET_TEAM_CREATE_SUCCESS,
    SET_TEAM_CREATE_ERROR,
    SET_TEAM_LIST_LOADING,
    SET_TEAM_LIST_SUCCESS,
    SET_TEAM_LIST_ERROR,
    SET_TEAM_UPDATE_LOADING,
    SET_TEAM_UPDATE_SUCCESS,
    SET_TEAM_UPDATE_ERROR,
    SET_TEAM_DELETE_LOADING,
    SET_TEAM_DELETE_SUCCESS,
    SET_TEAM_DELETE_ERROR,
    SET_TEAM_GET_LOADING,
    SET_TEAM_GET_SUCCESS,
    SET_TEAM_GET_ERROR
} from "./constants";

interface TeamState {
    create: BaseObjectState,
    update: BaseObjectState,
    delete: BaseObjectState,
    list: BaseArrayState,
    get: BaseObjectState
}

const INITIAL_STATE: TeamState = {
    create: {
        state: "pending",
        data: {},
        error: {}
    },
    update: {
        state: "pending",
        data: {},
        error: {}
    },
    delete: {
        state: "pending",
        data: {},
        error: {}
    },
    list: {
        state: "pending",
        data: [],
        error: {}
    },
    get: {
        state: "pending",
        data: {},
        error: {}
    }
}

export const TeamReducer = (state = INITIAL_STATE, action: any) => {
    switch (action.type) {
        case SET_TEAM_CREATE_LOADING:
            return {
                ...state,
                create: {
                    state: "loading",
                    data: {},
                    error: ''
                }
            }
        case SET_TEAM_CREATE_SUCCESS:
            return {
                ...state,
                create: {
                    state: "success",
                    data: action.payload,
                    error: ''
                }
            }
        case SET_TEAM_CREATE_ERROR:
            return {
                ...state,
                create: {
                    state: "error",
                    data: {},
                    error: action.error || 'Something went wrong'
                }
            }
        case SET_TEAM_LIST_LOADING:
            return {
                ...state,
                list: {
                    state: "loading",
                    data: [],
                    error: ''
                }
            }
        case SET_TEAM_LIST_SUCCESS:
            return {
                ...state,
                list: {
                    state: "success",
                    data: action.payload,
                    error: '',
                    metadata: action.metadata
                }
            }
        case SET_TEAM_LIST_ERROR:
            return {
                ...state,
                list: {
                    state: "error",
                    data: [],
                    error: action.error || 'Something went wrong'
                }
            }
        case SET_TEAM_UPDATE_LOADING:
            return {
                ...state,
                update: {
                    state: "loading",
                    data: {},
                    error: ''
                }
            }
        case SET_TEAM_UPDATE_SUCCESS:
            return {
                ...state,
                update: {
                    state: "success",
                    data: action.payload,
                    error: ''
                }
            }
        case SET_TEAM_UPDATE_ERROR:
            return {
                ...state,
                update: {
                    state: "error",
                    data: {},
                    error: action.error || 'Something went wrong'
                }
            }
        case SET_TEAM_DELETE_LOADING:
            return {
                ...state,
                delete: {
                    state: "loading",
                    data: {},
                    error: ''
                }
            }
        case SET_TEAM_DELETE_SUCCESS:
            return {
                ...state,
                delete: {
                    state: "success",
                    data: action.payload,
                    error: ''
                }
            }
        case SET_TEAM_DELETE_ERROR:
            return {
                ...state,
                delete: {
                    state: "error",
                    data: {},
                    error: action.error || 'Something went wrong'
                }
            }
        case SET_TEAM_GET_LOADING:
            return {
                ...state,
                get: {
                    state: "loading",
                    data: {},
                    error: ''
                }
            }
        case SET_TEAM_GET_SUCCESS:
            return {
                ...state,
                get: {
                    state: "success",
                    data: action.payload,
                    error: ''
                }
            }
        case SET_TEAM_GET_ERROR:
            return {
                ...state,
                get: {
                    state: "error",
                    data: {},
                    error: action.error || 'Something went wrong'
                }
            }
        default:
            return state;
    }
}
