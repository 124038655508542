'use client'

import * as React from 'react'
import type { DialogProps } from '@radix-ui/react-dialog'
import { MagnifyingGlassIcon } from '@radix-ui/react-icons'
import { Command as CommandPrimitive } from 'cmdk'

import { ny } from '~/lib/utils'
import { Dialog, DialogContent } from '~/components/ui/dialog'

const Command = React.forwardRef<
   React.ElementRef<typeof CommandPrimitive>,
   React.ComponentPropsWithoutRef<typeof CommandPrimitive>
>(({ className, ...props }, ref) => (
   <CommandPrimitive
      ref={ref}
      className={ny(
         'bg-popover text-popover-foreground flex size-full flex-col overflow-hidden rounded-md',
         className,
      )}
      {...props}
   />
))
Command.displayName = CommandPrimitive.displayName

interface CommandDialogProps extends DialogProps {}

function CommandDialog({ children, ...props }: CommandDialogProps) {
   return (
      <Dialog {...props}>
         <DialogContent className="overflow-hidden p-0">
            <Command className="[&_[cmdk-group-heading]]:text-muted-foreground [&_[cmdk-group-heading]]:px-2 [&_[cmdk-group-heading]]:font-medium [&_[cmdk-group]:not([hidden])_~[cmdk-group]]:pt-0 [&_[cmdk-group]]:px-2 [&_[cmdk-input-wrapper]_svg]:size-5 [&_[cmdk-input]]:h-12 [&_[cmdk-item]]:px-2 [&_[cmdk-item]]:py-3 [&_[cmdk-item]_svg]:size-5">
               {children}
            </Command>
         </DialogContent>
      </Dialog>
   )
}

const CommandInput = React.forwardRef<
   React.ElementRef<typeof CommandPrimitive.Input>,
   React.ComponentPropsWithoutRef<typeof CommandPrimitive.Input>
>(({ className, ...props }, ref) => (
   <div className="flex items-center border-b px-3" cmdk-input-wrapper="">
      <MagnifyingGlassIcon className="mr-2 size-4 shrink-0 opacity-50" />
      <CommandPrimitive.Input
         ref={ref}
         className={ny(
            'placeholder:text-muted-foreground flex h-10 w-full rounded-md bg-transparent py-3 text-sm outline-none disabled:cursor-not-allowed disabled:opacity-50',
            className,
         )}
         {...props}
      />
   </div>
))

CommandInput.displayName = CommandPrimitive.Input.displayName

const CommandList = React.forwardRef<
   React.ElementRef<typeof CommandPrimitive.List>,
   React.ComponentPropsWithoutRef<typeof CommandPrimitive.List>
>(({ className, ...props }, ref) => (
   <CommandPrimitive.List
      ref={ref}
      className={ny('max-h-[300px] overflow-y-auto overflow-x-hidden', className)}
      {...props}
   />
))

CommandList.displayName = CommandPrimitive.List.displayName

const CommandEmpty = React.forwardRef<
   React.ElementRef<typeof CommandPrimitive.Empty>,
   React.ComponentPropsWithoutRef<typeof CommandPrimitive.Empty>
>((props, ref) => (
   <CommandPrimitive.Empty
      ref={ref}
      className="py-6 text-center text-sm"
      {...props}
   />
))

CommandEmpty.displayName = CommandPrimitive.Empty.displayName

const CommandGroup = React.forwardRef<
   React.ElementRef<typeof CommandPrimitive.Group>,
   React.ComponentPropsWithoutRef<typeof CommandPrimitive.Group>
>(({ className, ...props }, ref) => (
   <CommandPrimitive.Group
      ref={ref}
      className={ny(
         'text-foreground [&_[cmdk-group-heading]]:text-muted-foreground overflow-hidden p-1 [&_[cmdk-group-heading]]:px-2 [&_[cmdk-group-heading]]:py-1.5 [&_[cmdk-group-heading]]:text-xs [&_[cmdk-group-heading]]:font-medium',
         className,
      )}
      {...props}
   />
))

CommandGroup.displayName = CommandPrimitive.Group.displayName

const CommandSeparator = React.forwardRef<
   React.ElementRef<typeof CommandPrimitive.Separator>,
   React.ComponentPropsWithoutRef<typeof CommandPrimitive.Separator>
>(({ className, ...props }, ref) => (
   <CommandPrimitive.Separator
      ref={ref}
      className={ny('bg-border -mx-1 h-px', className)}
      {...props}
   />
))
CommandSeparator.displayName = CommandPrimitive.Separator.displayName

const CommandItem = React.forwardRef<
   React.ElementRef<typeof CommandPrimitive.Item>,
   React.ComponentPropsWithoutRef<typeof CommandPrimitive.Item>
>(({ className, ...props }, ref) => (
   <CommandPrimitive.Item
      ref={ref}
      className={ny(
         'aria-selected:bg-accent aria-selected:text-accent-foreground relative flex cursor-default select-none items-center rounded-sm px-2 py-1.5 text-sm outline-none data-[disabled]:pointer-events-none data-[disabled]:opacity-50',
         className,
      )}
      {...props}
   />
))

CommandItem.displayName = CommandPrimitive.Item.displayName

function CommandShortcut({
   className,
   ...props
}: React.HTMLAttributes<HTMLSpanElement>) {
   return (
      <span
         className={ny(
            'text-muted-foreground ml-auto text-xs tracking-widest',
            className,
         )}
         {...props}
      />
   )
}
CommandShortcut.displayName = 'CommandShortcut'

export {
   Command,
   CommandDialog,
   CommandInput,
   CommandList,
   CommandEmpty,
   CommandGroup,
   CommandItem,
   CommandShortcut,
   CommandSeparator,
}
