import { BaseObjectState } from "../../types"
import { RESET_AGENT_CREATE, RESET_AGENT_UPDATE, SET_AGENT_ANALYSIS_ERROR, SET_AGENT_ANALYSIS_LOADING, SET_AGENT_ANALYSIS_SUCCESS, SET_AGENT_CREATE_ERROR, SET_AGENT_CREATE_LOADING, SET_AGENT_CREATE_SUCCESS, SET_AGENT_UPDATE_ERROR, SET_AGENT_UPDATE_LOADING, SET_AGENT_UPDATE_SUCCESS, SET_GET_AGENT_ERROR, SET_GET_AGENT_LOADING, SET_GET_AGENT_RESET, SET_GET_AGENT_SUCCESS } from "./constants"

interface AssistantState {
    create: BaseObjectState
    update: BaseObjectState,
    get: BaseObjectState,
    analysis: BaseObjectState
}

const INITIAL_STATE: AssistantState = {
    create: {
        state: "pending",
        data: {},
        error: {}
    },
    update: {
        state: "pending",
        data: {},
        error: {}
    },
    get: {
        state: "pending",
        data: {},
        error: {}
    },
    analysis: {
        state: "pending",
        data: {},
        error: {}
    }
}

export const AssistantsReducer = (state = INITIAL_STATE, action: any) => {
    switch (action.type) {
        case SET_AGENT_CREATE_LOADING:
            return {
                ...state,
                create: {
                    state: "loading",
                    data: {},
                    error: {}
                }
            }
        case SET_AGENT_CREATE_SUCCESS:
            return {
                ...state,
                create: {
                    state: "success",
                    data: action.payload,
                    error: {}
                }
            }
        case SET_AGENT_CREATE_ERROR:
            return {
                ...state,
                create: {
                    state: "error",
                    data: {},
                    error: action.error
                }
            }
        case RESET_AGENT_CREATE:
            return {
                ...state,
                create: {
                    state: "pending",
                    data: {},
                    error: {}
                }
            }
        case SET_AGENT_UPDATE_LOADING:
            return {
                ...state,
                update: {
                    state: "loading",
                    data: {},
                    error: {}
                }
            }
        case SET_AGENT_UPDATE_SUCCESS:
            return {
                ...state,
                update: {
                    state: "success",
                    data: action.payload,
                    error: {}
                },
                get: {
                    state: "success",
                    data: action.payload,
                    metadata: action.metadata,
                    error: {}
                }
            }
        case SET_AGENT_UPDATE_ERROR:
            return {
                ...state,
                update: {
                    state: "error",
                    data: {},
                    error: action.error
                }
            }
        case RESET_AGENT_UPDATE:
            return {
                ...state,
                update: {
                    state: "pending",
                    data: {},
                    error: {}
                }
            }
        case SET_GET_AGENT_LOADING:
            return {
                ...state,
                get: {
                    state: "loading",
                    data: {},
                    error: {}
                }
            }
        case SET_GET_AGENT_RESET:
            return {
                ...state,
                get: {
                    state: "pending",
                    data: {},
                    error: {}
                }
            }
        case SET_GET_AGENT_SUCCESS:
            return {
                ...state,
                get: {
                    state: "success",
                    data: action.payload,
                    metadata: action.metadata,
                    error: {}
                }
            }
        case SET_GET_AGENT_ERROR:
            return {
                ...state,
                get: {
                    state: "error",
                    data: {},
                    error: action.error,
                    metadata: action.metadata
                }
            }
        case SET_AGENT_ANALYSIS_LOADING:
            return {
                ...state,
                analysis: {
                    state: "loading",
                    data: {},
                    error: {}
                }
            }
        case SET_AGENT_ANALYSIS_SUCCESS:
            return {
                ...state,
                analysis: {
                    state: "success",
                    data: action.payload,
                    metadata: action.metadata,
                    error: {}
                }
            }
        case SET_AGENT_ANALYSIS_ERROR:
            return {
                ...state,
                analysis: {
                    state: "error",
                    data: {},
                    error: action.error,
                    metadata: action.metadata
                }
            }
        default:
            return state
    }
}