import { inputAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(inputAnatomy.keys);

const baseStyle = definePartsStyle({
  field: {
    _hover: {
      borderColor: "var(--chakra-colors-primary-500)",
      boxShadow: "0 0 0 1px var(--chakra-colors-primary-500)",
    },
    _focusVisible: {
      borderColor: "var(--chakra-colors-primary-500)",
      boxShadow: "0 0 0 1px var(--chakra-colors-primary-500)",
    },
    _focus: {
      borderColor: "var(--chakra-colors-primary-500)",
      boxShadow: "0 0 0 1px var(--chakra-colors-primary-500)",
    },
  },
});

export const inputTheme = defineMultiStyleConfig({ baseStyle });
