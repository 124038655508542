import { axios } from "../../../utils/axios.loader";
import { AppDispatch } from "../../store";
import { setOrganizationCreateLoading, setOrganizationCreateSuccess, setOrganizationGetLoading, setOrganizationGetSuccess, setOrganizationListError, setOrganizationListSuccess, setOrganizationUpdateLoading, setOrganizationUpdateSuccess } from "./actions";

export const listOrganizations = (id?: string) => async (dispatch: AppDispatch) => {
    try {
        const request = await axios.get(`/organization/user/${id}?page_size=100`)
        const { organizations, total_count, page, page_size } = request.data
        dispatch(setOrganizationListSuccess(organizations, {
            total_count,
            page,
            page_size
        }))
    } catch (err) {
        dispatch(setOrganizationListError("Failed to get organisations"))
    }
}

export const getOrganization = (organization: string) => async (dispatch: AppDispatch) => {
    dispatch(setOrganizationGetLoading())
    try {
        const request = await axios.get(`/organization/${organization}`)
        const response = request.data
        dispatch(setOrganizationGetSuccess(response))
    } catch (err) {}
}

export const createOrganization = (payload: any) => async (dispatch: AppDispatch) => {
    dispatch(setOrganizationCreateLoading())
    try {
        const request = await axios.post(`/organization/`, {
            ...payload,
            created_at: new Date().toISOString(),
            updated_at: new Date().toISOString()
        })
        const response = request.data
        dispatch(setOrganizationCreateSuccess(response))
    } catch (err) {}
}

export const updateOrganization = (organization: string, payload: any) => async (dispatch: AppDispatch) => {
    dispatch(setOrganizationUpdateLoading())
    try {
        const request = await axios.put(`/organization/${organization}`, {
            ...payload,
            updated_at: new Date().toISOString()
        })
        const response = request.data
        dispatch(setOrganizationUpdateSuccess(response))
        dispatch(setOrganizationGetSuccess(response))
    } catch (err) {}
}