import {
  Flex,
  Stack,
  Text,
  Select as CoreSelect,
  IconButton,
  Grid,
  Box,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Tooltip,
  useToast,
  Button,
  Input,
  CircularProgress,
  CircularProgressLabel,
  Skeleton,
} from "@chakra-ui/react";
import Page from "../../@components/Page";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronsLeftIcon,
  ChevronsRightIcon,
  ChevronsUpDownIcon,
  CopyIcon,
  EllipsisVerticalIcon,
  PauseIcon,
  PlayIcon,
} from "lucide-react";
import { useAppDispatch, useAppSelector } from "../../lib/hooks";
import { RefObject, useEffect, useRef, useState } from "react";
import { getVoicesList } from "../../lib/app/voice-library/thunk";
import _ from "lodash";
import CircleIcon from "../../@components/CircleIcon";
import { Select, chakraComponents } from "chakra-react-select";

const dropdownStyles: any = {
  dropdownIndicator: (b: any) => ({
    ...b,
    backgroundColor: "transparent",
    borderColor: "transparent",
    border: 0,
  }),
  control: (b: any) => ({
    ...b,
    borderRadius: 8,
  }),
  menuList: (b: any) => ({
    ...b,
    borderRadius: 8,
  }),
};

const dropdownComponents = {
  DropdownIndicator: (props: any) => (
    <chakraComponents.DropdownIndicator {...props}>
      <ChevronsUpDownIcon size={12} />
    </chakraComponents.DropdownIndicator>
  ),
};

function Audio({
  url,
  onInteraction,
}: {
  url: string;
  onInteraction: (
    state: "playing" | "paused",
    ref?: RefObject<HTMLAudioElement> | null
  ) => void;
}) {
  const [duration, setDuration] = useState(0);
  const [track, setTrack] = useState(0);
  const [isPaused, setPaused] = useState(true);
  const ref = useRef<HTMLAudioElement>(null);

  return (
    <Flex alignItems={"center"} gap={3}>
      <audio
        onEnded={() => {
          onInteraction("paused", ref);
          setTrack(0);
          setPaused(true);
        }}
        onTimeUpdate={(e) => {
          setTrack(Math.ceil(e.currentTarget.currentTime));
        }}
        hidden
        ref={ref}
        src={url}
        onLoadedMetadata={(e) => {
          setDuration(Math.ceil(ref.current?.duration || 0));
        }}
      />

      <CircularProgress
        max={duration}
        value={track}
        color="var(--chakra-colors-primary-500)"
        size={12}
      >
        <CircularProgressLabel mx={"auto"}>
          {isPaused ? (
            <PlayIcon
              size={16}
              style={{ marginLeft: "auto", marginRight: "auto" }}
              cursor={"pointer"}
              onClick={() => {
                if (ref.current?.paused) {
                  ref.current?.play();
                  setPaused(false);
                } else {
                  ref.current?.pause();
                  setPaused(true);
                }
              }}
            />
          ) : (
            <PauseIcon
              color="var(--chakra-colors-primary-500)"
              size={16}
              style={{ marginLeft: "auto", marginRight: "auto" }}
              cursor={"pointer"}
              onClick={() => {
                if (ref.current?.paused) {
                  ref.current?.play();
                  setPaused(false);
                } else {
                  ref.current?.pause();
                  setPaused(true);
                }
              }}
            />
          )}
        </CircularProgressLabel>
      </CircularProgress>
      {/* <Slider max={duration} value={track} min={0} colorScheme="primary" defaultValue={0}>
                <SliderTrack>
                    <SliderFilledTrack />
                </SliderTrack>
                <SliderThumb />
            </Slider> */}
    </Flex>
  );
}

export default function VoiceLibrary() {
  const voices = useAppSelector((state) => state.app.voices.list);
  const metadata = useAppSelector((state) => state.app.voices.list.metadata);
  const dispatch = useAppDispatch();
  const [page, setPage] = useState(-1);
  const [size, setSize] = useState(15);
  const toast = useToast();
  const currentRef = useRef<HTMLAudioElement>(null);
  const [filters, setFilters] = useState<{ gender?: string, accent?: string }>({

  })
  const [keyword, setKeyword] = useState('')
  const debouncedSearch = _.debounce((v: string) => {
    dispatch(getVoicesList(1, size, v, filters));
  }, 300);

  useEffect(() => {
    dispatch(getVoicesList(Math.abs(page), size, keyword, filters));
  }, [page, size, filters]);

  return (
    <Page title="Voice Library">
      <Flex mt={3} mb={4} ml={3} gap={2}>
        <Stack gap={0}>
          <Text fontWeight={500} fontSize={"sm"}>
            Search
          </Text>
          <Input
            rounded={8}
            w={300}
            onChange={(v) => {
                setKeyword(v.target.value)
              debouncedSearch(v.target.value);
            }}
            h={8}
            size={"sm"}
            placeholder="Enter voice name"
          />
        </Stack>
        <Button hidden fontSize={"sm"} variant={"outline"}>
          Filters
        </Button>
        <Stack w={200} gap={0}>
          <Text fontWeight={500} fontSize={"sm"}>
            Gender
          </Text>
          <Select
            colorScheme="primary"
            chakraStyles={dropdownStyles}
            components={dropdownComponents}
            size={"sm"}
            variant="outline"
            onChange={(v) => {
                setFilters({
                    ...filters,
                    gender: v?.value === "ALL" ? undefined : v?.value.toUpperCase()
                })
                setPage(1)
            }}
            options={[
              {
                value: "ALL",
                label: "All",
              },
              {
                value: "MALE",
                label: "Male",
              },
              {
                value: "FEMALE",
                label: "Female",
              },
            ]}
          />
        </Stack>
        <Stack w={200} gap={0}>
          <Text fontWeight={500} fontSize={"sm"}>
            Accent
          </Text>
          <Select
            placeholder={"Choose accent"}
            chakraStyles={dropdownStyles}
            components={dropdownComponents}
            size={"sm"}
            variant="outline"
            onChange={(v) => {
                setFilters({
                    ...filters,
                    accent: v?.value === "ALL" ? undefined : v?.value.toUpperCase()
                })
                setPage(1)
            }}
            options={[
                {
                    "label": "All",
                    "value": "ALL"
                },
                {
                    "label": "American",
                    "value": "AMERICAN"
                },
                {
                    "label": "American Irish",
                    "value": "AMERICAN_IRISH"
                },
                {
                    "label": "American Southern",
                    "value": "AMERICAN_SOUTHERN"
                },
                {
                    "label": "Australian",
                    "value": "AUSTRALIAN"
                },
                {
                    "label": "British",
                    "value": "BRITISH"
                },
                {
                    "label": "British Essex",
                    "value": "BRITISH_ESSEX"
                },
                {
                    "label": "English Italian",
                    "value": "ENGLISH_ITALIAN"
                },
                {
                    "label": "English Swedish",
                    "value": "ENGLISH_SWEDISH"
                },
                {
                    "label": "Indian",
                    "value": "INDIAN"
                },
                {
                    "label": "Irish",
                    "value": "IRISH"
                }
            ]
            }
          />
        </Stack>
      </Flex>
      <Stack mx={4}>
        <Grid gap={4} gridTemplateColumns={"repeat(4, 1fr)"}>
          {voices.state === "loading" ? <>
            <Skeleton rounded={8} h={150} />
            <Skeleton rounded={8} h={150} />
            <Skeleton rounded={8} h={150} />
            <Skeleton rounded={8} h={150} />
            <Skeleton rounded={8} h={150} />
            <Skeleton rounded={8} h={150} />
            <Skeleton rounded={8} h={150} />
            <Skeleton rounded={8} h={150} />
            </> : voices.data.map((voice: any) => {
            return (
              <Stack
                borderRadius={8}
                p={4}
                border={"1px solid var(--chakra-colors-gray-200)"}
                w={"full"}
              >
                <Flex gap={2} justifyContent={"space-between"}>
                  <Box>
                    <Text fontSize={"large"} fontWeight={600}>
                      {voice.name}
                    </Text>
                    <Text fontWeight={500} fontSize={"sm"}>
                      {voice.voice_id}
                    </Text>
                    <Text fontWeight={500} fontSize={"xs"}>{`${_.capitalize(
                      voice.gender
                    )} | ${_.capitalize(voice.accent)}`}</Text>
                  </Box>
                  <Flex gap={1}>
                    <Tooltip hasArrow label="Copy ID">
                      <IconButton
                        variant={"outline"}
                        onClick={async () => {
                          toast({
                            size: "sm",
                            title: "ID Copied",
                            status: "success",
                          });
                          await window.navigator.clipboard.writeText(
                            voice.voice_id
                          );
                        }}
                        size={"sm"}
                        p={2}
                        aria-label=""
                        icon={<CopyIcon size={12} />}
                      />
                    </Tooltip>
                    {/* <IconButton variant={"outline"} onClick={async () => {
                                            toast({
                                                size: "sm",
                                                title: "ID Copied",
                                                status: "success"
                                            })
                                            await window.navigator.clipboard.writeText(voice.voice_id)
                                    }} size={"sm"} aria-label="" icon={<EllipsisVerticalIcon size={12} />} /> */}
                  </Flex>
                </Flex>
                <Flex alignItems={"center"} justifyContent={"space-between"}>
                  <Flex
                    w={"fit-content"}
                    gap={1}
                    alignItems={"center"}
                    p={0}
                    px={2}
                    rounded={4}
                    border={"1px solid #63B3ED"}
                  >
                    <CircleIcon color={"#63B3ED"} boxSize={2} />
                    <Text textTransform={"lowercase"} fontSize={"xs"}>
                      {voice.voice_provider}
                    </Text>
                  </Flex>
                  <Audio
                    onInteraction={(state, ref) => {
                      if (state === "playing") {
                        // currentRef. = ref?.current
                      }
                    }}
                    url={voice.sample_url}
                  />
                </Flex>
              </Stack>
            );
          })}
        </Grid>
        <Flex ml={2} justifyContent={"space-between"}>
          <Flex align={"center"} gap={2}>
            <Text whiteSpace={"nowrap"} fontSize={"small"}>
              {/* @ts-ignore */}
              Showing{" "}
              {metadata?.total_count < page * size
                ? metadata?.total_count
                : page * size}{" "}
              of {metadata?.total_count}
            </Text>
            <CoreSelect
              onChange={(v) => {
                setSize(Number.parseInt(v.target.value, 10));
              }}
              defaultValue={size}
              size="sm"
              borderRadius={8}
            >
              <option value={15} selected>
                15
              </option>
              <option value={30}>25</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </CoreSelect>
          </Flex>
          <Flex gap={2} align={"center"}>
            <IconButton
              isDisabled={page === 1}
              onClick={() => {
                setPage(1);
              }}
              rounded={"full"}
              variant={"outline"}
              icon={<ChevronsLeftIcon size={16} />}
              aria-label=""
            />
            <IconButton
              isDisabled={page === 1}
              onClick={() => {
                setPage(page - 1);
              }}
              rounded={"full"}
              variant={"outline"}
              icon={<ChevronLeftIcon size={16} />}
              aria-label=""
            />
            <IconButton
              // @ts-ignore
              isDisabled={metadata?.total_count <= page * size}
              onClick={() => {
                setPage(page + 1);
              }}
              rounded={"full"}
              variant={"outline"}
              icon={<ChevronRightIcon size={16} />}
              aria-label=""
            />
            <IconButton
              // @ts-ignore
              isDisabled={metadata?.total_count <= page * size}
              onClick={() => {
                // @ts-ignore
                setPage(Math.ceil(metadata?.total_count / size));
              }}
              rounded={"full"}
              variant={"outline"}
              icon={<ChevronsRightIcon size={16} />}
              aria-label=""
            />
          </Flex>
        </Flex>
      </Stack>
    </Page>
  );
}
