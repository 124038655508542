import { axios } from "../../../utils/axios.loader";
import { AppDispatch } from "../../store";
import { setFeedbackCreateLoading, setFeedbackCreateSuccess, setFeedbackDeleteLoading, setFeedbackDeleteSuccess, setFeedbackListSuccess, setFeedbackUpdateLoading, setFeedbackUpdateSuccess } from "./actions";

export const getFeedbackByAssistant = (agent_id: string) => async (dispatch: AppDispatch) => {
    try {
        const request = await axios.get(`/agent/${agent_id}/feedbacks?page=1&sort_value=${'-updated_at'}`)
        const { feedbacks, total_count } = request.data
        dispatch(setFeedbackListSuccess(feedbacks, {
            total_count
        }))
    } catch (err) {

    }
}

export const createFeedback = (agent_id: string, payload: any, callback?: (p?: any) => void) => async (dispatch: AppDispatch) => {
    dispatch(setFeedbackCreateLoading())
    try {
        const request = await axios.post(`/agent/${agent_id}/feedbacks`, payload)
        dispatch(setFeedbackCreateSuccess(request.data))
        callback?.()
    } catch (err) {

    }
}

export const updateFeedback = (organization_id: string, feedback_id: string, payload: any, callback?: () => void) => async (dispatch: AppDispatch) => {
    dispatch(setFeedbackUpdateLoading())
    try {
        const request = await axios.patch(`/feedbacks/organization/${organization_id}/${feedback_id}`, payload)
        dispatch(setFeedbackUpdateSuccess(request.data))
        callback?.()
    } catch (err) {

    }
}

export const deleteFeedback = (organization_id: string, feedback_id: string, callback?: () => void) => async (dispatch: AppDispatch) => {
    dispatch(setFeedbackDeleteLoading())
    try {
        const request = await axios.delete(`/feedbacks/organization/${organization_id}/${feedback_id}`)
        dispatch(setFeedbackDeleteSuccess(request.data))
        callback?.()
    } catch (err) {

    }
}