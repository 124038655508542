import { AppDispatch } from "~/lib/store"
import { setCreateTasksLoading, setCreateTasksSuccess, setGetTasksLoading, setGetTasksSuccess, setListTasksLoading, setListTasksSuccess } from "./actions"
import { axios } from "~/utils/axios.loader"

export const listTasks = (organization_id: string, agent_id: string) => async (dispatch: AppDispatch) => {
    dispatch(setListTasksLoading())
    try {
        const request = await axios.get(`/tasks/organizations/${organization_id}/agents/${agent_id}`)
        dispatch(setListTasksSuccess(request.data.tasks || []))
    } catch (err) {
        
    }
}

export const getTask = (task_id: string) => async (dispatch: AppDispatch) => {
    dispatch(setGetTasksLoading())
    try {
        const request = await axios.get(`/tasks/${task_id}`)
        dispatch(setGetTasksSuccess(request.data))
    } catch (err) {
        
    }
}

export const createTask = (organization_id: string, agent_id: string, name: string, config: any, callback?: (data: any) => void) => async (dispatch: AppDispatch) => {
    dispatch(setCreateTasksLoading())
    try {
        const request = await axios.post(`/tasks/organizations/${organization_id}/agents/${agent_id}`, {
            "title": name,
            "task_config": config
        })
        dispatch(setCreateTasksSuccess(request.data))
        callback?.(request.data)
    } catch (err) {
        
    }
}

export const updateTask = (name: string, config: any) => async (dispatch: AppDispatch) => {
    try {
        
    } catch (err) {
        
    }
}

export const deleteTask = (name: string, config: any) => async (dispatch: AppDispatch) => {
    try {
        
    } catch (err) {

    }
}