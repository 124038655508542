import _ from "lodash";

export function extractVariables(template: string, skipBraces = false) {
    const regex = /{([^{}]+)}/g;
    const matches = Array.from(template.matchAll(regex));
    const variables = [];

    for (const match of matches) {
        const variable = match[1].trim();
        if (!skipBraces || variable[0] !== "{" || variable[variable.length - 1] !== "}") {
            variables.push(variable);
        }
    }

    return variables;
}

export function generateCanonicalNames (label: string) {
    switch (label) {
        case "customer-did-not-give-microphone-permission":
            return "No microphone permission"
        case "silence-timed-out":
            return "Customer was silent"
        default:
            return _.capitalize((label || '').split("-").join(" "))
    }
}

export function generateCanonicalVariants (label: string) {
    switch (label) {
        case "customer-did-not-give-microphone-permission":
            return "destructive-subtle"
        case "silence-timed-out":
            return "primary-subtle"
        case "customer-ended-call":
            return "green-subtle"
        default:
            return _.capitalize((label || '').split("-").join(" "))
    }
}