export const SET_CREATE_ORGANIZATION_LOADING = "get-started/organization/create/loading"
export const SET_CREATE_ORGANIZATION_SUCCESS = "get-started/organization/create/success"
export const SET_CREATE_ORGANIZATION_ERROR = "get-started/organization/create/error"
export const SET_UPDATE_ORGANIZATION_LOADING = "get-started/organization/update/loading"
export const SET_UPDATE_ORGANIZATION_SUCCESS = "get-started/organization/update/success"
export const SET_UPDATE_ORGANIZATION_ERROR = "get-started/organization/update/error"
export const SET_GET_ORGANIZATION_LOADING = "get-started/organization/get/loading"
export const SET_GET_ORGANIZATION_SUCCESS = "get-started/organization/get/success"
export const SET_GET_ORGANIZATION_ERROR = "get-started/organization/get/error"
export const SET_LIST_ORGANIZATION_LOADING = "get-started/organization/list/loading"
export const SET_LIST_ORGANIZATION_SUCCESS = "get-started/organization/list/success"
export const SET_LIST_ORGANIZATION_ERROR = "get-started/organization/list/error"
export const SET_CREATE_AGENT_LOADING = "get-started/agent/create/loading"
export const SET_CREATE_AGENT_SUCCESS = "get-started/agent/create/success"
export const SET_CREATE_AGENT_ERROR = "get-started/agent/create/error"
export const SET_INVITE_LOADING = "get-started/invite/loading"
export const SET_INVITE_SUCCESS = "get-started/invite/success"
export const SET_INVITE_ERROR = "get-started/invite/error"