import { axios, axiosv2 } from "../../../utils/axios.loader";
import { AppDispatch } from "../../store";
import { setCreateAgentLoading, setCreateAgentSuccess, setCreateOrganizationLoading, setCreateOrganizationSuccess, setInviteMembersError, setInviteMembersLoading, setInviteMembersSuccess, setUpdateOrganizationLoading, setUpdateOrganizationSuccess } from "./actions";

export const createOrganization = (payload: any, callback?: (data?: any, team?: any) => void) => async (dispatch: AppDispatch) => {
    dispatch(setCreateOrganizationLoading())
    try {
        const request = await axios.post(`/organization/`, payload)
        const response = request.data
        dispatch(setCreateOrganizationSuccess(response))
        const team = await axios.post(`/teams/organizations/${response.id}`, {
            "name": "My First Team",
            "type": "SALES"
        })
        callback?.(response, team.data)
    } catch (err) {

    }
}

export const updateOrganization = (organization: string, payload: any, callback?: () => void) => async (dispatch: AppDispatch) => {
    dispatch(setUpdateOrganizationLoading())
    try {
        const request = await axios.put(`/organization/${organization}/`, payload)
        const response = request.data
        dispatch(setUpdateOrganizationSuccess(response))
        callback?.()
    } catch (err) {

    }
}

export const getOrganization = (organization: string) => async (dispatch: AppDispatch) => {
    try {
        const request = await axios.put(`/organization/${organization}/`)
        const response = request.data
    } catch (err) {

    }
}

export const createV2Agent = (payload: any, callback?: (id: string, data?: any) => void) => async (dispatch: AppDispatch) => {
    dispatch(setCreateAgentLoading())
    try {
        const request = await axiosv2.post(`/agent/organization/${payload.organization_id}/create`, {
            ...payload,
            created_at: new Date().toISOString(),
            updated_at: new Date().toISOString()
        })
        const response = request.data
        dispatch(setCreateAgentSuccess(response))
        callback?.(response.id, response)
    } catch (err) {

    }
}

export const createAgent = (payload: any, callback?: (id: string, data?: any) => void) => async (dispatch: AppDispatch) => {
    dispatch(setCreateAgentLoading())
    try {
        const request = await axiosv2.post(`/agent/organization/${payload.organization_id}/create`, {
            ...payload,
            created_at: new Date().toISOString(),
            updated_at: new Date().toISOString()
        })
        const response = request.data
        dispatch(setCreateAgentSuccess(response))
        callback?.(response.id, response)
    } catch (err) {

    }
}

export const inviteTeamMates = (organization_id: string, emails: string[], callback?: () => void) => async (dispatch: AppDispatch) => {
    dispatch(setInviteMembersLoading())
    try {
        for (const email of emails) {
            try {
                await axios.post(`/organization/${organization_id}/users`, {
                    "email": email,
                    "role": "ADMIN"
                })
            } catch (err) {

            }
        }
        dispatch(setInviteMembersSuccess())
        callback?.()
    } catch (err) {
        dispatch(setInviteMembersError(err))
        callback?.()
    }
}

export const updateV2Agent = (organization_id: string, agent: string, payload: any, callback?: () => void) => async (dispatch: AppDispatch) => {
    dispatch(setCreateAgentLoading())
    try {
        const request = await axiosv2.put(`/agent/organization/${organization_id}/${agent}/`, {
            ...payload,
            updated_at: new Date().toISOString()
        })
        const response = request.data
        dispatch(setCreateAgentSuccess(response))
        callback?.()
    } catch (err) {

    }
}

export const getV2Agent = (agent: string) => async (dispatch: AppDispatch) => {

}