import { Route, Routes, useLocation, useNavigate } from "react-router-dom"
import Login from "./@pages/auth/Login"
import Playground from "./@pages/app/Playground"
import Assistants from "./@pages/app/Assistants"
import { useEffect } from "react"
import { useCookies } from "react-cookie"
import Shell from "./@pages/Shell"
import SignUp from "./@pages/auth/SignUp"
import SpecificAssistant from "./@pages/app/SpecificAssistant"
import Home from "./@pages/app/Home"
import AuditLogs from "./@pages/app/Audit"
import Settings from "./@pages/app/Settings"
import VoiceLibrary from "./@pages/app/VoiceLibrary"
import GetStarted from "./@pages/app/GetStarted"
import Prompt from "./@pages/app/Prompt"
import Loader from "./@pages/app/Loader"
import KnowledgeBase from "./@pages/app/KnowledgeBase"
import Analytics from "./@pages/app/Analytics"
import Demo from "./@pages/app/Demo"
import Welcome from "./@pages/auth/Welcome"
import Dashboard from "./@pages/app/Dashboard"
import Team from "./@pages/app/Team"
import OrganizationShell from "./@pages/OrganizationShell"
import Notifications from "./@pages/app/Notifications"
import Integrations from "./@pages/app/Integrations"
import mixpanel from "mixpanel-browser"
import Wrapper from "./Wrapper"

export const App = () => {
  const [cookies] = useCookies(["accessToken"]);
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    mixpanel.init("ace5f76219b901d8cdfe3e85f0919ca0", {
      debug: true,
      track_pageview: true,
      persistence: "localStorage",
    });
  })

  useEffect(() => {
    if (location.pathname.startsWith("/demo")) {

    } else if (!location.pathname.startsWith("/auth") && !cookies.accessToken) {
      navigate(`/auth/login`)
    } else if (location.pathname === "/") {
      navigate(`/`)
    }
  }, [ cookies ])

  return (
    <Routes>
      <Route path="demo" element={<Demo />} />
      <Route path="auth/">
        <Route path="login/" element={<Login />} />
        <Route path="signup/" element={<SignUp />} />
      </Route>
      <Route path="get-started/" element={<GetStarted />} />
      <Route path="prompt/" element={<Prompt />} />
      <Route path="welcome/" element={<Welcome />} />
      <Route path="/" element={<Loader />} />
      <Route path="app/:organization/" element={<Wrapper />}>
        <Route element={<OrganizationShell />}>
        <Route path="home" element={<Dashboard />} />
        <Route path="member-access/" element={<Team />} />
        <Route path="settings/" element={<Settings />} />
        <Route path="notifications/" element={<Notifications />} />
        <Route path="integrations/" element={<Integrations />} />
        </Route>
        <Route path="team/:team" element={<Shell />}>
          <Route path="home/" element={<Home />} />
          <Route path="voice-library/" element={<VoiceLibrary />} />
          <Route path="playground/" element={<Playground />} />
          <Route path="assistants/" element={<Assistants />} />
          <Route path="files/" element={<KnowledgeBase />} />
          <Route path="analytics/" element={<Analytics />} />
          <Route path="files/:file" element={<KnowledgeBase />} />
          <Route path="assistants/:assistant" element={<SpecificAssistant />} />
          <Route path="logs/" element={<AuditLogs />} />
          <Route path="logs/:log" element={<AuditLogs />} />
        </Route>
      </Route>
    </Routes>
  )
}
