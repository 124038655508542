export const SET_ORGANIZATION_CREATE_LOADING = "organization/create/loading"
export const SET_ORGANIZATION_CREATE_SUCCESS = "organization/create/success"
export const SET_ORGANIZATION_CREATE_ERROR = "organization/create/error"
export const SET_ORGANIZATION_LIST_LOADING = "organization/list/loading";
export const SET_ORGANIZATION_LIST_SUCCESS = "organization/list/success";
export const SET_ORGANIZATION_LIST_ERROR = "organization/list/error";
export const SET_ORGANIZATION_UPDATE_LOADING = "organization/update/loading";
export const SET_ORGANIZATION_UPDATE_SUCCESS = "organization/update/success";
export const SET_ORGANIZATION_UPDATE_ERROR = "organization/update/error";
export const SET_ORGANIZATION_GET_LOADING = "organization/get/loading";
export const SET_ORGANIZATION_GET_SUCCESS = "organization/get/success";
export const SET_ORGANIZATION_GET_ERROR = "organization/get/error";
