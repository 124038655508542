import { BaseObjectState } from "../../types";
import { 
    SET_CREATE_AGENT_ERROR, 
    SET_CREATE_AGENT_LOADING, 
    SET_CREATE_AGENT_SUCCESS,
    SET_CREATE_ORGANIZATION_ERROR,
    SET_CREATE_ORGANIZATION_LOADING,
    SET_CREATE_ORGANIZATION_SUCCESS,
    SET_INVITE_ERROR,
    SET_INVITE_LOADING,
    SET_INVITE_SUCCESS,
    SET_UPDATE_ORGANIZATION_ERROR,
    SET_UPDATE_ORGANIZATION_LOADING,
    SET_UPDATE_ORGANIZATION_SUCCESS
} from "./constants";

interface PlatformState {
    organization: BaseObjectState,
    agent: BaseObjectState,
    invite: BaseObjectState
}

const INITIAL_STATE: PlatformState = {
    organization: {
        state: "pending",
        data: {},
        error: {}
    },
    agent: {
        state: "pending",
        data: {},
        error: {}
    },
    invite: {
        state: "pending",
        data: {},
        error: {}
    },
}

export const GetStartedReducer = (state = INITIAL_STATE, action: any) => {
    switch (action.type) {
        case SET_CREATE_AGENT_LOADING:
            return {
                ...state,
                agent: {
                    state: "loading",
                    data: {},
                    error: ''
                }
            }
        case SET_CREATE_AGENT_SUCCESS:
            return {
                ...state,
                agent: {
                    state: "success",
                    data: action.payload,
                    error: ''
                }
            }
        case SET_CREATE_AGENT_ERROR:
            return {
                ...state,
                agent: {
                    state: "error",
                    data: {},
                    error: action.error || 'Something went wrong'
                }
            }
        case SET_CREATE_ORGANIZATION_LOADING:
            return {
                ...state,
                organization: {
                    state: "loading",
                    data: {},
                    error: ''
                }
            }
        case SET_CREATE_ORGANIZATION_SUCCESS:
            return {
                ...state,
                organization: {
                    state: "success",
                    data: action.payload,
                    error: ''
                }
            }
        case SET_CREATE_ORGANIZATION_ERROR:
            return {
                ...state,
                organization: {
                    state: "error",
                    data: {},
                    error: action.error || 'Something went wrong'
                }
            }
        case SET_UPDATE_ORGANIZATION_LOADING:
            return {
                ...state,
                organization: {
                    state: "loading",
                    data: {},
                    error: ''
                }
            }
        case SET_UPDATE_ORGANIZATION_SUCCESS:
            return {
                ...state,
                organization: {
                    state: "success",
                    data: action.payload,
                    error: ''
                }
            }
        case SET_UPDATE_ORGANIZATION_ERROR:
            return {
                ...state,
                organization: {
                    state: "error",
                    data: {},
                    error: action.error || 'Something went wrong'
                }
            }
        
        case SET_INVITE_LOADING:
            return {
                ...state,
                invite: {
                    state: "loading",
                    data: {},
                    error: ''
                }
            }
        case SET_INVITE_SUCCESS:
            return {
                ...state,
                invite: {
                    state: "success",
                    data: {},
                    error: ''
                }
            }
        case SET_INVITE_ERROR:
            return {
                ...state,
                invite: {
                    state: "error",
                    data: {},
                    error: action.error || 'Something went wrong'
                }
            }
        default:
            return state;
    }
}
