import {
  Avatar,
  Box,
  Flex,
  Grid,
  Link,
  Stack,
  Button as CoreButton,
  Text,
  useDisclosure,
  useToast,
  Divider,
  Tooltip,
} from "@chakra-ui/react";
import Page from "../../@components/Page";
import { TabItem } from "./SpecificAssistant";
import { ChevronsUpDownIcon, LockIcon, SquareArrowOutUpRightIcon } from "lucide-react";
import { Field, Form, Formik } from "formik";
import { useAppDispatch, useAppSelector } from "../../lib/hooks";
import { updateOrganization } from "../../lib/app/organization/thunk";
import { useEffect, useState } from "react";
import {
  addMemberTeam,
  getTeam,
  removeMemberTeam,
  updateMemberTeam,
} from "../../lib/app/team/thunk";
import _ from "lodash";
import { Select, chakraComponents } from "chakra-react-select";
import { Button } from "~/components/ui/button";
import { Badge } from "~/components/ui/badge";

const dropdownStyles: any = {
  dropdownIndicator: (b: any) => ({
    ...b,
    backgroundColor: "transparent",
    borderColor: "transparent",
    border: 0,
  }),
  control: (b: any) => ({
    ...b,
    borderRadius: 8,
  }),
  menuList: (b: any) => ({
    ...b,
    borderRadius: 8,
  }),
};

const dropdownComponents = {
  DropdownIndicator: (props: any) => (
    <chakraComponents.DropdownIndicator {...props}>
      <ChevronsUpDownIcon size={12} />
    </chakraComponents.DropdownIndicator>
  ),
};

export default function Integrations() {
  const _organization = useAppSelector((state) => state.app.organizations.get);
  const _organizationUpdated = useAppSelector(
    (state) => state.app.organizations.update
  );
  const profile = useAppSelector((state) => state.app.platform.profile);
  const team = useAppSelector((state) => state.app.team.list);
  const create = useAppSelector((state) => state.app.team.create);
  const update = useAppSelector((state) => state.app.team.update);
  const _delete = useAppSelector((state) => state.app.team.delete);
  const dispatch = useAppDispatch();
  const toast = useToast();
  const [currentMember, setCurrentMember] = useState<any | null>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isDeleteOpen,
    onOpen: onDeleteOpen,
    onClose: onDeleteClose,
  } = useDisclosure();
  const integrations = [
    {
      name: "Cal",
      image: "https://cal.com/android-chrome-512x512.png",
      website: "https://cal.com",
      description:
        "Connect Cal.com account to build processes around your events",
      active: false,
    },
    {
      name: "HubSpot",
      image: "https://cdn4.iconfinder.com/data/icons/logos-and-brands/512/168_Hubspot_logo_logos-512.png",
      website: "https://hubspot.com",
      description:
        "Connect Hubspot account to manage contacts across your CRM",
      active: false,
    },
  ];

  return (
    <Page
      subtitle="Discover & Connect ESL to other tools that your team uses."
      title="Integrations"
    >
      <Divider mt={3} />
      <Stack px={3} mt={4}>
        <Grid gridTemplateColumns={"repeat(3, 1fr)"} gap={4}>
          {integrations.map((integration) => {
            return (
              <Stack
                justifyContent={"space-between"}
                rounded={"xl"}
                className="border"
              >
                <Flex
                  flex={1}
                  justifyContent={"space-between"}
                  className="border-b"
                >
                  <Stack py={3} px={3} gap={3}>
                    <Box>
                      <Flex gap={1} alignItems={"center"}>
                        <Box>
                          <Text fontSize={"lg"} fontWeight={"semibold"}>
                            {integration.name}
                          </Text>
                          <Link
                            gap={2}
                            display={"flex"}
                            alignItems={"center"}
                            href={integration.website}
                            target="_blank"
                            className="!underline text-muted-foreground"
                            fontSize={"sm"}
                          >
                            {new URL(integration.website).hostname}{" "}
                            <SquareArrowOutUpRightIcon size={14} />
                          </Link>
                        </Box>
                      </Flex>
                      <Text
                        mt={2}
                        className="text-muted-foreground"
                        fontSize={"sm"}
                      >
                        {integration.description}
                      </Text>
                    </Box>
                  </Stack>
                  <Box pos={"relative"}>
                    <Box></Box>
                    <Avatar
                      rounded={"xs"}
                      size={"lg"}
                      top={-0.5}
                      right={-0.5}
                      borderRadius={0}
                      src={integration.image}
                    />
                    {/* <Badge className="whitespace-nowrap" variant={"green-subtle"}>
                    Coming Soon
                  </Badge> */}
                  </Box>
                </Flex>
                <Flex justifyContent={"space-between"} px={2} py={2} pt={0}>
                  <CoreButton  fontSize={"xs"} variant={"outline"} size={"sm"} fontWeight={"medium"}>View Integration</CoreButton>
                  <Tooltip hasArrow label="Coming soon">
                  <CoreButton size={"sm"} fontSize={"xs"} colorScheme="black" className="bg-black" leftIcon={<LockIcon size={16} />}>Connect Now</CoreButton>
                  </Tooltip>
                </Flex>
              </Stack>
            );
          })}
        </Grid>
      </Stack>
    </Page>
  );
}
