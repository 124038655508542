import {
    SET_TEAM_CREATE_LOADING,
    SET_TEAM_CREATE_SUCCESS,
    SET_TEAM_CREATE_ERROR,
    SET_TEAM_LIST_LOADING,
    SET_TEAM_LIST_SUCCESS,
    SET_TEAM_LIST_ERROR,
    SET_TEAM_UPDATE_LOADING,
    SET_TEAM_UPDATE_SUCCESS,
    SET_TEAM_UPDATE_ERROR,
    SET_TEAM_DELETE_LOADING,
    SET_TEAM_DELETE_SUCCESS,
    SET_TEAM_DELETE_ERROR,
    SET_TEAM_GET_LOADING,
    SET_TEAM_GET_SUCCESS,
    SET_TEAM_GET_ERROR
} from './constants';

// Team Create Actions
export function setTeamCreateLoading() {
    return {
        type: SET_TEAM_CREATE_LOADING
    }
}

export function setTeamCreateSuccess(payload: any, metadata?: any) {
    return {
        type: SET_TEAM_CREATE_SUCCESS,
        payload,
        metadata
    }
}

export function setTeamCreateError(error: any) {
    return {
        type: SET_TEAM_CREATE_ERROR,
        error
    }
}

// Team List Actions
export function setTeamListLoading() {
    return {
        type: SET_TEAM_LIST_LOADING
    }
}

export function setTeamListSuccess(payload: any, metadata?: any) {
    return {
        type: SET_TEAM_LIST_SUCCESS,
        payload,
        metadata
    }
}

export function setTeamListError(error: any) {
    return {
        type: SET_TEAM_LIST_ERROR,
        error
    }
}

// Team Update Actions
export function setTeamUpdateLoading() {
    return {
        type: SET_TEAM_UPDATE_LOADING
    }
}

export function setTeamUpdateSuccess(payload: any, metadata?: any) {
    return {
        type: SET_TEAM_UPDATE_SUCCESS,
        payload,
        metadata
    }
}

export function setTeamUpdateError(error: any) {
    return {
        type: SET_TEAM_UPDATE_ERROR,
        error
    }
}

// Team Delete Actions
export function setTeamDeleteLoading() {
    return {
        type: SET_TEAM_DELETE_LOADING
    }
}

export function setTeamDeleteSuccess(payload: any, metadata?: any) {
    return {
        type: SET_TEAM_DELETE_SUCCESS,
        payload,
        metadata
    }
}

export function setTeamDeleteError(error: any) {
    return {
        type: SET_TEAM_DELETE_ERROR,
        error
    }
}

// Team Get Actions
export function setTeamGetLoading() {
    return {
        type: SET_TEAM_GET_LOADING
    }
}

export function setTeamGetSuccess(payload: any, metadata?: any) {
    return {
        type: SET_TEAM_GET_SUCCESS,
        payload,
        metadata
    }
}

export function setTeamGetError(error: any) {
    return {
        type: SET_TEAM_GET_ERROR,
        error
    }
}
