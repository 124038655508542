import { BaseArrayState } from "../../types";
import { 
    SET_METRICS_LOADING,
    SET_METRICS_SUCCESS,
    SET_METRICS_ERROR,
    SET_ANALYTICS_LOADING,
    SET_ANALYTICS_SUCCESS,
    SET_ANALYTICS_ERROR
} from "./constants";

interface HomeState {
    metrics: BaseArrayState,
    analytics: BaseArrayState
}

const INITIAL_STATE: HomeState = {
    metrics: {
        state: "loading",
        data: [],
        error: {}
    },
    analytics: {
        state: "loading",
        data: [],
        error: {}
    }
}

export const HomeReducer = (state = INITIAL_STATE, action: any) => {
    switch (action.type) {
        case SET_METRICS_LOADING:
            return {
                ...state,
                metrics: {
                    state: "loading",
                    data: [],
                    error: ''
                }
            }
        case SET_METRICS_SUCCESS:
            return {
                ...state,
                metrics: {
                    state: "success",
                    data: action.payload,
                    error: ''
                }
            }
        case SET_METRICS_ERROR:
            return {
                ...state,
                metrics: {
                    state: "error",
                    data: [],
                    error: action.error || 'Something went wrong'
                }
            }
        case SET_ANALYTICS_LOADING:
            return {
                ...state,
                analytics: {
                    state: "loading",
                    data: [],
                    error: ''
                }
            }
        case SET_ANALYTICS_SUCCESS:
            return {
                ...state,
                analytics: {
                    state: "success",
                    data: action.payload,
                    error: '',
                    metadata: action.metadata
                }
            }
        case SET_ANALYTICS_ERROR:
            return {
                ...state,
                analytics: {
                    state: "error",
                    data: [],
                    error: action.error || 'Something went wrong'
                }
            }
        default:
            return state;
    }
}
