import {
    SET_CREATE_TASKS_LOADING,
    SET_CREATE_TASKS_SUCCESS,
    SET_CREATE_TASKS_ERROR,
    SET_UPDATE_TASKS_LOADING,
    SET_UPDATE_TASKS_SUCCESS,
    SET_UPDATE_TASKS_ERROR,
    SET_DELETE_TASKS_LOADING,
    SET_DELETE_TASKS_SUCCESS,
    SET_DELETE_TASKS_ERROR,
    SET_GET_TASKS_LOADING,
    SET_GET_TASKS_SUCCESS,
    SET_GET_TASKS_ERROR,
    SET_LIST_TASKS_LOADING,
    SET_LIST_TASKS_SUCCESS,
    SET_LIST_TASKS_ERROR
} from './constants';

// Action Creators for tasks
export const setCreateTasksLoading = () => ({
    type: SET_CREATE_TASKS_LOADING,
});

export const setCreateTasksSuccess = (task: any) => ({
    type: SET_CREATE_TASKS_SUCCESS,
    payload: task,
});

export const setCreateTasksError = (error: string) => ({
    type: SET_CREATE_TASKS_ERROR,
    payload: error,
});

export const setUpdateTasksLoading = () => ({
    type: SET_UPDATE_TASKS_LOADING,
});

export const setUpdateTasksSuccess = (task: any) => ({
    type: SET_UPDATE_TASKS_SUCCESS,
    payload: task,
});

export const setUpdateTasksError = (error: string) => ({
    type: SET_UPDATE_TASKS_ERROR,
    payload: error,
});

export const setDeleteTasksLoading = () => ({
    type: SET_DELETE_TASKS_LOADING,
});

export const setDeleteTasksSuccess = (taskId: string) => ({
    type: SET_DELETE_TASKS_SUCCESS,
    payload: taskId,
});

export const setDeleteTasksError = (error: string) => ({
    type: SET_DELETE_TASKS_ERROR,
    payload: error,
});

export const setGetTasksLoading = () => ({
    type: SET_GET_TASKS_LOADING,
});

export const setGetTasksSuccess = (task: any) => ({
    type: SET_GET_TASKS_SUCCESS,
    payload: task,
});

export const setGetTasksError = (error: string) => ({
    type: SET_GET_TASKS_ERROR,
    payload: error,
});

export const setListTasksLoading = () => ({
    type: SET_LIST_TASKS_LOADING,
});

export const setListTasksSuccess = (tasks: any[]) => ({
    type: SET_LIST_TASKS_SUCCESS,
    payload: tasks,
});

export const setListTasksError = (error: string) => ({
    type: SET_LIST_TASKS_ERROR,
    payload: error,
});
