export const SET_CREATE_FILE_LOADING = "files/create/loading";
export const SET_CREATE_FILE_SUCCESS = "files/create/success";
export const SET_CREATE_FILE_ERROR = "files/create/error";

export const SET_LIST_FILES_LOADING = "files/list/loading";
export const SET_LIST_FILES_SUCCESS = "files/list/success";
export const SET_LIST_FILES_ERROR = "files/list/error";

export const SET_UPDATE_FILE_LOADING = "files/update/loading";
export const SET_UPDATE_FILE_SUCCESS = "files/update/success";
export const SET_UPDATE_FILE_ERROR = "files/update/error";

export const SET_GET_FILE_LOADING = "files/get/loading";
export const SET_GET_FILE_SUCCESS = "files/get/success";
export const SET_GET_FILE_ERROR = "files/get/error";
