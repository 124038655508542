import { Avatar, Box, Button, Divider, Flex, Grid, Heading, IconButton, Image, Img, Modal, ModalBody, ModalContent, ModalOverlay, Popover, PopoverContent, PopoverTrigger, Progress, Skeleton, Stack, StackProps, Text, Tooltip, useDisclosure, useToast } from "@chakra-ui/react";
import { ArrowLeftIcon, Building2Icon, BuildingIcon, CogIcon, MenuIcon, PlusIcon, RefreshCcwIcon, Repeat1Icon, RepeatIcon, RotateCcwIcon } from "lucide-react";
import { MouseEventHandler, ReactNode, useEffect, useState } from "react";
import { useAppSelector } from "../lib/hooks";
import { CheckCircleIcon, InfoOutlineIcon } from "@chakra-ui/icons";
import { useNavigate, useParams } from "react-router-dom";

interface PageProps extends StackProps {
    actions?: ReactNode[]
    showHamburger?: boolean
    showBorder?: boolean
    subtitle?: string
    showBack?: boolean
    onBackClick?: MouseEventHandler<HTMLElement>
    onHamburgerClick?: MouseEventHandler<HTMLElement>
}

export default function Page(props: PageProps) {
    const metrics = useAppSelector(state => state.app.home.metrics)
    const organizations = useAppSelector(state => state.app.organizations.list)
    const _organization = useAppSelector(state => state.app.organizations.get)
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [selectedOrg, setSelectedOrg] = useState<string | null>(null)
    const { organization } = useParams()
    const toast = useToast()
    const navigate = useNavigate()

    useEffect(() => {
        if (organization) {
            setSelectedOrg(organization)
        }
    }, [organization])

    return (
        <Stack h={"100vh"} w={"full"} flex={1} gap={0}>
            
            <Flex mt={2} alignItems={"center"} p={4} py={2} height={'64px'} justifyContent={"space-between"}>
                <Flex borderBottom={props.showBorder ? `0.5px solid #e4e4e4` : undefined} alignItems={"center"}  w={"full"} justifyContent={"space-between"}>
                    <Flex alignItems={"center"} gap={2}>
                        {props.showHamburger ? <IconButton size={"sm"} variant={"none"} display={["flex", "none"]} aria-label="" icon={<MenuIcon size={20} />} onClick={props.onHamburgerClick} /> : <></>}
                        {props.showBack ? <IconButton size={"sm"} variant={"outline"} mr={2} aria-label="" icon={<ArrowLeftIcon size={20} />} onClick={props.onBackClick} /> : <></>}
                        <Box>
                        <Text fontWeight={"bold"} fontSize={28}>{props.title}</Text>
                        {props.subtitle ? <Text color={"#6C6685"} fontWeight={"medium"} fontSize={14}>{props.subtitle}</Text> : <></>}
                        </Box>
                    </Flex>
                    <Flex>
                        {props.actions}
                    </Flex>
                </Flex>
                <Box>
                    {/*  */}
                
                </Box>
            </Flex>
            <Box  pb={10} h={"calc(100vh - 72px)"} overflow={"scroll"}>
            {props.children}
            </Box>
        </Stack>
    )
}