import { Box, Circle, CircularProgress, Text } from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import ThemeContext from "./color-theme";
import { initialTabs as tabs } from "../lib/constants";

const Timer = (props) => {
  const {
    timerData,
    statusHandler,
    countingStatus,
    setCountingStatus,
    startTime,
    ...otherProps
  } = props;

  const themeCtx = useContext(ThemeContext);
  const [intervalId, setIntervalId] = useState(0);
  const [timerStep, setTimerStep] = useState(0);

  // Calculate the initial countdown time based on the startTime prop
  const calculateInitialTime = () => {
    const now = new Date().getTime();
    const start = new Date(startTime).getTime();
    const elapsed = Math.abs(now - start);
    return elapsed > 0 ? elapsed : 0;
  };

  const [countDownTime, setCountDownTime] = useState(calculateInitialTime());

  const [tempShort, setTempShort] = useState(Number(themeCtx.shortBreakDuration));
  const [tempLong, setTempLong] = useState(Number(themeCtx.longBreakDuration));
  const [timerLabel, setTimerLabel] = useState(5);
  const startCountDown = () => {
    if (intervalId) {
      clearInterval(intervalId);
      setCountingStatus("pause");
      setIntervalId(0);
      return;
    }

    if (countingStatus === "end") {
      setCountDownTime(() => calculateInitialTime());
      setCountingStatus(() => "pause");
      return;
    }

    if (timerData === tabs[1] && timerStep % 2 === 0) {
      setTimerStep((timerStep) => timerStep + 1);
    } else if (timerData === tabs[2] && timerStep !== 7) {
      setTimerStep(() => 7);
    }
    startNewTimer();
  };

  const startNewTimer = () => {
    const newIntervalId = setInterval(() => {
      setCountDownTime((countDownTime) => countDownTime - 1000);
    }, 1000);
    setIntervalId(newIntervalId);
    setCountingStatus("counting");
  };

  useEffect(() => {
    if (tempShort != Number(themeCtx.shortBreakDuration) && countingStatus !== "counting") {
      setTempShort(() => Number(themeCtx.shortBreakDuration));
    }
  }, [tempShort, themeCtx.shortBreakDuration]);


  useEffect(() => {
    if (tempLong != Number(themeCtx.longBreakDuration) && countingStatus !== "counting") {
      setTempLong(() => Number(themeCtx.longBreakDuration));
    }
  }, [tempLong, themeCtx.longBreakDuration]);

  useEffect(() => {
    if (countDownTime <= 0) {
      clearInterval(intervalId);
      setIntervalId(0);
      if (timerStep % 2 === 0) {
        if (timerStep !== 6) {
          setTimerStep((timerStep) => timerStep + 1);
          setCountDownTime(() => tempShort * 60 * 1000);
          statusHandler(tabs[1]);
          startNewTimer();
        } else if (timerStep === 6) {
          setTimerStep((timerStep) => timerStep + 1);
          setCountDownTime(() => tempLong * 60 * 1000);
          statusHandler(tabs[2]);
          startNewTimer();
        }
      } else {
        if (timerStep === 7) {
          console.log("test");
          setTimerStep(() => 0);
          setCountDownTime(() => 0);
          statusHandler(tabs[0]);
          setCountingStatus("end");
        } else {
          setTimerStep((timerStep) => timerStep + 1);
          setCountDownTime(() => calculateInitialTime());
          statusHandler(tabs[0]);
          startNewTimer();
        }
      }
    }
  }, [countDownTime]);

  useEffect(() => {
    if (countingStatus != "end") {
      if (timerData == tabs[0]) {
        setTimerLabel(() => tempShort);
        setCountDownTime(() => calculateInitialTime());
      } else if (timerData == tabs[1]) {
        setTimerLabel(() => tempShort);
        setCountDownTime(() => tempShort * 60 * 1000);
      } else if (timerData == tabs[2]) {
        setTimerLabel(() => tempLong);
        setCountDownTime(() => tempLong * 60 * 1000);
      }
    }
  }, [timerData]);

  useEffect(() => {
    if (timerLabel != tempShort && countingStatus != "counting") {
      if (timerData === tabs[0]) {
        setTimerLabel(() => tempShort);
        setCountDownTime(() => calculateInitialTime());
      } else if (timerData === tabs[1]) {
        setTimerLabel(() => tempShort);
        setCountDownTime(() => tempShort * 60 * 1000);
      } else if (timerData === tabs[2]) {
        setTimerLabel(() => tempLong);
        setCountDownTime(() => tempLong * 60 * 1000);
      }
    }
  }, [timerLabel, tempShort, countingStatus, timerData]);

  const formatTime = (milliseconds) => {
    const totalSeconds = Math.floor(milliseconds / 1000);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;
    if (hours > 0) {
      return `${hours}:${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
    } else {
      return `${minutes}:${seconds.toString().padStart(2, "0")}`;
    }
  };

  const btnName =
    countingStatus === "pause"
      ? "START"
      : countingStatus === "counting"
      ? "PAUSE"
      : "RESTART";

  useEffect(() => {
    startCountDown();
  }, []);

  return (
    <Circle
      mx="auto"
      w={["75px", "75px"]}
      h={["75px", "75px"]}
      bgGradient="linear-gradient(315deg, #2E325A 0%, #0E112A 100%)"
    >
      <Circle
        w={["167.805px", "166px"]}
        h={["167.805px", "166px"]}
        bg="transparent"
      >
        <CircularProgress
          size={["53.475px", "123px"]}
          value={(countDownTime / 60 / 1000 / timerLabel) * 100}
          color={themeCtx.themeColor}
          thickness={3.5}
          stroke={"var(--chakra-colors-primary-500)"}
          capIsRound
        />
        <Box pos="absolute">
          <Text
            textStyle="h1"
            color="white"
            fontWeight={"bold"}
            fontSize={"lg"}
            position="relative"
            textAlign="Center"
          >
            {formatTime(countDownTime)}
          </Text>
        </Box>
      </Circle>
    </Circle>
  );
};

export default Timer;
