import { 
    SET_CREATE_ORGANIZATION_LOADING,
    SET_CREATE_ORGANIZATION_SUCCESS,
    SET_CREATE_ORGANIZATION_ERROR,
    SET_CREATE_AGENT_LOADING,
    SET_CREATE_AGENT_SUCCESS,
    SET_CREATE_AGENT_ERROR,
    SET_UPDATE_ORGANIZATION_LOADING,
    SET_UPDATE_ORGANIZATION_SUCCESS,
    SET_UPDATE_ORGANIZATION_ERROR,
    SET_INVITE_LOADING,
    SET_INVITE_SUCCESS,
    SET_INVITE_ERROR
} from './constants';

// Organization Creation Actions
export function setCreateOrganizationLoading() {
    return {
        type: SET_CREATE_ORGANIZATION_LOADING
    }
}

export function setCreateOrganizationSuccess(payload: any, metadata?: any) {
    return {
        type: SET_CREATE_ORGANIZATION_SUCCESS,
        payload,
        metadata
    }
}

export function setCreateOrganizationError(error: any) {
    return {
        type: SET_CREATE_ORGANIZATION_ERROR,
        error
    }
}

// Agent Creation Actions
export function setCreateAgentLoading() {
    return {
        type: SET_CREATE_AGENT_LOADING
    }
}

export function setCreateAgentSuccess(payload: any, metadata?: any) {
    return {
        type: SET_CREATE_AGENT_SUCCESS,
        payload,
        metadata
    }
}

export function setCreateAgentError(error: any) {
    return {
        type: SET_CREATE_AGENT_ERROR,
        error
    }
}

// Organization Update Actions
export function setUpdateOrganizationLoading() {
    return {
        type: SET_UPDATE_ORGANIZATION_LOADING
    }
}

export function setUpdateOrganizationSuccess(payload: any, metadata?: any) {
    return {
        type: SET_UPDATE_ORGANIZATION_SUCCESS,
        payload,
        metadata
    }
}

export function setUpdateOrganizationError(error: any) {
    return {
        type: SET_UPDATE_ORGANIZATION_ERROR,
        error
    }
}

export function setInviteMembersLoading() {
    return {
        type: SET_INVITE_LOADING
    }
}

export function setInviteMembersSuccess() {
    return {
        type: SET_INVITE_SUCCESS
    }
}

export function setInviteMembersError(error: any) {
    return {
        type: SET_INVITE_ERROR
    }
}