import { Dispatch } from "@reduxjs/toolkit"
import Cookies from 'universal-cookie';
import moment from "moment-timezone";
import { setSignupError, setSignupLoading, setSignupSuccess } from "./actions"
import { axios, axiosv2 } from "../../../utils/axios.loader"
import { setUserProfile } from "../../app/platform/actions";

const cookies = new Cookies();

export const initiateEmailSignup = (payload: any) => async (dispatch: Dispatch) => {
    dispatch(setSignupLoading())
    try {
        const request = await axios.post('/users/signup', payload)
        const { username, id, profile_pic, access_token, refresh_token } = request.data;
        cookies.set("accessToken", access_token, {
            expires: moment().add(1, "day").toDate(),
            path: "/"
        })
        cookies.set("refreshToken", refresh_token, {
            expires: moment().add(1, "day").toDate(),
            path: "/"
        })
        axios.defaults.headers.Authorization = `Bearer ${access_token}`
        axiosv2.defaults.headers.Authorization = `Bearer ${access_token}`
        dispatch(setUserProfile({
            image: profile_pic,
            id,
            username
        }))
        dispatch(setSignupSuccess({
            username,
            id,
            image: profile_pic
        }))

    } catch (err: any) {
        dispatch(setSignupError(err?.response?.data?.message || err.toString()))
    }
}