import { 
    SET_METRICS_LOADING,
    SET_METRICS_SUCCESS,
    SET_METRICS_ERROR,
    SET_ANALYTICS_LOADING,
    SET_ANALYTICS_SUCCESS,
    SET_ANALYTICS_ERROR
} from './constants';

// Metrics Actions
export function setMetricsLoading() {
    return {
        type: SET_METRICS_LOADING
    }
}

export function setMetricsSuccess(payload: any, metadata?: any) {
    return {
        type: SET_METRICS_SUCCESS,
        payload,
        metadata
    }
}

export function setMetricsError(error: any) {
    return {
        type: SET_METRICS_ERROR,
        error
    }
}

// Analytics Actions
export function setAnalyticsLoading() {
    return {
        type: SET_ANALYTICS_LOADING
    }
}

export function setAnalyticsSuccess(payload: any, metadata?: any) {
    return {
        type: SET_ANALYTICS_SUCCESS,
        payload,
        metadata
    }
}

export function setAnalyticsError(error: any) {
    return {
        type: SET_ANALYTICS_ERROR,
        error
    }
}
