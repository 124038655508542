import * as React from 'react'
import { Slot } from '@radix-ui/react-slot'
import { type VariantProps, cva } from 'class-variance-authority'

import { ny } from '~/lib/utils'
import { Loader, Loader2 } from 'lucide-react'

const buttonVariants = cva(
   'inline-flex items-center gap-2 justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50',
   {
      variants: {
         variant: {
            default: 'bg-primary text-primary-foreground hover:bg-primary/90',
            destructive:
          'bg-destructive text-destructive-foreground hover:bg-destructive/90',
          dark:
        'bg-black text-primary-foreground hover:bg-black/90',
            outline:
          'border border-input bg-background hover:bg-accent hover:text-accent-foreground',
          outlineHover:
        'border border-input bg-red-500 hover:text-accent-foreground',
            secondary:
          'bg-secondary text-secondary-foreground hover:bg-secondary/80',
            ghost: 'hover:bg-accent hover:text-accent-foreground',
            link: 'text-primary underline-offset-4 hover:underline',
         },
         size: {
            default: 'h-10 px-4 py-2',
            sm: 'h-9 rounded-md px-3',
            lg: 'h-11 rounded-md px-8',
            icon: 'h-10 w-10',
         },
      },
      defaultVariants: {
         variant: 'default',
         size: 'default',
      },
   },
)

export interface ButtonProps
   extends React.ButtonHTMLAttributes<HTMLButtonElement>,
   VariantProps<typeof buttonVariants> {
   asChild?: boolean,
   isLoading?: boolean
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
   ({ className, variant, isLoading, size, asChild = false, ...props }, ref) => {
      const Comp = asChild ? Slot : 'button'
      return (
         <>
         <Comp
            disabled={isLoading}
            className={ny(buttonVariants({ variant, size, className }))}
            ref={ref}
            {...props}
         >
           {isLoading ? <Loader2 className="mr-2 h-4 w-4 animate-spin" /> : <></>}
            {props.children}
         </Comp>
         </>
      )
   },
)
Button.displayName = 'Button'

export { Button, buttonVariants }
