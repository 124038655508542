import { AppDispatch } from "~/lib/store";
import { axios } from "~/utils/axios.loader";
import { setCreateTeamsLoading, setCreateTeamsSuccess, setDeleteTeamsLoading, setDeleteTeamsSuccess, setGetTeamsSuccess, setListTeamsSuccess, setUpdateTeamsLoading, setUpdateTeamsSuccess } from "./actions";

export const getOrganizationTeams = (organization_id: string) => async (dispatch: AppDispatch) => {
    try {
        const request = await axios.get(`/teams/organizations/${organization_id}`)
        const { teams, total_count } = request.data
        dispatch(setListTeamsSuccess(teams, {
            total: total_count
        }))
    } catch (err) {

    }
}

export const getSpecificOrganizationTeam = (organization_id: string, team_id: string) => async (dispatch: AppDispatch) => {
    try {
        const request = await axios.get(`/teams/organizations/${organization_id}/${team_id}`)
        const team = request.data
        dispatch(setGetTeamsSuccess(team))
    } catch (err) {

    }
}

export const createOrganizationTeams = (organization_id: string, payload: any, callback?: () => void) => async (dispatch: AppDispatch) => {
    dispatch(setCreateTeamsLoading())
    try {
        const request = await axios.post(`/teams/organizations/${organization_id}`, payload)
        dispatch(setCreateTeamsSuccess(request.data))
        callback?.()
    } catch (err) {

    }
}

export const updateOrganizationTeams = (organization_id: string, team_id: string, payload: any, callback?: () => void) => async (dispatch: AppDispatch) => {
    dispatch(setUpdateTeamsLoading())
    try {
        const request = await axios.patch(`/teams/organizations/${organization_id}/${team_id}`, payload)
        callback?.()
        dispatch(setUpdateTeamsSuccess(request.data))
    } catch (err) {

    }
}

export const deleteOrganizationTeams = (organization_id: string, team_id: string, callback?: () => void) => async (dispatch: AppDispatch) => {
    dispatch(setDeleteTeamsLoading())
    try {
        const request = await axios.delete(`/teams/organizations/${organization_id}/${team_id}`)
        callback?.()
        dispatch(setDeleteTeamsSuccess(request.data))
    } catch (err) {

    }
}