import {
    SET_CREATE_TEAMS_ERROR,
    SET_CREATE_TEAMS_SUCCESS,
    SET_CREATE_TEAMS_LOADING,
    SET_GET_TEAMS_ERROR,
    SET_DELETE_TEAMS_ERROR,
    SET_DELETE_TEAMS_LOADING,
    SET_DELETE_TEAMS_SUCCESS,
    SET_GET_TEAMS_LOADING,
    SET_GET_TEAMS_SUCCESS,
    SET_LIST_TEAMS_ERROR,
    SET_LIST_TEAMS_LOADING,
    SET_LIST_TEAMS_SUCCESS,
    SET_UPDATE_TEAMS_ERROR,
    SET_UPDATE_TEAMS_LOADING,
    SET_UPDATE_TEAMS_SUCCESS
} from "./constants"

export const setCreateTeamsLoading = () => ({
    type: SET_CREATE_TEAMS_LOADING,
});

export const setCreateTeamsSuccess = (team: any) => ({
    type: SET_CREATE_TEAMS_SUCCESS,
    payload: team,
});

export const setCreateTeamsError = (error: string) => ({
    type: SET_CREATE_TEAMS_ERROR,
    payload: error,
});

export const setUpdateTeamsLoading = () => ({
    type: SET_UPDATE_TEAMS_LOADING,
});

export const setUpdateTeamsSuccess = (team: any) => ({
    type: SET_UPDATE_TEAMS_SUCCESS,
    payload: team,
});

export const setUpdateTeamsError = (error: string) => ({
    type: SET_UPDATE_TEAMS_ERROR,
    payload: error,
});

export const setDeleteTeamsLoading = () => ({
    type: SET_DELETE_TEAMS_LOADING,
});

export const setDeleteTeamsSuccess = (teamId: string) => ({
    type: SET_DELETE_TEAMS_SUCCESS,
    payload: teamId,
});

export const setDeleteTeamsError = (error: string) => ({
    type: SET_DELETE_TEAMS_ERROR,
    payload: error,
});

export const setGetTeamsLoading = () => ({
    type: SET_GET_TEAMS_LOADING,
});

export const setGetTeamsSuccess = (team: any) => ({
    type: SET_GET_TEAMS_SUCCESS,
    payload: team,
});

export const setGetTeamsError = (error: string) => ({
    type: SET_GET_TEAMS_ERROR,
    payload: error,
});

export const setListTeamsLoading = () => ({
    type: SET_LIST_TEAMS_LOADING,
});

export const setListTeamsSuccess = (teams: any[], metadata: any) => ({
    type: SET_LIST_TEAMS_SUCCESS,
    payload: teams,
    metadata
});

export const setListTeamsError = (error: string) => ({
    type: SET_LIST_TEAMS_ERROR,
    payload: error,
});
