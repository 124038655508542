import { axios } from "../../../utils/axios.loader";
import { AppDispatch } from "../../store";
import { setGetVoicesLoading, setGetVoicesSuccess } from "./actions";

export const getVoicesList = (page = 1, size = 20, keyword = '', filters: { accent?: string, gender?: string } = {}) => async (dispatch: AppDispatch) => {
    dispatch(setGetVoicesLoading())
    try {
        const request = await axios.get(`/agent/voice/?page=${page}&page_size=${size}${keyword ? `&filters=name[contains]=${keyword}` : ''}${filters.accent ? `&filters=accent[eq]=${filters.accent}` : ''}${filters.gender ? `&filters=gender[in]=${filters.gender}` : ''}`)
        const { voices, total_count, page: _page, page_size } = request.data;
        dispatch(setGetVoicesSuccess(voices, {
            total_count,
            page: _page,
            page_size
        }))
    } catch (err) {

    }
}

export const searchVoices = async (keyword = '', page = 1, size = 10) => {
    try {
        const request = await axios.get(`/agent/voice/?page=${page}&page_size=${size}${keyword ? `&filters=name[contains]=${keyword}` : ''}`)
        const { voices } = request.data;
        return (voices || []).map((v: any) => ({
            label: v.name,
            value: v.voice_id,
            preview: v.sample_url
        }))
    } catch (err) {
        return []
    }
}