import { BaseArrayState } from "../../types"
import { SET_GET_VOICES_ERROR, SET_GET_VOICES_LOADING, SET_GET_VOICES_SUCCESS } from "./constants"

interface VoiceLibraryState {
    list: BaseArrayState
}

const INITIAL_STATE: VoiceLibraryState = {
    list: {
        data: [],
        state: "loading",
        error: '',
        metadata: {}
    }
}

export const VoiceLibraryReducer = (state = INITIAL_STATE, action: any) => {
    switch (action.type) {
        case SET_GET_VOICES_LOADING:
            return {
                ...state,
                list: {
                    data: [],
                    state: "loading",
                    error: '',
                    metadata: {}
                }
            }
        case SET_GET_VOICES_SUCCESS:
            return {
                ...state,
                list: {
                    data: action.payload,
                    state: "success",
                    error: '',
                    metadata: action.metadata || {}
                }
            }
        case SET_GET_VOICES_ERROR:
            return {
                ...state,
                list: {
                    data: [],
                    state: "error",
                    metadata: {}
                }
            }
        default:
            return state
    }
}