import { BaseArrayState, BaseObjectState } from "../../types"
import { CLEAR_MESSAGES, DISPATCH_MESSAGE, RECIEVE_MESSAGE, SET_SOCKET_CONNECTING, SET_SOCKET_ERROR, SET_SOCKET_SUCCESS } from "./constants"

interface PlaygroundState {
    messages: BaseArrayState
    connection: BaseObjectState
}

const INITIAL_STATE: PlaygroundState = {
    messages: {
        data: []
    },
    connection: {
        state: "pending",
        data: {},
        error: ''
    }
}

export const PlaygroundReducer = (state = INITIAL_STATE, action: any) => {
    switch (action.type) {
        case SET_SOCKET_CONNECTING:
            return {
                ...state,
                connection: {
                    state: "loading",
                    data: {},
                    error: ''
                }
            }
        case SET_SOCKET_SUCCESS:
            return {
                ...state,
                connection: {
                    state: "success",
                    data: {},
                    error: ''
                }
            }
        case SET_SOCKET_ERROR:
            return {
                ...state,
                connection: {
                    state: "error",
                    data: {},
                    error: ''
                }
            }
        case CLEAR_MESSAGES:
            return {
                ...state,
                messages: {
                    ...state.messages,
                    data: []
                }
            }
        case DISPATCH_MESSAGE:
            return {
                ...state,
                messages: {
                    data: [
                        ...state.messages.data,
                        {
                            "role": "user",
                            "message": action.payload,
                            key: crypto.randomUUID()
                        }
                    ]
                }
            }
        case RECIEVE_MESSAGE:
            return {
                ...state,
                messages: {
                    data: [
                        ...state.messages.data,
                        {
                            "role": "ai",
                            "message": action.payload,
                            key: crypto.randomUUID()
                        }
                    ]
                }
            }
        default:
            return state
    }
}