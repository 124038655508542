import {
    SET_FEEDBACK_CREATE_LOADING,
    SET_FEEDBACK_CREATE_SUCCESS,
    SET_FEEDBACK_CREATE_ERROR,
    SET_FEEDBACK_UPDATE_LOADING,
    SET_FEEDBACK_UPDATE_SUCCESS,
    SET_FEEDBACK_UPDATE_ERROR,
    SET_FEEDBACK_DELETE_LOADING,
    SET_FEEDBACK_DELETE_SUCCESS,
    SET_FEEDBACK_DELETE_ERROR,
    SET_FEEDBACK_LIST_LOADING,
    SET_FEEDBACK_LIST_SUCCESS,
    SET_FEEDBACK_LIST_ERROR,
    SET_FEEDBACK_GET_LOADING,
    SET_FEEDBACK_GET_SUCCESS,
    SET_FEEDBACK_GET_ERROR
} from './constants';

// Feedback Create Actions
export function setFeedbackCreateLoading() {
    return {
        type: SET_FEEDBACK_CREATE_LOADING
    }
}

export function setFeedbackCreateSuccess(payload: any, metadata?: any) {
    return {
        type: SET_FEEDBACK_CREATE_SUCCESS,
        payload,
        metadata
    }
}

export function setFeedbackCreateError(error: any) {
    return {
        type: SET_FEEDBACK_CREATE_ERROR,
        error
    }
}

// Feedback Update Actions
export function setFeedbackUpdateLoading() {
    return {
        type: SET_FEEDBACK_UPDATE_LOADING
    }
}

export function setFeedbackUpdateSuccess(payload: any, metadata?: any) {
    return {
        type: SET_FEEDBACK_UPDATE_SUCCESS,
        payload,
        metadata
    }
}

export function setFeedbackUpdateError(error: any) {
    return {
        type: SET_FEEDBACK_UPDATE_ERROR,
        error
    }
}

// Feedback Delete Actions
export function setFeedbackDeleteLoading() {
    return {
        type: SET_FEEDBACK_DELETE_LOADING
    }
}

export function setFeedbackDeleteSuccess(payload: any, metadata?: any) {
    return {
        type: SET_FEEDBACK_DELETE_SUCCESS,
        payload,
        metadata
    }
}

export function setFeedbackDeleteError(error: any) {
    return {
        type: SET_FEEDBACK_DELETE_ERROR,
        error
    }
}

// Feedback List Actions
export function setFeedbackListLoading() {
    return {
        type: SET_FEEDBACK_LIST_LOADING
    }
}

export function setFeedbackListSuccess(payload: any, metadata?: any) {
    return {
        type: SET_FEEDBACK_LIST_SUCCESS,
        payload,
        metadata
    }
}

export function setFeedbackListError(error: any) {
    return {
        type: SET_FEEDBACK_LIST_ERROR,
        error
    }
}

// Feedback Get Actions
export function setFeedbackGetLoading() {
    return {
        type: SET_FEEDBACK_GET_LOADING
    }
}

export function setFeedbackGetSuccess(payload: any, metadata?: any) {
    return {
        type: SET_FEEDBACK_GET_SUCCESS,
        payload,
        metadata
    }
}

export function setFeedbackGetError(error: any) {
    return {
        type: SET_FEEDBACK_GET_ERROR,
        error
    }
}
