import { axios } from "../../../utils/axios.loader";
import { AppDispatch } from "../../store";
import { setTeamCreateError, setTeamCreateLoading, setTeamCreateSuccess, setTeamDeleteLoading, setTeamDeleteSuccess, setTeamListLoading, setTeamListSuccess, setTeamUpdateLoading, setTeamUpdateSuccess } from "./actions";

export const getTeam = (organization: string, keyword: string = '', {
    page = 1,
    size = 10
}) => async (dispatch: AppDispatch) => {
    dispatch(setTeamListLoading())
    try {
        const request = await axios.get(`/organization/${organization}/users?page=${page}&page_size=${size}${keyword ? `&filters[]=name[contains]=${keyword}` : ''}`)
        const { users, total_count } = request.data
        dispatch(setTeamListSuccess(users, {
            total_count
        }))
    } catch (err) {}
}

export const addMemberTeam = (organization: string, email: string, role: string, name: string, callback?: (data?: any) => void, onerror?:() => void) => async (dispatch: AppDispatch) => {
    dispatch(setTeamCreateLoading())
    try {
        const request = await axios.post(`/organization/${organization}/users`, {
            email,
            name,
            role
        })
        const response = request.data
        dispatch(setTeamCreateSuccess(response))
        callback?.(response)
    } catch (err) {
        dispatch(setTeamCreateError("Member already invited"))
        onerror?.()
    }
}

export const updateMemberTeam = (organization: string, user: string, role: string, callback?: (data?: any) => void) => async (dispatch: AppDispatch) => {
    dispatch(setTeamUpdateLoading())
    try {
        const request = await axios.patch(`/organization/${organization}/users/${user}`, {
            role
        })
        const response = request.data
        dispatch(setTeamUpdateSuccess(response))
        callback?.(response)
    } catch (err) {}
}

export const removeMemberTeam = (organization: string, user: string, callback?: (data: any) => void) => async (dispatch: AppDispatch) => {
    dispatch(setTeamDeleteLoading())
    try {
        const request = await axios.delete(`/organization/${organization}/users/${user}`)
        const response = request.data
        dispatch(setTeamDeleteSuccess(response))
        callback?.(response)
    } catch (err) {}
}