export const SET_AGENT_CREATE_LOADING = "assistants/create/loading"
export const SET_AGENT_CREATE_SUCCESS = "assistants/create/success"
export const SET_AGENT_CREATE_ERROR = "assistants/create/error"
export const RESET_AGENT_CREATE = "assistants/create/reset"
export const SET_AGENT_UPDATE_LOADING = "assistants/update/loading"
export const SET_AGENT_UPDATE_SUCCESS = "assistants/update/success"
export const SET_AGENT_UPDATE_ERROR = "assistants/update/error"
export const RESET_AGENT_UPDATE = "assistants/update/reset"
export const SET_GET_AGENT_LOADING = "assistants/get/loading"
export const SET_GET_AGENT_SUCCESS = "assistants/get/success"
export const SET_GET_AGENT_ERROR = "assistants/get/error"
export const SET_GET_AGENT_RESET = "assistants/get/reset"
export const SET_AGENT_ANALYSIS_LOADING = "assistants/analysis/loading"
export const SET_AGENT_ANALYSIS_SUCCESS = "assistants/analysis/success"
export const SET_AGENT_ANALYSIS_ERROR = "assistants/analysis/error"