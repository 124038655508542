import { Box, Button, Center, Divider, Flex, Grid, Input, Skeleton, Stack, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr } from "@chakra-ui/react";
import Page from "../../@components/Page";
import Chart from "react-apexcharts";
import _ from "lodash";
import { generateCanonicalNames } from "../../utils/helper.utils";
import { useAppDispatch, useAppSelector } from "../../lib/hooks";
import { loadAnalytics } from "../../lib/app/home/thunk";
import { useEffect, useState } from "react";
import moment from "moment-timezone";
import { ChevronLeftIcon, ChevronRightIcon, ChevronsUpDownIcon, MessagesSquareIcon, PlusIcon } from "lucide-react";
import ReactWordcloud from "react-wordcloud";
import { AsyncSelect, Select, chakraComponents } from "chakra-react-select";
import { loadAgents, searchAgents } from "../../lib/app/platform/thunk";
import { useNavigate } from "react-router-dom";
import { getAgentAnalysis } from "../../lib/app/assistants/thunk";


const dropdownStyles: any = {
    dropdownIndicator: (b: any) => ({
      ...b,
      backgroundColor: "transparent",
      borderColor: "transparent",
      border: 0,
    }),
    control: (b: any) => ({
      ...b,
      borderRadius: 8,
    }),
    menuList: (b: any) => ({
      ...b,
      borderRadius: 8,
    }),
  };
  
  const dropdownComponents = {
    DropdownIndicator: (props: any) => (
      <chakraComponents.DropdownIndicator {...props}>
        <ChevronsUpDownIcon size={12} />
      </chakraComponents.DropdownIndicator>
    ),
  };

export default function Analytics() {
  const analytics = useAppSelector((state) => state.app.home.analytics);
  const analysis = useAppSelector((state) => state.app.assistants.analysis);
  const dispatch = useAppDispatch();
  const organization = useAppSelector((state) => state.app.organizations.get);
  const [range, setRange] = useState<moment.DurationInputArg2>("month");
  const [data, setData] = useState<{
    totalCalls?: number;
    connectedCalls?: number;
    successfulCalls?: number;
  }>({});
  const [history, setHistory] = useState<any[]>([])
  const [assistantId, setAssistantId] = useState();
  const [selectedAssistant, setSelectedAssistant] = useState<{ label?: string, value?: string }>();
  const [assistantOptions, setAssistantOptions] = useState<{ label?: string, value?: string }[]>([])
  const [assistantState, setAssistantState] = useState<string>("loading")
  const navigate = useNavigate()

  useEffect(() => {
    if (analytics.data?.length) {
      const _data = analytics.data[0]?.result?.filter(
        (v: any) => v.assistantId === assistantId
      );
      const totalCalls = (_data || []).reduce(
        (accumulator: any, currentValue: any) => {
          return accumulator + Number.parseInt(currentValue.countId, 10);
        },
        0
      );
      const connectedCalls = (_data || [])
        .filter((a: any) =>
          [
            "customer-ended-call",
            "assistant-ended-call",
            "assistant-forwarded-call",
          ].includes(a.endedReason)
        )
        .reduce((accumulator: any, currentValue: any) => {
          return accumulator + Number.parseInt(currentValue.countId, 10);
        }, 0);
      const successfulCalls = (_data || [])
        .filter((a: any) =>
          ["customer-ended-call", "assistant-ended-call"].includes(
            a.endedReason
          )
        )
        .reduce((accumulator: any, currentValue: any) => {
          return accumulator + Number.parseInt(currentValue.countId, 10);
        }, 0);
      setData({
        totalCalls,
        connectedCalls,
        successfulCalls,
      });
    }
  }, [analytics, assistantId]);

  useEffect(() => {
    if (organization.data?.providers?.vapi?.api_key) {
      const key = organization.data?.id;
      const start = moment().subtract(3, range).toDate().toISOString();
      const end = moment().toDate().toISOString();
      dispatch(
        loadAnalytics(key, [
          {
            name: "Number of Calls",
            table: "call",
            operations: [
              {
                operation: "count",
                column: "id",
              },
            ],
            groupBy: ["assistantId", "endedReason"],
            timeRange: {
              start: start,
              end: end,
              step: "day",
              timezone: "UTC",
            },
          }
        ])
      );
    }
  }, [organization, range]);

  useEffect(() => {
    if (organization.data?.id) {

        (async () => {
            const options = await searchAgents(organization.data.id!, 1, 25, '')
            setSelectedAssistant(options[0])
            setAssistantId(options[0].providerId)
            setAssistantState("success")
            setAssistantOptions(options)
        })()
    }
  }, [ organization ])

  useEffect(() => {
    if (selectedAssistant?.value) {
      dispatch(getAgentAnalysis(selectedAssistant?.value!))
    }
  }, [ selectedAssistant ])

  return (
    <Page title="Analytics">
       {!assistantOptions.length && assistantState === "success" ? <Center height={"calc(90vh - 84px)"}>
          <Stack align={"center"}>
              <MessagesSquareIcon size={30} />
              <Text fontWeight={600} fontSize={"lg"}>
                No agents found
              </Text>
              <Button
              boxShadow={`0 0 12px -2px rgba(254, 91, 37, 0), 0 0 12px -2px rgba(163, 85, 247, 0), 0 0 4px 2px rgba(35, 39, 46, .24), inset 0 2px 2px rgba(255, 255, 255, .24)`}
              _hover={{
                boxShadow: `0 0 12px -2px var(--chakra-colors-primary-200), 0 0 12px -2px #a355f7, 0 0 4px 2px rgba(35, 39, 46, .24), inset 0 2px 2px 0 rgba(255, 255, 255, .24)`,
              }}
              onClick={() => {
                navigate(`/app/${organization.data.id}/assistants?open=true`)
              }}
              size={"sm"}
              colorScheme="primary"
              leftIcon={<PlusIcon size={16} />}
            >
              Create your first assistant
            </Button>
            </Stack>
        </Center> : <Box>
          <Flex mx={3}>
        <Skeleton isLoaded={assistantState === "success"}>
        <Stack w={300}>
            <Text fontWeight={600} fontSize={"sm"}>Choose agent</Text>
            <AsyncSelect
            colorScheme="primary"
            chakraStyles={dropdownStyles}
            components={dropdownComponents}
            size={"sm"}
            onChange={(v: any) => {
              setSelectedAssistant(v)
              setAssistantId(v.providerId)
            }}
            value={selectedAssistant}
            isLoading={!assistantOptions?.length}
            variant="outline"
            defaultOptions={assistantOptions}
            loadOptions={(input: string) => {
                if (organization.data.id) {
                    return searchAgents(organization.data.id!, 1, 25, input);
                }
            }}
          />
        </Stack>
        </Skeleton>
        </Flex>
      <Stack>
      <Grid mr={3} gridTemplateColumns={"repeat(2, 1fr)"}>
      <Skeleton isLoaded={assistantState === "success"}><Stack
            px={2}
            py={2}
            border={"1px solid #e4e4e4"}
            rounded={8}
            mt={4}
            ml={4}
            w={"-webkit-fill-available"}
          >
            <Grid
              gap={4}
              justifyContent={"space-between"}
              gridTemplateColumns={"repeat(3, 1fr)"}
              mb={3}
            >
              <Box borderRight={"1px solid #e4e4e4"}>
                <Text fontWeight={700}>Total calls</Text>
                <Text color={"gray"} fontWeight={600} fontSize={12}>
                  Total number of calls
                </Text>
                <Text fontWeight={"bold"} fontSize={"4xl"}>
                  {data.totalCalls}
                </Text>
              </Box>
              <Box borderRight={"1px solid #e4e4e4"}>
                <Text fontWeight={700}>Connected calls</Text>
                <Text color={"gray"} fontWeight={600} fontSize={12}>
                  Total number of calls connected
                </Text>
                <Text fontWeight={"bold"} fontSize={"4xl"}>
                  {data.connectedCalls}
                </Text>
              </Box>
              <Box>
                <Text fontWeight={700}>Successful calls</Text>
                <Text color={"gray"} fontWeight={600} fontSize={12}>
                  Total number of calls successful
                </Text>
                <Text fontWeight={"bold"} fontSize={"4xl"}>
                  {data.successfulCalls}
                </Text>
              </Box>
            </Grid>
            <Divider />
            {data.totalCalls ? (
              <Chart
                height={375}
                options={{
                  chart: {
                    type: "donut",
                    width: "200%",
                  },
                  colors: ["#8884d8aa", "#82ca9daa", "#ffc658aa", "#ff8042aa"],
                  dataLabels: {
                    enabled: false,
                  },
                  stroke: {
                    lineCap: "round",
                  },
                  xaxis: {
                    type: "datetime",
                  },
                  legend: {
                    position: "bottom",
                  },
                  tooltip: {
                    enabled: true,
                  },
                  plotOptions: {
                    pie: {
                      donut: {
                        size: "45%",
                      },
                    },
                  },
                  labels: [
                    "Total Calls",
                    "Connected Calls",
                    "Successful Calls",
                  ],
                }}
                series={[
                  data.totalCalls!,
                  data.connectedCalls!,
                  data.successfulCalls!,
                ]}
                type="donut"
              />
            ) : (
              <></>
            )}
          </Stack></Skeleton>
          <Skeleton isLoaded={assistantState === "success"}><Stack
            px={2}
            py={2}
            border={"1px solid #e4e4e4"}
            rounded={8}
            mt={4}
            ml={4}
            w={"-webkit-fill-available"}
          >
            <Grid
              gap={4}
              justifyContent={"space-between"}
              gridTemplateColumns={"repeat(3, 1fr)"}
              mb={3}
            >
              <Box borderRight={"1px solid #e4e4e4"}>
                <Text fontWeight={700}>% Positive</Text>
                <Text color={"gray"} fontWeight={600} fontSize={12}>
                  No of positive sentiment calls
                </Text>
                <Text fontWeight={"bold"} fontSize={"4xl"}>
                  {analysis.data.intents?.POSITIVE || 0}
                </Text>
              </Box>
              <Box borderRight={"1px solid #e4e4e4"}>
                <Text fontWeight={700}>% Neutral</Text>
                <Text color={"gray"} fontWeight={600} fontSize={12}>
                  No of neutral sentiment calls
                </Text>
                <Text fontWeight={"bold"} fontSize={"4xl"}>
                {analysis.data.intents?.NEUTRAL || 0}
                </Text>
              </Box>
              <Box>
                <Text fontWeight={700}>% Negative</Text>
                <Text color={"gray"} fontWeight={600} fontSize={12}>
                  No of negative sentiment calls
                </Text>
                <Text fontWeight={"bold"} fontSize={"4xl"}>
                {analysis.data.intents?.NEGATIVE || 0}
                </Text>
              </Box>
            </Grid>
            <Divider />
            {data.totalCalls ? (
              <Chart
                height={375}
                options={{
                  chart: {
                    type: "pie",
                    width: "200%",
                  },
                  dataLabels: {
                    enabled: false,
                  },
                  stroke: {
                    lineCap: "round",
                  },
                  xaxis: {
                    type: "datetime",
                  },
                  legend: {
                    position: "bottom",
                  },
                  tooltip: {
                    enabled: true,
                  },
                  plotOptions: {
                    pie: {},
                  },
                  labels: [
                    "No of positive sentiment calls",
                    "No of neutral sentiment calls",
                    "No of negative sentiment calls",
                  ],
                }}
                series={[
                  (analysis.data.intents?.POSITIVE || 0),
                  (analysis.data.intents?.NEUTRAL || 0),
                  (analysis.data.intents?.NEGATIVE || 0),
                ]}
                type="pie"
              />
            ) : (
              <></>
            )}
          </Stack></Skeleton>
        </Grid>
        <Skeleton isLoaded={assistantState === "success"}><Grid mr={3} gap={6} gridTemplateColumns={"repeat(2, 1fr)"}>
          <Stack
            px={2}
            py={2}
            pb={8}
            border={"1px solid #e4e4e4"}
            rounded={8}
            mt={4}
            flex={0.6}
            ml={4}
            w={"-webkit-fill-available"}
          >
            <Box mb={3}>
              <Text fontWeight={700}>Reason Conversation Ended</Text>
              <Text color={"gray"} fontWeight={600} fontSize={12}>
                Here you can get a quick overview of how your calls are going
                within your organization.
              </Text>
            </Box>
            <Divider />
            <Box>
                {data.totalCalls ? (
                <Chart
                    height={375}
                    options={{
                    chart: {
                        type: "bar",
                        width: "200%",
                        toolbar: {
                            show: false
                        }
                    },
                    dataLabels: {
                        enabled: false,
                    },
                    stroke: {
                        lineCap: "round",
                    },
                    xaxis: {
                        type: "category",
                        labels: {
                          rotateAlways: false,
                          rotate: 0
                        }
                    },
                    legend: {
                        position: "bottom",
                    },
                    tooltip: {
                        enabled: true,
                    },
                    plotOptions: {
                        pie: {},
                    },
                    labels: Object.keys(
                        _.groupBy(
                          analytics.data?.[0]?.result || [],
                          (v: any) => {
                            return generateCanonicalNames(v.endedReason);
                          }
                        )
                      ) as unknown as string[],
                    }}
                    series={[
                        {
                            name: "Reason Conversation Ended",
                            data: Object.values(
                                _.groupBy(
                                  analytics.data?.[0]?.result || [],
                                  (v: any) => {
                                    return generateCanonicalNames(v.endedReason);
                                  }
                                )
                              ).map((v: any) => v.reduce(
                                (accumulator: any, currentValue: any) => {
                                  return accumulator + Number.parseInt(currentValue.countId, 10);
                                },
                                0
                              )) as number[],
                        }
                    ]}
                    type="bar"
                />
                ) : (
                <></>
                )}
            </Box>
          </Stack>
          <Stack 
              flex={0.4} p={2} rounded={8} border={"1px solid #e4e4e4"} mt={4}>
              <Box mb={3}>
                <Text fontWeight={700}>Top questions</Text>
                <Text color={"gray"} fontWeight={600} fontSize={12}>
                    Top questions of the calls
                </Text>
              </Box>

              <TableContainer h={"fit-content"}>
                <Table size={"sm"} variant="simple">
                  <Thead>
                    <Tr>
                      <Th
                        py={2}
                        textTransform={"capitalize"}
                        fontWeight={600}
                        fontSize={"xs"}
                      >
                        Question
                      </Th>
                      <Th
                        py={2}
                        textTransform={"capitalize"}
                        fontWeight={600}
                        fontSize={"xs"}
                      >
                        Total Count
                      </Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {Object.keys(analysis.data.questions || {}).map(q => <Tr py={2}>
                        <Td fontWeight={600}>{q}</Td>
                        <Td>{analysis.data.questions[q]}</Td>
                    </Tr>)}
                  </Tbody>
                </Table>
                <Flex py={2} gap={2}>
                  <Button
                    isDisabled
                    variant={"outline"}
                    fontSize={"xs"}
                    leftIcon={<ChevronLeftIcon size={16} />}
                    size={"sm"}
                  >
                    Previous
                  </Button>
                  <Input
                    size={"sm"}
                    w={10}
                    rounded={8}
                    value={1}
                    textAlign={"center"}
                  />
                  <Button
                    isDisabled
                    variant={"outline"}
                    fontSize={"xs"}
                    rightIcon={<ChevronRightIcon size={16} />}
                    size={"sm"}
                  >
                    Next
                  </Button>
                </Flex>
              </TableContainer>
            </Stack>
        </Grid></Skeleton>
        <Skeleton isLoaded={assistantState === "success"}><Stack mx={4}
              flex={0.4} p={2} rounded={8} border={"1px solid #e4e4e4"} mt={4}>
            <Box mb={3}>
            <Text fontWeight={700}>Top keywords</Text>
            </Box>
            <Divider />
            <Box mx={"auto"}>
            {data.totalCalls ? <ReactWordcloud options={{
                rotations: 0,
                fontSizes: [20, 60],
                fontFamily: "Urbanist"
            }} size={[600, 400]} words={Object.keys(analysis.data.words || {}).map(v => ({
              text: v,
              value: analysis.data.words[v]
            }))} /> : <></>}
            </Box>
        </Stack></Skeleton>
      </Stack>
      </Box>}
    </Page>
  );
}
