import { SET_AGENT_ERROR, SET_AGENT_LOADING, SET_AGENT_SUCCESS, SET_COMPANY, SET_CONFIG_ERROR, SET_CONFIG_LOADING, SET_CONFIG_SUCCESS, SET_LIST_AGENTS_ERROR, SET_LIST_AGENTS_LOADING, SET_LIST_AGENTS_SUCCESS, SET_PLATFORM_ERROR, SET_PLATFORM_LOADING, SET_PLATFORM_SUCCESS, SET_USER_PROFILE } from "./constants"
import { Agent, Company, UserProfile } from "./types"

export function setPlatformLoading () {
    return {
        type: SET_PLATFORM_LOADING
    }
}

export function setPlatformSuccess () {
    return {
        type: SET_PLATFORM_SUCCESS
    }
}

export function setPlatformError (error?: string) {
    return {
        type: SET_PLATFORM_ERROR,
        error
    }
}


export function setUserProfile (profile: UserProfile) {
    return {
        type: SET_USER_PROFILE,
        payload: profile
    }
}

export function setCompany (company: Company) {
    return {
        type: SET_COMPANY,
        payload: company
    }
}

export function setListAgentsLoading () {
    return {
        type: SET_LIST_AGENTS_LOADING
    }
}

export function setListAgentsSuccess (agents: any) {
    return {
        type: SET_LIST_AGENTS_SUCCESS,
        payload: agents
    }
}

export function setListAgentsError () {
    return {
        type: SET_LIST_AGENTS_ERROR
    }
}

export function setAgentLoading () {
    return {
        type: SET_AGENT_LOADING
    }
}

export function setAgentSuccess (agent: Agent) {
    return {
        type: SET_AGENT_SUCCESS,
        payload: agent
    }
}

export function setAgentsError () {
    return {
        type: SET_AGENT_ERROR
    }
}

export function setConfigLoading () {
    return {
        type: SET_CONFIG_LOADING
    }
}

export function setConfigSuccess (payload: any) {
    return {
        type: SET_CONFIG_SUCCESS,
        payload
    }
}

export function setConfigError () {
    return {
        type: SET_CONFIG_ERROR
    }
}