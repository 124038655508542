import { set } from "date-fns";
import { VapiAPIManager } from "../../../utils/vapi.manager";
import { AppDispatch } from "../../store";
import { setAnalyticsLoading, setAnalyticsSuccess, setMetricsLoading, setMetricsSuccess } from "./actions";

export const loadMetrics = (organization_id: string, start: string, end: string) => async (dispatch: AppDispatch) => {
    dispatch(setMetricsLoading())
    try {
        const manager = new VapiAPIManager(organization_id)
        const metrics = await manager.getMetrics(start, end)
        dispatch(setMetricsSuccess(metrics[0]))
    } catch (err) {

    }
}

export const loadAnalytics = (organization_id: string, queries: any[]) => async (dispatch: AppDispatch) => {
    dispatch(setAnalyticsLoading())
    try {
        const manager = new VapiAPIManager(organization_id)
        const analytics = await manager.getAnalytics(queries)
        const assistants = await manager.getAssistants()
        dispatch(setAnalyticsSuccess(analytics, {
            assistants
        }))
    } catch (err) {

    }
}