'use client'

import * as React from 'react'
import {
   CheckIcon,
   ChevronRightIcon,
   DotFilledIcon,
} from '@radix-ui/react-icons'
import * as MenubarPrimitive from '@radix-ui/react-menubar'

import { ny } from '~/lib/utils'

const MenubarMenu = MenubarPrimitive.Menu

const MenubarGroup = MenubarPrimitive.Group

const MenubarPortal = MenubarPrimitive.Portal

const MenubarSub = MenubarPrimitive.Sub

const MenubarRadioGroup = MenubarPrimitive.RadioGroup

const Menubar = React.forwardRef<
   React.ElementRef<typeof MenubarPrimitive.Root>,
   React.ComponentPropsWithoutRef<typeof MenubarPrimitive.Root>
>(({ className, ...props }, ref) => (
   <MenubarPrimitive.Root
      ref={ref}
      className={ny(
         'bg-background flex h-9 items-center space-x-1 rounded-md border p-1 shadow-sm',
         className,
      )}
      {...props}
   />
))
Menubar.displayName = MenubarPrimitive.Root.displayName

const MenubarTrigger = React.forwardRef<
   React.ElementRef<typeof MenubarPrimitive.Trigger>,
   React.ComponentPropsWithoutRef<typeof MenubarPrimitive.Trigger>
>(({ className, ...props }, ref) => (
   <MenubarPrimitive.Trigger
      ref={ref}
      className={ny(
         'focus:bg-accent focus:text-accent-foreground data-[state=open]:bg-accent data-[state=open]:text-accent-foreground flex cursor-default select-none items-center rounded-sm px-3 py-1 text-sm font-medium outline-none',
         className,
      )}
      {...props}
   />
))
MenubarTrigger.displayName = MenubarPrimitive.Trigger.displayName

const MenubarSubTrigger = React.forwardRef<
   React.ElementRef<typeof MenubarPrimitive.SubTrigger>,
   React.ComponentPropsWithoutRef<typeof MenubarPrimitive.SubTrigger> & {
      inset?: boolean
   }
>(({ className, inset, children, ...props }, ref) => (
   <MenubarPrimitive.SubTrigger
      ref={ref}
      className={ny(
         'focus:bg-accent focus:text-accent-foreground data-[state=open]:bg-accent data-[state=open]:text-accent-foreground flex cursor-default select-none items-center rounded-sm px-2 py-1.5 text-sm outline-none',
         inset && 'pl-8',
         className,
      )}
      {...props}
   >
      {children}
      <ChevronRightIcon className="ml-auto size-4" />
   </MenubarPrimitive.SubTrigger>
))
MenubarSubTrigger.displayName = MenubarPrimitive.SubTrigger.displayName

const MenubarSubContent = React.forwardRef<
   React.ElementRef<typeof MenubarPrimitive.SubContent>,
   React.ComponentPropsWithoutRef<typeof MenubarPrimitive.SubContent>
>(({ className, ...props }, ref) => (
   <MenubarPrimitive.SubContent
      ref={ref}
      className={ny(
         'bg-popover text-popover-foreground data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2 z-50 min-w-32 overflow-hidden rounded-md border p-1 shadow-lg',
         className,
      )}
      {...props}
   />
))
MenubarSubContent.displayName = MenubarPrimitive.SubContent.displayName

const MenubarContent = React.forwardRef<
   React.ElementRef<typeof MenubarPrimitive.Content>,
   React.ComponentPropsWithoutRef<typeof MenubarPrimitive.Content>
>(
   (
      { className, align = 'start', alignOffset = -4, sideOffset = 8, ...props },
      ref,
   ) => (
      <MenubarPrimitive.Portal>
         <MenubarPrimitive.Content
            ref={ref}
            align={align}
            alignOffset={alignOffset}
            sideOffset={sideOffset}
            className={ny(
               'bg-popover text-popover-foreground data-[state=open]:animate-in data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2 z-50 min-w-48 overflow-hidden rounded-md border p-1 shadow-md',
               className,
            )}
            {...props}
         />
      </MenubarPrimitive.Portal>
   ),
)
MenubarContent.displayName = MenubarPrimitive.Content.displayName

const MenubarItem = React.forwardRef<
   React.ElementRef<typeof MenubarPrimitive.Item>,
   React.ComponentPropsWithoutRef<typeof MenubarPrimitive.Item> & {
      inset?: boolean
   }
>(({ className, inset, ...props }, ref) => (
   <MenubarPrimitive.Item
      ref={ref}
      className={ny(
         'focus:bg-accent focus:text-accent-foreground relative flex cursor-default select-none items-center rounded-sm px-2 py-1.5 text-sm outline-none data-[disabled]:pointer-events-none data-[disabled]:opacity-50',
         inset && 'pl-8',
         className,
      )}
      {...props}
   />
))
MenubarItem.displayName = MenubarPrimitive.Item.displayName

const MenubarCheckboxItem = React.forwardRef<
   React.ElementRef<typeof MenubarPrimitive.CheckboxItem>,
   React.ComponentPropsWithoutRef<typeof MenubarPrimitive.CheckboxItem>
>(({ className, children, checked, ...props }, ref) => (
   <MenubarPrimitive.CheckboxItem
      ref={ref}
      className={ny(
         'focus:bg-accent focus:text-accent-foreground relative flex cursor-default select-none items-center rounded-sm py-1.5 pl-8 pr-2 text-sm outline-none data-[disabled]:pointer-events-none data-[disabled]:opacity-50',
         className,
      )}
      checked={checked}
      {...props}
   >
      <span className="absolute left-2 flex size-3.5 items-center justify-center">
         <MenubarPrimitive.ItemIndicator>
            <CheckIcon className="size-4" />
         </MenubarPrimitive.ItemIndicator>
      </span>
      {children}
   </MenubarPrimitive.CheckboxItem>
))
MenubarCheckboxItem.displayName = MenubarPrimitive.CheckboxItem.displayName

const MenubarRadioItem = React.forwardRef<
   React.ElementRef<typeof MenubarPrimitive.RadioItem>,
   React.ComponentPropsWithoutRef<typeof MenubarPrimitive.RadioItem>
>(({ className, children, ...props }, ref) => (
   <MenubarPrimitive.RadioItem
      ref={ref}
      className={ny(
         'focus:bg-accent focus:text-accent-foreground relative flex cursor-default select-none items-center rounded-sm py-1.5 pl-8 pr-2 text-sm outline-none data-[disabled]:pointer-events-none data-[disabled]:opacity-50',
         className,
      )}
      {...props}
   >
      <span className="absolute left-2 flex size-3.5 items-center justify-center">
         <MenubarPrimitive.ItemIndicator>
            <DotFilledIcon className="size-4 fill-current" />
         </MenubarPrimitive.ItemIndicator>
      </span>
      {children}
   </MenubarPrimitive.RadioItem>
))
MenubarRadioItem.displayName = MenubarPrimitive.RadioItem.displayName

const MenubarLabel = React.forwardRef<
   React.ElementRef<typeof MenubarPrimitive.Label>,
   React.ComponentPropsWithoutRef<typeof MenubarPrimitive.Label> & {
      inset?: boolean
   }
>(({ className, inset, ...props }, ref) => (
   <MenubarPrimitive.Label
      ref={ref}
      className={ny(
         'px-2 py-1.5 text-sm font-semibold',
         inset && 'pl-8',
         className,
      )}
      {...props}
   />
))
MenubarLabel.displayName = MenubarPrimitive.Label.displayName

const MenubarSeparator = React.forwardRef<
   React.ElementRef<typeof MenubarPrimitive.Separator>,
   React.ComponentPropsWithoutRef<typeof MenubarPrimitive.Separator>
>(({ className, ...props }, ref) => (
   <MenubarPrimitive.Separator
      ref={ref}
      className={ny('bg-muted -mx-1 my-1 h-px', className)}
      {...props}
   />
))
MenubarSeparator.displayName = MenubarPrimitive.Separator.displayName

function MenubarShortcut({
   className,
   ...props
}: React.HTMLAttributes<HTMLSpanElement>) {
   return (
      <span
         className={ny(
            'text-muted-foreground ml-auto text-xs tracking-widest',
            className,
         )}
         {...props}
      />
   )
}
MenubarShortcut.displayname = 'MenubarShortcut'

export {
   Menubar,
   MenubarMenu,
   MenubarTrigger,
   MenubarContent,
   MenubarItem,
   MenubarSeparator,
   MenubarLabel,
   MenubarCheckboxItem,
   MenubarRadioGroup,
   MenubarRadioItem,
   MenubarPortal,
   MenubarSubContent,
   MenubarSubTrigger,
   MenubarGroup,
   MenubarSub,
   MenubarShortcut,
}
