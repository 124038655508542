import { InfoOutlineIcon } from "@chakra-ui/icons";
import {
  Box,
  Center,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  Heading,
  Image,
  SlideFade,
  Stack,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { Field, FieldArray, Form, Formik } from "formik";
import { ArrowRightIcon, BookMarkedIcon, BriefcaseBusinessIcon, BuildingIcon, HandshakeIcon, HeadsetIcon } from "lucide-react";
import { useEffect, useState } from "react";
import TeamHome from "~/assets/img/home.png";
import AgentHome from "~/assets/img/agent.png";
import { Badge } from "~/components/ui/badge";
import { Button } from "~/components/ui/button";
import { Input } from "~/components/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "~/components/ui/select";
import { Switch } from "~/components/ui/switch";
import { Textarea } from "~/components/ui/textarea";
import { useLocalStorage } from "@uidotdev/usehooks";
import { useAppDispatch, useAppSelector } from "~/lib/hooks";
import { getOrganization } from "~/lib/app/organization/thunk";
import { loadUserProfile } from "~/lib/app/platform/thunk";
import { createAgent, createOrganization, createV2Agent, inviteTeamMates } from "~/lib/app/get-started/thunk";
import { useNavigate } from "react-router-dom";

export default function Welcome() {
  const [tab, setCurrentTab] = useState(0);
  const agent = useAppSelector((state) => state.app.onboarding.agent);
  const invite = useAppSelector((state) => state.app.onboarding.invite);
  const [organizationCache, saveOrganizationCache] = useLocalStorage<{
    id?: string,
    name?: string,
    description?: string
  }>("organization", {});
  const organization = useAppSelector(
    (state) => state.app.onboarding.organization
  );
  const [assistantCache, saveAssistantCache] = useLocalStorage<{
    id?: string,
    name?: string,
    description?: string
  }>("assistant", {});
  const [teamCache, saveTeamCache] = useLocalStorage<{
    id?: string,
    name?: string,
    description?: string
  }>("team", {});
  const [stage, saveStage] = useLocalStorage("stage", 0);
  const profile = useAppSelector(state => state.app.platform.profile)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    if (organizationCache.id) {
      dispatch(getOrganization(organizationCache?.id));
    }
  }, [organizationCache])

  useEffect(() => {
    setCurrentTab(stage)
  }, [stage])

  useEffect(() => {
    dispatch(loadUserProfile())
  }, [])

  return (
    <Flex w={"full"} h={"100vh"}>
      <Center flex={0.5} h={"full"}>
        <Box w={"30vw"}>
          <Tabs index={tab} isLazy>
            <TabPanels>
              <TabPanel>
                <Stack>
                  <SlideFade in={tab === 0} delay={0.1}>
                    <Stack>
                      <Badge className="w-fit" variant={"outline"}>
                        {tab + 1} / 3
                      </Badge>
                      <Heading fontWeight={"black"} fontSize={28}>
                        Setup your organization
                      </Heading>
                      <Text>
                        Let's start by creating your workspace and settings
                        things up
                      </Text>
                    </Stack>
                  </SlideFade>
                  <Stack mt={4}>
                    <Formik
                      onSubmit={(values) => {
                        if (!organizationCache?.id) {
                          dispatch(
                            createOrganization(
                              {
                                description: values.description,
                                image_url: "https://avatar.vercel.sh/acme",
                                name: values.name,
                                industry: values.industry,
                                providers: {
                                  vapi: {
                                    api_key: "",
                                  },
                                },
                              },
                              (data?: any, team?: any) => {
                                saveStage(tab + 1);
                                setCurrentTab(tab + 1);
                                saveOrganizationCache(data)
                                saveTeamCache(team)
                              }
                            )
                          );
                        } else {
                          saveStage(tab + 1);
                          setCurrentTab(tab + 1);
                        }
                      }}
                      initialValues={{
                        name: "",
                        description: "",
                        industry: "",
                        newsletter: true,
                      }}
                    >
                      {({ errors, touched, values, setFieldValue }) => (
                        <Form>
                          <Stack gap={3}>
                            <SlideFade in={tab === 0} delay={0.2}>
                              <FormControl
                                isInvalid={!!errors.name && touched.name}
                              >
                                <FormLabel fontWeight={500} fontSize={"sm"}>
                                  Name of your organization{" "}
                                </FormLabel>
                                <Field
                                  name="name"
                                  fontWeight={500}
                                  value={values.name}
                                  background
                                  as={Input}
                                  validate={(v: string) => {
                                    let error;
                                    if (!v) {
                                      error = "Organisation name is required";
                                    }
                                    return error;
                                  }}
                                  fontSize={"sm"}
                                  placeholder="Acme, Inc"
                                />
                                <FormErrorMessage fontWeight={500}>
                                  {errors.name}
                                </FormErrorMessage>
                              </FormControl>
                            </SlideFade>
                            <SlideFade in={tab === 0} delay={0.3}>
                              <FormControl
                                isInvalid={!!errors.industry}
                              >
                                <FormLabel fontWeight={500} fontSize={"sm"}>
                                  Choose your industry{" "}
                                </FormLabel>
                                <Field as={Select}
                                  value={values.industry}
                                  name={"industry"}
                                  validate={(value: string) => {
                                    let error;
                                    if (!value) {
                                      error = "Industry is required"
                                    }
                                    return error
                                  }}
                                  onValueChange={(v: string) => {
                                    console.log(v)
                                    setFieldValue("industry", v)
                                  }}
                                  defaultValue={values.industry}
                                >
                                  <SelectTrigger>
                                    <SelectValue placeholder="Select industry" />
                                  </SelectTrigger>
                                  <SelectContent>
                                    <SelectItem value="technology">
                                      Technology
                                    </SelectItem>
                                    <SelectItem value="finance">
                                      Finance
                                    </SelectItem>
                                    <SelectItem value="healthcare">
                                      Healthcare
                                    </SelectItem>
                                    <SelectItem value="education">
                                      Education
                                    </SelectItem>
                                    <SelectItem value="retail">
                                      Retail
                                    </SelectItem>
                                    <SelectItem value="manufacturing">
                                      Manufacturing
                                    </SelectItem>
                                    <SelectItem value="real_estate">
                                      Real Estate
                                    </SelectItem>
                                    <SelectItem value="media">Media</SelectItem>
                                    <SelectItem value="hospitality">
                                      Hospitality
                                    </SelectItem>
                                    <SelectItem value="transportation">
                                      Transportation
                                    </SelectItem>
                                    <SelectItem value="logistics">
                                      Logistics
                                    </SelectItem>
                                    <SelectItem value="nonprofit">
                                      Nonprofit
                                    </SelectItem>
                                    <SelectItem value="government">
                                      Government
                                    </SelectItem>
                                    <SelectItem value="energy">
                                      Energy
                                    </SelectItem>
                                    <SelectItem value="construction">
                                      Construction
                                    </SelectItem>
                                  </SelectContent>
                                </Field>
                                <FormErrorMessage fontWeight={500}>
                                  {errors.industry}
                                </FormErrorMessage>
                              </FormControl>
                            </SlideFade>
                            <SlideFade in={tab === 0} delay={0.4}>
                              <FormControl
                                isInvalid={
                                  !!errors.description && touched.description
                                }
                              >
                                <FormLabel fontWeight={500} fontSize={"sm"}>
                                  About{" "}
                                </FormLabel>
                                <Field
                                  name="description"
                                  fontWeight={500}
                                  value={values.description}
                                  background
                                  as={Textarea}
                                  validate={(v: string) => {
                                    let error;
                                    if (!v) {
                                      error =
                                        "Description is required";
                                    }
                                    return error;
                                  }}
                                  fontSize={"sm"}
                                  placeholder="An AI saas organization"
                                />
                                <FormErrorMessage fontWeight={500}>
                                  {errors.description}
                                </FormErrorMessage>
                              </FormControl>
                            </SlideFade>
                            <SlideFade in={tab === 0} delay={0.5}>
                              <FormControl
                                mt={2}
                              >
                                <Flex
                                  alignItems={"center"}
                                  justifyContent={"space-between"}
                                  className="border rounded-lg p-4 shadow-sm"
                                >
                                  <div className="space-y-0.5">
                                    <FormLabel
                                      className={"text-sm !font-semibold"}
                                    >
                                      Subscribe to our newsletter
                                    </FormLabel>
                                    <Text className={"text-xs"}>
                                      Receive emails about new releases,
                                      playbooks, and more.
                                    </Text>
                                  </div>
                                  <Switch
                                    checked={values.newsletter}
                                    onCheckedChange={(value: boolean) => {
                                      setFieldValue("newsletter", value);
                                    }}
                                  />
                                </Flex>
                              </FormControl>
                            </SlideFade>
                          </Stack>
                          <SlideFade in={tab === 0} delay={0.6}>
                            <Flex justifyContent={"end"} mt={4}>
                              <Button
                                isLoading={organization.state === "loading"}
                                className="font-semibold"
                                variant={"dark"}
                                type="submit"
                              >
                                Create organization
                              </Button>
                            </Flex>
                          </SlideFade>
                        </Form>
                      )}
                    </Formik>
                  </Stack>
                </Stack>
              </TabPanel>
              <TabPanel>
              <Stack>
                  <SlideFade in={tab === 1} delay={0.1}>
                    <Stack>
                      <Badge className="w-fit" variant={"outline"}>
                        {tab + 1} / 3
                      </Badge>
                      <Heading fontWeight={"black"} fontSize={28}>
                        Create your first agent
                      </Heading>
                      <Text>
                        Let's start by creating your agent for your team
                      </Text>
                    </Stack>
                  </SlideFade>
                  <Stack mt={4}>
                    <Formik
                      onSubmit={(values) => {
                        if (values.use_case === "RESEARCH") {
                          dispatch(createAgent({
                            name: values.name,
                            system_prompt: values.about,
                            about: values.about,
                            ai_model_config: {
                              max_tokens: 250,
                              personality: "AUTO"
                            },
                            use_case: values.use_case,
                            ai_model_name: "gpt-4o",
                            interaction_mode: "CONVERSATIONAL",
                            status: "ONLINE",
                            advanced_config: {},
                            team_id: teamCache?.id,
                            organization_id: organizationCache?.id || organization.data.id,
                          },
                          (id: string, data?: any) => {
                            saveAssistantCache(data)
                            saveStage(2)
                            setCurrentTab(2)
                          }))
                        } else {
                        dispatch(
                          createV2Agent(
                            {
                              name: values.name,
                              system_prompt: values.about,
                              about: values.about,
                              ai_model_config: {
                                max_tokens: 250,
                                personality: "AUTO"
                              },
                              transcriber_config: {},
                              voice_config: {
                                voice_id: "21m00Tcm4TlvDq8ikWAM",
                                voice_name: "Rachel",
                              },
                              voice_id: "ba7a019e-df90-4229-811b-b72b4e712a45",
                              status: "OFFLINE",
                              use_case: values.use_case,
                              ai_model_name: "gpt-4o",
                              interaction_mode: "CONVERSATIONAL",
                              advanced_config: {},
                              team_id: teamCache?.id,
                              organization_id: organizationCache?.id || organization.data.id,
                              recommended_voices: [
                                {
                                    "label": "Saira - Young Casual Voice",
                                    "value": "vghiSqG5ezdhd8F3tKAD",
                                    "id": "c8ec34d4-95ca-476a-a77f-95a65d26f92c",
                                    "voice_id": "vghiSqG5ezdhd8F3tKAD",
                                    "voice_provider": "ELEVENLABS",
                                    "name": "Saira - Young Casual Voice",
                                    "description": null,
                                    "gender": "FEMALE",
                                    "accent": "BRITISH",
                                    "tone": null,
                                    "age_category": "YOUNG",
                                    "category": "PREMADE",
                                    "use_case": "CONVERSATIONAL",
                                    "sample_url": "https://storage.googleapis.com/eleven-public-prod/custom/voices/vghiSqG5ezdhd8F3tKAD/lVQiUua3EmORrlbKpfr0.mp3",
                                    "created_at": "2023-11-28T00:27:31.819752",
                                    "updated_at": "2023-11-28T00:27:31.819756"
                                },
                                {
                                    "label": "Hope - natural conversations",
                                    "value": "OYTbf65OHHFELVut7v2H",
                                    "id": "f907a5dd-4f86-4227-9c40-7f51820ec0fa",
                                    "voice_id": "OYTbf65OHHFELVut7v2H",
                                    "voice_provider": "ELEVENLABS",
                                    "name": "Hope - natural conversations",
                                    "description": "",
                                    "gender": "FEMALE",
                                    "accent": "AMERICAN",
                                    "tone": "CONVERSATIONAL",
                                    "age_category": "YOUNG",
                                    "category": "PREMADE",
                                    "use_case": "CONVERSATIONAL",
                                    "sample_url": "https://storage.googleapis.com/eleven-public-prod/custom/voices/OYTbf65OHHFELVut7v2H/ZN73m0kIWmrTlinxqSiN.mp3",
                                    "created_at": "2024-08-02T00:45:41.397547",
                                    "updated_at": "2024-08-02T00:45:41.397547"
                                },
                                {
                                    "label": "Barbara - Eloquent, intelligent female\r\n",
                                    "value": "kwZZS3WAVfk7EncqOz2K",
                                    "id": "5c108a13-b5b4-48cf-a5a1-c40bd25bf82a",
                                    "voice_id": "kwZZS3WAVfk7EncqOz2K",
                                    "voice_provider": "ELEVENLABS",
                                    "name": "Barbara - Eloquent, intelligent female\r\n",
                                    "description": "A young female British speaker, eloquent and intelligent, who tends to speak somewhat rapidly.Great for podcast.",
                                    "gender": "FEMALE",
                                    "accent": "BRITISH",
                                    "tone": "CONVERSATIONAL",
                                    "age_category": "YOUNG",
                                    "category": "PREMADE",
                                    "use_case": "CONVERSATIONAL",
                                    "sample_url": "https://storage.googleapis.com/eleven-public-prod/udmG0I9oKegHHyrU3sEvatdvG2p1/voices/xt3HPA16vjtEep1aKvP2/4fb33ada-8003-4e0e-b367-5de11dae9bf9.mp3",
                                    "created_at": "2024-08-02T00:45:43.974683",
                                    "updated_at": "2024-08-02T00:45:43.974683"
                                },
                                {
                                    "label": "Archer",
                                    "value": "L0Dsvb3SLTyegXwtm47J",
                                    "id": "01763abb-6dce-4f35-afd0-d390a0a18f93",
                                    "voice_id": "L0Dsvb3SLTyegXwtm47J",
                                    "voice_provider": "ELEVENLABS",
                                    "name": "Archer",
                                    "description": "Conversational, calm British, thirty-something, perfect for an AI agent.",
                                    "gender": "MALE",
                                    "accent": "BRITISH",
                                    "tone": "CONVERSATIONAL",
                                    "age_category": "MIDDLE_AGED",
                                    "category": "PREMADE",
                                    "use_case": "CONVERSATIONAL",
                                    "sample_url": "https://storage.googleapis.com/eleven-public-prod/custom/voices/L0Dsvb3SLTyegXwtm47J/EKoXN08bacukhTjtp6sq.mp3",
                                    "created_at": "2024-08-02T00:45:43.654923",
                                    "updated_at": "2024-08-02T00:45:43.654923"
                                },
                                {
                                    "label": "Miriam - Casual and Wry",
                                    "value": "Ah0Fg1nzczXgBpMPTtQb",
                                    "id": "31014311-bc44-410b-bd1d-d9a20a1748dd",
                                    "voice_id": "Ah0Fg1nzczXgBpMPTtQb",
                                    "voice_provider": "ELEVENLABS",
                                    "name": "Miriam - Casual and Wry",
                                    "description": "Middle aged female with an Australian accent. Voice is low, smooth and casual. Great for conversations.",
                                    "gender": "FEMALE",
                                    "accent": "AUSTRALIAN",
                                    "tone": "CONVERSATIONAL",
                                    "age_category": "MIDDLE_AGED",
                                    "category": "PREMADE",
                                    "use_case": "CONVERSATIONAL",
                                    "sample_url": "https://storage.googleapis.com/eleven-public-prod/0uIqkkS46jQxfFloXWQg5TWCzKm1/voices/JKpK5B1pXInRQlSuN4pQ/6bc93aa0-5c0b-408c-8b5f-1b6d7bdb8f2d.mp3",
                                    "created_at": "2024-08-02T00:45:47.892662",
                                    "updated_at": "2024-08-02T00:45:47.892662"
                                },
                                {
                                    "label": "Sam - Aussie",
                                    "value": "3DkcznWTIDSnX3f0J6DG",
                                    "id": "7c1c5caf-5bd9-4f4a-a3c6-e35ad3defb3c",
                                    "voice_id": "3DkcznWTIDSnX3f0J6DG",
                                    "voice_provider": "ELEVENLABS",
                                    "name": "Sam - Aussie",
                                    "description": "A young adult male voice hailing from down under, showcasing relatable, friendly Aussie accent.  The voice of the everyman! (well, one of them!)  A good fit for a project where a conversational, casual style is needed. Would be great for YouTube or social media.",
                                    "gender": "MALE",
                                    "accent": "AUSTRALIAN",
                                    "tone": "CONVERSATIONAL",
                                    "age_category": "YOUNG",
                                    "category": "PREMADE",
                                    "use_case": "CONVERSATIONAL",
                                    "sample_url": "https://storage.googleapis.com/eleven-public-prod/custom/voices/3DkcznWTIDSnX3f0J6DG/fZmMOcPbqy0B6gBZ2sLW.mp3",
                                    "created_at": "2024-08-02T00:45:48.973501",
                                    "updated_at": "2024-08-02T00:45:48.973501"
                                },
                                {
                                    "label": "Trixie - Young Gossip Girl",
                                    "value": "c7XGL37TTXR5zdorzHX9",
                                    "id": "429993a9-2010-460f-bb98-ea5ab307c22d",
                                    "voice_id": "c7XGL37TTXR5zdorzHX9",
                                    "voice_provider": "ELEVENLABS",
                                    "name": "Trixie - Young Gossip Girl",
                                    "description": "A youthful, energetic, gossip girl voice. Perfect for storytelling, conversations, podcasts, entertainment, and pop culture. ",
                                    "gender": "FEMALE",
                                    "accent": "AMERICAN",
                                    "tone": "CONVERSATIONAL",
                                    "age_category": "YOUNG",
                                    "category": "PREMADE",
                                    "use_case": "CONVERSATIONAL",
                                    "sample_url": "https://storage.googleapis.com/eleven-public-prod/custom/voices/c7XGL37TTXR5zdorzHX9/wDROlBcDXPW683m7wN1v.mp3",
                                    "created_at": "2024-08-02T00:45:50.696860",
                                    "updated_at": "2024-08-02T00:45:50.696860"
                                },
                                {
                                    "label": "Christopher - friendly guy next door",
                                    "value": "RPEIZnKMqlQiZyZd1Dae",
                                    "id": "5435c18c-80c3-4d80-b92e-47b0ac4591eb",
                                    "voice_id": "RPEIZnKMqlQiZyZd1Dae",
                                    "voice_provider": "ELEVENLABS",
                                    "name": "Christopher - friendly guy next door",
                                    "description": "A middle age American male with a slight raspiness in his voice and a mix of an East Coast and Midwestern accent. Great for conversations & Explainer videos.",
                                    "gender": "MALE",
                                    "accent": "AMERICAN",
                                    "tone": "CONVERSATIONAL",
                                    "age_category": "MIDDLE_AGED",
                                    "category": "PREMADE",
                                    "use_case": "CONVERSATIONAL",
                                    "sample_url": "https://storage.googleapis.com/eleven-public-prod/custom/voices/RPEIZnKMqlQiZyZd1Dae/P8rxSTTRjB7TxLxZ89Cd.mp3",
                                    "created_at": "2024-08-02T00:45:44.647374",
                                    "updated_at": "2024-08-02T00:45:44.647374"
                                },
                                {
                                    "label": "Raju - Relatable Hindi Voice",
                                    "value": "zT03pEAEi0VHKciJODfn",
                                    "id": "b4e2c67d-7ade-40bc-9b62-ef61edc74a39",
                                    "voice_id": "zT03pEAEi0VHKciJODfn",
                                    "voice_provider": "ELEVENLABS",
                                    "name": "Raju - Relatable Hindi Voice",
                                    "description": "This voice has the familiar warmth and approachability of a regular Indian speaker, yet it carries a professionalism that makes it versatile for a range of applications. It's clear and articulate enough to engage audiences on social media, provide guidance through customer care bots, and serve in various other communication roles. Its authentic Indian tone adds a layer of relatability without compromising on clarity or effectiveness, making it an excellent choice for connecting with audiences.",
                                    "gender": "MALE",
                                    "accent": "INDIAN",
                                    "tone": "CONVERSATIONAL",
                                    "age_category": "MIDDLE_AGED",
                                    "category": "PREMADE",
                                    "use_case": "CONVERSATIONAL",
                                    "sample_url": "https://storage.googleapis.com/eleven-public-prod/custom/voices/zT03pEAEi0VHKciJODfn/E1SwTL1qIKPkIxyZQQfk.mp3",
                                    "created_at": "2024-08-02T00:45:48.628098",
                                    "updated_at": "2024-08-02T00:45:48.628098"
                                },
                                {
                                    "label": "Muskaan - Casual Hindi Voice",
                                    "value": "xoV6iGVuOGYHLWjXhVC7",
                                    "id": "5f82ad42-7f0e-4e0e-ad4a-7795598bf25d",
                                    "voice_id": "xoV6iGVuOGYHLWjXhVC7",
                                    "voice_provider": "ELEVENLABS",
                                    "name": "Muskaan - Casual Hindi Voice",
                                    "description": "Muskaan is the pen name of a very experienced financial trainer in India. Her voice is very natural and feels relatable, unlike the corporate tone that often feels disconnected. This voice will be very good for use cases like social media, conversational contexts, and e-learning content.",
                                    "gender": "FEMALE",
                                    "accent": "INDIAN",
                                    "tone": "CONVERSATIONAL",
                                    "age_category": "MIDDLE_AGED",
                                    "category": "PREMADE",
                                    "use_case": "CONVERSATIONAL",
                                    "sample_url": "https://storage.googleapis.com/eleven-public-prod/custom/voices/xoV6iGVuOGYHLWjXhVC7/HLJ6hooUJSQwgFVGM32b.mp3",
                                    "created_at": "2024-08-02T00:45:31.477778",
                                    "updated_at": "2024-08-02T00:45:31.477778"
                                }
                            ]
                            },
                            (id: string, data?: any) => {
                              // navigate(
                              //   `/app/${organizationCache?.id || organization.data.id}/assistants/${id}`
                              // );
                              saveAssistantCache(data)
                              saveStage(2)
                              setCurrentTab(2)
                            }
                          )
                        );
                        }
                      }}
                      initialValues={{
                        name: "My First Agent",
                        about: "",
                        use_case: "RESEARCH",
                        newsletter: true,
                      }}
                    >
                      {({ errors, touched, values, setFieldValue }) => (
                        <Form>
                          <Stack gap={4}>
                            <SlideFade in={tab === 1} delay={0.2}>
                              <FormControl
                                isInvalid={!!errors.name && touched.name}
                              >
                                <FormLabel fontWeight={500} fontSize={"sm"}>
                                  Name of your agent{" "}
                                </FormLabel>
                                <Field
                                  name="name"
                                  fontWeight={500}
                                  value={values.name}
                                  background
                                  as={Input}
                                  validate={(v: string) => {
                                    let error;
                                    if (!v) {
                                      error = "Organisation name is required";
                                    }
                                    return error;
                                  }}
                                  fontSize={"sm"}
                                  placeholder="Acme, Inc"
                                />
                                <FormErrorMessage fontWeight={500}>
                                  {errors.name}
                                </FormErrorMessage>
                              </FormControl>
                            </SlideFade>
                            <SlideFade in={tab === 1} delay={0.3}>
                              <FormControl
                                isInvalid={!!errors.use_case && touched.use_case}
                              >
                                <FormLabel fontWeight={500} fontSize={"sm"}>
                                  Select agent purpose{" "}
                                </FormLabel>
                                <Grid gap={4} gridTemplateColumns={'repeat(2, 1fr)'}>
                                    <Box onClick={() => {
                                      setFieldValue("use_case", "RESEARCH")
                                    }} pos={"relative"} cursor={"pointer"} className={`border rounded-xl ${values.use_case === "RESEARCH" ? "bg-primary text-white" : "hover:bg-blue-50/50"} transition-all duration-300`} p={4}>
                                        <BookMarkedIcon />
                                        <Text className="font-semibold mt-1">Research</Text>
                                        <Text className={`${values.use_case === "RESEARCH" ? "text-white": "text-muted-foreground"} text-xs`}>A research agent that does any kind of research for your</Text>
                                    </Box>
                                    <Box onClick={() => {
                                      setFieldValue("use_case", "SUPPORT")
                                    }} pos={"relative"} cursor={"pointer"} className={`border rounded-xl ${values.use_case === "SUPPORT" ? "bg-primary text-white" : "hover:bg-blue-50/50"} transition-all duration-300`} p={4}>
                                        <HeadsetIcon />
                                        <Text className="font-semibold mt-1">Support</Text>
                                        <Text className={`${values.use_case === "SUPPORT" ? "text-white": "text-muted-foreground"} text-xs`}>A support agent for your organization</Text>
                                    </Box>
                                    <Box onClick={() => {
                                      setFieldValue("use_case", "SALES")
                                    }} pos={"relative"} cursor={"pointer"} className={`border rounded-xl ${values.use_case === "SALES" ? "bg-primary text-white" : "hover:bg-blue-50/50"} transition-all duration-300`} p={4}>
                                        <BuildingIcon />
                                        <Text className="font-semibold mt-1">Sales</Text>
                                        <Text className={`${values.use_case === "SALES" ? "text-white": "text-muted-foreground"} text-xs`}>A sales agent to handle your outbound</Text>
                                    </Box>
                                    <Box onClick={() => {
                                      setFieldValue("use_case", "RECRUITMENT")
                                    }} pos={"relative"} cursor={"pointer"} className={`border rounded-xl ${values.use_case === "RECRUITMENT" ? "bg-primary text-white" : "hover:bg-blue-50/50"} transition-all duration-300`} p={4}>
                                        <BriefcaseBusinessIcon />
                                        <Text className="font-semibold mt-1">Recruitment</Text>
                                        <Text className={`${values.use_case === "RECRUITMENT" ? "text-white": "text-muted-foreground"} text-xs`}>A call agent that acts as your receruiter</Text>
                                    </Box>
                                </Grid>
                                <FormErrorMessage fontWeight={500}>
                                  {errors.use_case}
                                </FormErrorMessage>
                              </FormControl>
                            </SlideFade>
                            <SlideFade in={tab === 1} delay={0.4}>
                              <FormControl
                                isInvalid={
                                  !!errors.about && touched.about
                                }
                              >
                                <FormLabel fontWeight={500} fontSize={"sm"}>
                                  Tell us in detail what your agent should do{" "}
                                </FormLabel>
                                <Field
                                rows={4}
                                  name="about"
                                  fontWeight={500}
                                  value={values.about}
                                  background
                                  as={Textarea}
                                  validate={(v: string) => {
                                    let error;
                                    if (!v) {
                                      error =
                                        "About is required";
                                    }
                                    return error;
                                  }}
                                  fontSize={"sm"}
                                  placeholder="An smart assistant that does my task"
                                />
                                <FormErrorMessage fontWeight={500}>
                                  {errors.about}
                                </FormErrorMessage>
                              </FormControl>
                            </SlideFade>
                          </Stack>
                          <SlideFade in={tab === 1} delay={0.6}>
                            <Flex justifyContent={"end"} mt={4}>
                              <Button
                                isLoading={agent.state === "loading"}
                                className="font-semibold"
                                variant={"dark"}
                                type="submit"
                              >
                                Create agent
                              </Button>
                            </Flex>
                          </SlideFade>
                        </Form>
                      )}
                    </Formik>
                  </Stack>
                </Stack>
              </TabPanel>
              <TabPanel>
              <Stack>
                  <SlideFade in={tab === 2} delay={0.1}>
                    <Stack>
                      <Badge className="w-fit" variant={"outline"}>
                        {tab + 1} / 3
                      </Badge>
                      <Heading fontWeight={"black"} fontSize={28}>
                        Invite your team
                      </Heading>
                      <Text>
                        Add team members to manage your agents
                      </Text>
                    </Stack>
                  </SlideFade>
                  <Stack mt={4}>
                    <Formik
                      onSubmit={(values) => {
                         dispatch(inviteTeamMates(organizationCache?.id || organization.data?.id, values.emails.filter(Boolean), () => {
                          navigate(
                            `/app/${organizationCache?.id || organization.data.id}/home`
                          );
                          saveOrganizationCache({})
                          saveAssistantCache({})
                          saveStage(0)
                         }))
                      }}
                      initialValues={{
                        emails: [
                          "",
                          "",
                          ""
                        ]
                      }}
                    >
                      {({ errors, touched, values, setFieldValue }) => (
                        <Form>
                            <FieldArray name="emails" render={({ insert, remove, push }) => (
                                <Stack h={240} gap={4}>
                                  {values.emails.map((v: string, i: number) => {
                                    return (
                                      <SlideFade key={i} in={tab === 2} delay={0.1}>
                                        <FormControl isInvalid={!!errors.emails?.[i]}>
                                          <Field validate={(v: string) => {
                                            let error;
                                            if (v && !v.includes("@")) {
                                              error = "Invalid email address"
                                            }
                                            return error
                                          }} as={Input} value={v} name={`emails.${i}`} placeholder="Enter member's email address" />
                                          <FormErrorMessage>{errors.emails?.[i] as string}</FormErrorMessage>
                                        </FormControl>
                                      </SlideFade>
                                    )
                                  })}
                                </Stack>
                              )} />
                          <SlideFade in={tab === 2} delay={0.4}>
                            <Flex gap={3} justifyContent={"end"} mt={4}>
                             <Button
                                onClick={() => {
                                  navigate(
                                    `/app/${organizationCache?.id || organization.data.id}/home`
                                  );
                                  saveOrganizationCache({})
                                  saveAssistantCache({})
                                  saveTeamCache({})
                                  saveStage(0)
                                }}
                                className="font-semibold"
                                variant={"outline"}
                                type="button"
                              >
                                Skip for now
                              </Button>
                              <Button
                                isLoading={invite.state === "loading"}
                                className="font-semibold"
                                variant={"dark"}
                                type="submit"
                              >
                                Take me in 
                                <ArrowRightIcon size={16} />
                              </Button>
                            </Flex>
                          </SlideFade>
                        </Form>
                      )}
                    </Formik>
                  </Stack>
                </Stack>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>
      </Center>
      <Box
        overflow={"hidden"}
        className="border-l"
        pos={"relative"}
        flex={0.5}
        h={"full"}
      >
        {tab === 0 ? <Image
        w={500}
          rounded={10}
          bottom={"-20%"}
          right={"-10%"}
          pos={"absolute"}
          src={TeamHome}
        /> : <Box>
            <Image
        rounded={10}
        w={500}
         className="border rounded-xl"
        bottom={"-30%"}
        right={"0%"}
        pos={"absolute"}
        src={AgentHome}
      /> </Box>}
      </Box>
    </Flex>
  );
}
