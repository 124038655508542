import {
  Avatar,
  Box,
  Button,
  Center,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  Heading,
  IconButton,
  Img,
  Input,
  List,
  ListIcon,
  ListItem,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tag,
  Text,
  Textarea,
  Tooltip,
} from "@chakra-ui/react";
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  BrainIcon,
  Building2Icon,
  CodesandboxIcon,
  HelpCircleIcon,
  LogOutIcon,
} from "lucide-react";
import Logo from "../../assets/img/logo-full.png";
import Asset1 from "../../assets/img/image1.png";
import Asset2 from "../../assets/img/image2.png";
import Asset3 from "../../assets/img/image3.png";
import { CheckCircleIcon, InfoOutlineIcon } from "@chakra-ui/icons";
import { Field, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import Lottie from "lottie-react";
import LoadingAnimation from "../../assets/animation/loading.json";
import Timer from "../../@components/timer/timer";
import { initialTabs } from "../../@components/lib/constants";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../lib/hooks";
import {
  createOrganization,
  createV2Agent,
} from "../../lib/app/get-started/thunk";
import { getOrganization } from "../../lib/app/organization/thunk";
import { useLocalStorage } from "@uidotdev/usehooks";
import { loadUserProfile } from "../../lib/app/platform/thunk";
import _ from "lodash";
import { useCookies } from "react-cookie";

export default function GetStarted() {
  const [currentTab, setCurrentTab] = useState(0);
  const [currentTemplate, setCurrentTemplate] = useState(0);
  const navigate = useNavigate();
  const organisation = useAppSelector(
    (state) => state.app.onboarding.organization
  );
  const agent = useAppSelector((state) => state.app.onboarding.agent);
  const profile = useAppSelector(state => state.app.platform.profile)
  const dispatch = useAppDispatch();
  const [organizationCache, saveOrganizationCache] = useLocalStorage<{
    id?: string,
    name?: string,
    description?: string
  }>("organization", {});
  const [stage, saveStage] = useLocalStorage("stage", 0);
  const [cookies, setCookie, removeCookie] = useCookies([
    "accessToken",
    "refreshToken",
  ]);
  const [assistantCache, saveAssistantCache] = useLocalStorage("assistant", {});

  const [countingStatus, setCountingStatus] = useState("pause");
  
  const statusHandler = (item: any) => {
    if (item.label === "short break") {
      dispatch(getOrganization(organizationCache?.id || organisation.data.id));
      navigate(`/app/${organisation.data.id}/home`);
    }
  };

  useEffect(() => {
    if (organizationCache.id) {
      dispatch(getOrganization(organizationCache?.id));
    }
  }, [organizationCache])

  useEffect(() => {
    setCurrentTab(stage)
  }, [stage])

  useEffect(() => {
    dispatch(loadUserProfile())
  }, [])

  return (
    <Stack h={"100vh"} justifyContent={"space-between"}>
      <Flex px={3} h={'64px'} justifyContent={"space-between"} alignItems={"center"}>
        <Img h={10} src={Logo} />
          <Flex gap={2} alignItems={"center"}>
            <Avatar src={profile?.data?.img} name={profile?.data?.username} size={"sm"} />
            <Box>
              <Text fontWeight={600}>{_.capitalize(profile.data?.username)}</Text>
              <Text fontWeight={500} fontSize={"sm"}>{profile.data?.email}</Text>
            </Box>
            <IconButton
              size={"xs"}
              onClick={() => {
                saveOrganizationCache({})
                removeCookie("accessToken", {
                  path: "/",
                });
                removeCookie("refreshToken", {
                  path: "/",
                });
                navigate("/auth/login?logout=true");
                saveStage(0)
              }}
              aria-label=""
              icon={<LogOutIcon size={18} />}
            />
          </Flex>
      </Flex>
      <Center flex={1} height={"calc(100vh - 64px)"}>
        {" "}
        {/**#f0eee5 */}
        <Stack h={540} w={"50vw"} gap={10}>
          {/* <Stepper
              size={"sm"}
              colorScheme={"primary"}
              index={activeStep}
            >
              {steps.map((step, index) => (
                <Step style={{ justifyContent: "center" }} key={index}>
                  <StepIndicator justifyContent={"center"} style={{ background: activeStep === index ? "var(--chakra-colors-primary-500)" : "gray", color: "white" }}>
                    <StepStatus
                      complete={<StepIcon />}
                      incomplete={<StepNumber />}
                      active={<StepNumber />}
                    />
                  </StepIndicator>
                  <Box color={activeStep === index ? "var(--chakra-colors-primary-500)" : "inherit"} flexShrink="0">
                    <StepTitle>{step.title}</StepTitle>
                  </Box>
                  <StepSeparator />
                </Step>
              ))}
            </Stepper> */}
          {currentTab <= 2 ? (
            <Tabs isLazy index={currentTab} size={"md"} colorScheme="primary">
              <TabList mb={4} gap={4} borderBottom={0}>
                <Tab
                  isDisabled={currentTab !== 0}
                  fontSize={"sm"}
                  gap={2}
                  fontWeight={700}
                >
                  <Building2Icon size={20} /> Create Organization
                </Tab>
                <Tab
                  isDisabled={currentTab !== 1}
                  fontSize={"sm"}
                  gap={2}
                  fontWeight={700}
                >
                  <CodesandboxIcon size={20} /> Choose package
                </Tab>
                <Tab
                  isDisabled={currentTab !== 2}
                  fontSize={"sm"}
                  gap={2}
                  fontWeight={700}
                >
                  <BrainIcon size={20} /> Setup Assistant
                </Tab>
              </TabList>
              {/* <TabIndicator height='3px' bg='var(--chakra-colors-primary-500)' borderRadius='3px' /> */}
              <TabPanels>
                <TabPanel width={"40vw"} pl={0}>
                  <Heading size={"lg"}>Name Your Organization</Heading>
                  <Text fontSize={"sm"} fontWeight={600} mb={10} mt={1}>
                    An organisation is the home for all your assistants
                  </Text>
                  <Formik
                    onSubmit={(values) => {
                      if (!organizationCache?.id) {
                        dispatch(
                          createOrganization(
                            {
                              description: values.description,
                              image_url: "https://example.com/acme_logo.png",
                              name: values.name,
                              providers: {
                                vapi: {
                                  api_key: "",
                                },
                              },
                            },
                            (data?: any) => {
                              saveStage(currentTab + 1);
                              setCurrentTab(currentTab + 1);
                              saveOrganizationCache(data)
                            }
                          )
                        );
                      } else {
                        saveStage(currentTab + 1);
                        setCurrentTab(currentTab + 1);
                      }
                    }}
                    initialValues={{
                      name: organizationCache?.name || "",
                      description: organizationCache?.description || "",
                    }}
                  >
                    {({ errors, touched, values }) => (
                      <Form
                        style={{
                          width: "100%",
                        }}
                        onChange={() => {}}
                      >
                        <Stack gap={4} mt={4}>
                          <FormControl
                            isInvalid={!!errors.name && touched.name}
                          >
                            <FormLabel fontWeight={500} fontSize={"sm"}>
                              Organization Name{" "}
                              <Tooltip hasArrow label="info icon">
                                <InfoOutlineIcon cursor={"pointer"} />
                              </Tooltip>
                            </FormLabel>
                            <Field
                              name="name"
                              fontWeight={500}
                              value={values.name}
                              background
                              as={Input}
                              validate={(v: string) => {
                                let error;
                                if (!v) {
                                  error = "Organisation name is required";
                                }
                                return error;
                              }}
                              fontSize={"sm"}
                              placeholder="Acme, Inc"
                            />
                            <FormErrorMessage fontWeight={500}>
                              {errors.name}
                            </FormErrorMessage>
                          </FormControl>
                          {/* <FormControl isInvalid={!!errors.vapi_api_key && touched.vapi_api_key}>
                          <FormLabel fontSize={"sm"}>
                            Vapi API Key {" "}
                            <Tooltip hasArrow label="info icon">
                              <InfoOutlineIcon cursor={"pointer"} />
                            </Tooltip>
                          </FormLabel>
                          <Field
                            name="vapi_api_key"
                            value={values.vapi_api_key}
                            background
                            as={Input}
                            validate={(v: string) => {
                              let error;
                              if (!v) {
                                error = "API Key is required";
                              }
                              return error;
                            }}
                            fontSize={"sm"}
                            placeholder="Vapi api key"
                          />
                          <FormErrorMessage>{errors.vapi_api_key}</FormErrorMessage>
                        </FormControl> */}
                          <FormControl
                            isInvalid={
                              !!errors.description && touched.description
                            }
                          >
                            <FormLabel fontSize={"sm"}>
                              About{" "}
                              <Tooltip hasArrow label="info icon">
                                <InfoOutlineIcon cursor={"pointer"} />
                              </Tooltip>
                            </FormLabel>
                            <Field
                              name="description"
                              value={values.description}
                              background
                              as={Textarea}
                              rows={4}
                              maxLength={250}
                              validate={(v: string) => {
                                let error;
                                if (!v) {
                                  error = "About organisation is required";
                                }
                                return error;
                              }}
                              fontSize={"sm"}
                              placeholder="About your organization"
                            />
                            <FormErrorMessage>
                              {errors.description}
                            </FormErrorMessage>
                          </FormControl>
                          <Button
                            isLoading={organisation.state === "loading"}
                            size={"sm"}
                            colorScheme={"primary"}
                            rounded={4}
                            w={"fit-content"}
                            fontSize={"sm"}
                            type="submit"
                          >
                            Continue
                          </Button>
                        </Stack>
                      </Form>
                    )}
                  </Formik>
                </TabPanel>
                <TabPanel>
                  <Heading size={"lg"}>Select a package</Heading>
                  <Text fontSize={"sm"} mb={10} mt={1}>
                    A package will get you started with the basic setup required
                    for your business
                  </Text>
                  <Grid gap={3} gridTemplateColumns={"repeat(3, 1fr)"}>
                    <Stack
                      pos={"relative"}
                      transition={"all 0.3s"}
                      cursor={"pointer"}
                      style={{
                        background: currentTemplate === 0 ? "#d7d4fd" : "white",
                        color: currentTemplate === 0 ? "black" : "black",
                      }}
                      onClick={() => {
                        setCurrentTemplate(0);
                      }}
                      border={`1px solid ${
                        currentTemplate === 0 ? "#d7d4fd" : "#e4e4e4"
                      }`}
                      bg={"white"}
                      p={4}
                      borderRadius={12}
                    >
                      <CheckCircleIcon
                        boxSize={5}
                        display={currentTemplate === 0 ? "block" : "none"}
                        pos={"absolute"}
                        top={4}
                        right={4}
                        color={"var(--chakra-colors-primary-500)"}
                      />
                      <Img w={16} h={16} src={Asset1} />
                      <Stack gap={1}>
                        <Heading size={"md"}>Response</Heading>
                        <Text
                          fontSize={"sm"}
                          color={currentTemplate === 1 ? "black" : "gray"}
                        >
                          For inbound calls
                        </Text>
                        <List
                          flexDirection={"column"}
                          display={"flex"}
                          gap={1}
                          listStyleType={"none"}
                          fontSize={"sm"}
                          fontWeight={500}
                        >
                          <ListItem>
                            <ListIcon as={CheckCircleIcon} color="green.500" />
                            Inbound Request
                          </ListItem>
                          <ListItem>
                            <ListIcon as={CheckCircleIcon} color="green.500" />
                            Both Customer, Prospect
                          </ListItem>
                          <ListItem>
                            <ListIcon as={CheckCircleIcon} color="green.500" />
                            Queries, Pre-Sales, Support, Post-Sales.{" "}
                          </ListItem>
                          <ListItem>
                            <ListIcon as={CheckCircleIcon} color="green.500" />
                            Voice, Text, Chat, Email
                          </ListItem>
                        </List>
                      </Stack>
                    </Stack>
                    <Stack
                      opacity={0.5}
                      pointerEvents={"none"}
                      pos={"relative"}
                      transition={"all 0.3s"}
                      cursor={"pointer"}
                      style={{
                        background: currentTemplate === 1 ? "#d7d4fd" : "white",
                        color: currentTemplate === 1 ? "black" : "black",
                      }}
                      onClick={() => {
                        setCurrentTemplate(1);
                      }}
                      border={`1px solid ${
                        currentTemplate === 1 ? "#d7d4fd" : "#e4e4e4"
                      }`}
                      bg={"white"}
                      p={4}
                      borderRadius={12}
                    >
                      <Tag
                        size={"sm"}
                        borderColor={"green"}
                        pos={"absolute"}
                        top={2}
                        right={2}
                        w={"fit-content"}
                        color={"green"}
                        variant={"outline"}
                      >
                        Coming soon
                      </Tag>

                      <CheckCircleIcon
                        boxSize={5}
                        display={currentTemplate === 1 ? "block" : "none"}
                        pos={"absolute"}
                        top={4}
                        right={4}
                        color={"var(--chakra-colors-primary-500)"}
                      />
                      <Img w={16} h={16} src={Asset3} />
                      <Stack gap={1}>
                        <Heading size={"md"}>Outreach</Heading>
                        <Text
                          fontSize={"sm"}
                          color={currentTemplate === 2 ? "black" : "gray"}
                        >
                          For sales & outreach
                        </Text>
                        <List
                          flexDirection={"column"}
                          display={"flex"}
                          gap={1}
                          listStyleType={"none"}
                          fontSize={"sm"}
                          fontWeight={500}
                        >
                          <ListItem>
                            <ListIcon as={CheckCircleIcon} color="green.500" />
                            Outbound
                          </ListItem>
                          <ListItem>
                            <ListIcon as={CheckCircleIcon} color="green.500" />
                            Target Prospects
                          </ListItem>
                          <ListItem>
                            <ListIcon as={CheckCircleIcon} color="green.500" />
                            Brand Awareness, Lead Gen, Sales{" "}
                          </ListItem>
                          <ListItem>
                            <ListIcon as={CheckCircleIcon} color="green.500" />
                            Voice, Text, Email
                          </ListItem>
                        </List>
                      </Stack>
                    </Stack>
                    <Stack
                      opacity={0.5}
                      pointerEvents={"none"}
                      pos={"relative"}
                      transition={"all 0.3s"}
                      cursor={"pointer"}
                      style={{
                        background: currentTemplate === 2 ? "#e2e0fb" : "white",
                        color: currentTemplate === 2 ? "black" : "black",
                      }}
                      onClick={() => {
                        setCurrentTemplate(2);
                      }}
                      border={`1px solid ${
                        currentTemplate === 2 ? "#e2e0fb" : "#e4e4e4"
                      }`}
                      bg={"white"}
                      p={4}
                      borderRadius={12}
                    >
                      <Tag
                        size={"sm"}
                        borderColor={"green"}
                        pos={"absolute"}
                        top={2}
                        right={2}
                        w={"fit-content"}
                        color={"green"}
                        variant={"outline"}
                      >
                        Coming soon
                      </Tag>
                      <CheckCircleIcon
                        boxSize={5}
                        display={currentTemplate === 2 ? "block" : "none"}
                        pos={"absolute"}
                        top={4}
                        right={4}
                        color={"var(--chakra-colors-primary-500)"}
                      />
                      <Img w={16} h={16} src={Asset2} />
                      <Stack gap={1}>
                        <Heading size={"md"}>Discovery</Heading>
                        <Text
                          fontSize={"sm"}
                          color={currentTemplate === 2 ? "black" : "gray"}
                        >
                          For customer support
                        </Text>
                        <List
                          flexDirection={"column"}
                          display={"flex"}
                          gap={1}
                          listStyleType={"none"}
                          fontSize={"sm"}
                          fontWeight={500}
                        >
                          <ListItem>
                            <ListIcon as={CheckCircleIcon} color="green.500" />
                            Inbound
                          </ListItem>
                          <ListItem>
                            <ListIcon as={CheckCircleIcon} color="green.500" />
                            Both Customer, Prospect
                          </ListItem>
                          <ListItem>
                            <ListIcon as={CheckCircleIcon} color="green.500" />
                            Queries, Pre-Sales, Support, Post-Sales.{" "}
                          </ListItem>
                          <ListItem>
                            <ListIcon as={CheckCircleIcon} color="green.500" />
                            Web Chat
                          </ListItem>
                        </List>
                      </Stack>
                    </Stack>
                  </Grid>
                  <Flex justifyContent={"space-between"} w={"full"} mt={6}>
                    <Button
                      onClick={() => {
                        saveStage(0);
                        setCurrentTab(0);
                      }}
                      size={"sm"}
                      leftIcon={<ArrowLeftIcon size={16} />}
                    >
                      Prev
                    </Button>
                    <Button
                      onClick={() => {
                        saveStage(2);
                        setCurrentTab(2);
                      }}
                      isDisabled={currentTemplate < 0}
                      size={"sm"}
                      colorScheme={"primary"}
                      rightIcon={<ArrowRightIcon size={16} />}
                    >
                      Next
                    </Button>
                  </Flex>
                </TabPanel>{" "}
                <TabPanel>
                  <Heading size={"lg"}>About agent</Heading>
                  <Text fontWeight={600} fontSize={"sm"} mb={10} mt={1}>
                    Enter about your agent details to get started.
                  </Text>
                  <Formik
                    onSubmit={(values) => {
                      dispatch(
                        createV2Agent(
                          {
                            name: values.name,
                            system_prompt: values.summary,
                            about: values.summary,
                            ai_model_config: {
                              max_tokens: 250,
                              personality: "AUTO"
                            },
                            transcriber_config: {},
                            voice_config: {
                              voice_id: "21m00Tcm4TlvDq8ikWAM",
                              voice_name: "Rachel",
                            },
                            voice_id: "ba7a019e-df90-4229-811b-b72b4e712a45",
                            status: "OFFLINE",
                            use_case: "GENERAL",
                            ai_model_name: "gpt-4o",
                            interaction_mode: "CONVERSATIONAL",
                            advanced_config: {},
                            organization_id: organizationCache?.id || organisation.data.id,
                            recommended_voices: [
                              {
                                  "label": "Saira - Young Casual Voice",
                                  "value": "vghiSqG5ezdhd8F3tKAD",
                                  "id": "c8ec34d4-95ca-476a-a77f-95a65d26f92c",
                                  "voice_id": "vghiSqG5ezdhd8F3tKAD",
                                  "voice_provider": "ELEVENLABS",
                                  "name": "Saira - Young Casual Voice",
                                  "description": null,
                                  "gender": "FEMALE",
                                  "accent": "BRITISH",
                                  "tone": null,
                                  "age_category": "YOUNG",
                                  "category": "PREMADE",
                                  "use_case": "CONVERSATIONAL",
                                  "sample_url": "https://storage.googleapis.com/eleven-public-prod/custom/voices/vghiSqG5ezdhd8F3tKAD/lVQiUua3EmORrlbKpfr0.mp3",
                                  "created_at": "2023-11-28T00:27:31.819752",
                                  "updated_at": "2023-11-28T00:27:31.819756"
                              },
                              {
                                  "label": "Hope - natural conversations",
                                  "value": "OYTbf65OHHFELVut7v2H",
                                  "id": "f907a5dd-4f86-4227-9c40-7f51820ec0fa",
                                  "voice_id": "OYTbf65OHHFELVut7v2H",
                                  "voice_provider": "ELEVENLABS",
                                  "name": "Hope - natural conversations",
                                  "description": "",
                                  "gender": "FEMALE",
                                  "accent": "AMERICAN",
                                  "tone": "CONVERSATIONAL",
                                  "age_category": "YOUNG",
                                  "category": "PREMADE",
                                  "use_case": "CONVERSATIONAL",
                                  "sample_url": "https://storage.googleapis.com/eleven-public-prod/custom/voices/OYTbf65OHHFELVut7v2H/ZN73m0kIWmrTlinxqSiN.mp3",
                                  "created_at": "2024-08-02T00:45:41.397547",
                                  "updated_at": "2024-08-02T00:45:41.397547"
                              },
                              {
                                  "label": "Barbara - Eloquent, intelligent female\r\n",
                                  "value": "kwZZS3WAVfk7EncqOz2K",
                                  "id": "5c108a13-b5b4-48cf-a5a1-c40bd25bf82a",
                                  "voice_id": "kwZZS3WAVfk7EncqOz2K",
                                  "voice_provider": "ELEVENLABS",
                                  "name": "Barbara - Eloquent, intelligent female\r\n",
                                  "description": "A young female British speaker, eloquent and intelligent, who tends to speak somewhat rapidly.Great for podcast.",
                                  "gender": "FEMALE",
                                  "accent": "BRITISH",
                                  "tone": "CONVERSATIONAL",
                                  "age_category": "YOUNG",
                                  "category": "PREMADE",
                                  "use_case": "CONVERSATIONAL",
                                  "sample_url": "https://storage.googleapis.com/eleven-public-prod/udmG0I9oKegHHyrU3sEvatdvG2p1/voices/xt3HPA16vjtEep1aKvP2/4fb33ada-8003-4e0e-b367-5de11dae9bf9.mp3",
                                  "created_at": "2024-08-02T00:45:43.974683",
                                  "updated_at": "2024-08-02T00:45:43.974683"
                              },
                              {
                                  "label": "Archer",
                                  "value": "L0Dsvb3SLTyegXwtm47J",
                                  "id": "01763abb-6dce-4f35-afd0-d390a0a18f93",
                                  "voice_id": "L0Dsvb3SLTyegXwtm47J",
                                  "voice_provider": "ELEVENLABS",
                                  "name": "Archer",
                                  "description": "Conversational, calm British, thirty-something, perfect for an AI agent.",
                                  "gender": "MALE",
                                  "accent": "BRITISH",
                                  "tone": "CONVERSATIONAL",
                                  "age_category": "MIDDLE_AGED",
                                  "category": "PREMADE",
                                  "use_case": "CONVERSATIONAL",
                                  "sample_url": "https://storage.googleapis.com/eleven-public-prod/custom/voices/L0Dsvb3SLTyegXwtm47J/EKoXN08bacukhTjtp6sq.mp3",
                                  "created_at": "2024-08-02T00:45:43.654923",
                                  "updated_at": "2024-08-02T00:45:43.654923"
                              },
                              {
                                  "label": "Miriam - Casual and Wry",
                                  "value": "Ah0Fg1nzczXgBpMPTtQb",
                                  "id": "31014311-bc44-410b-bd1d-d9a20a1748dd",
                                  "voice_id": "Ah0Fg1nzczXgBpMPTtQb",
                                  "voice_provider": "ELEVENLABS",
                                  "name": "Miriam - Casual and Wry",
                                  "description": "Middle aged female with an Australian accent. Voice is low, smooth and casual. Great for conversations.",
                                  "gender": "FEMALE",
                                  "accent": "AUSTRALIAN",
                                  "tone": "CONVERSATIONAL",
                                  "age_category": "MIDDLE_AGED",
                                  "category": "PREMADE",
                                  "use_case": "CONVERSATIONAL",
                                  "sample_url": "https://storage.googleapis.com/eleven-public-prod/0uIqkkS46jQxfFloXWQg5TWCzKm1/voices/JKpK5B1pXInRQlSuN4pQ/6bc93aa0-5c0b-408c-8b5f-1b6d7bdb8f2d.mp3",
                                  "created_at": "2024-08-02T00:45:47.892662",
                                  "updated_at": "2024-08-02T00:45:47.892662"
                              },
                              {
                                  "label": "Sam - Aussie",
                                  "value": "3DkcznWTIDSnX3f0J6DG",
                                  "id": "7c1c5caf-5bd9-4f4a-a3c6-e35ad3defb3c",
                                  "voice_id": "3DkcznWTIDSnX3f0J6DG",
                                  "voice_provider": "ELEVENLABS",
                                  "name": "Sam - Aussie",
                                  "description": "A young adult male voice hailing from down under, showcasing relatable, friendly Aussie accent.  The voice of the everyman! (well, one of them!)  A good fit for a project where a conversational, casual style is needed. Would be great for YouTube or social media.",
                                  "gender": "MALE",
                                  "accent": "AUSTRALIAN",
                                  "tone": "CONVERSATIONAL",
                                  "age_category": "YOUNG",
                                  "category": "PREMADE",
                                  "use_case": "CONVERSATIONAL",
                                  "sample_url": "https://storage.googleapis.com/eleven-public-prod/custom/voices/3DkcznWTIDSnX3f0J6DG/fZmMOcPbqy0B6gBZ2sLW.mp3",
                                  "created_at": "2024-08-02T00:45:48.973501",
                                  "updated_at": "2024-08-02T00:45:48.973501"
                              },
                              {
                                  "label": "Trixie - Young Gossip Girl",
                                  "value": "c7XGL37TTXR5zdorzHX9",
                                  "id": "429993a9-2010-460f-bb98-ea5ab307c22d",
                                  "voice_id": "c7XGL37TTXR5zdorzHX9",
                                  "voice_provider": "ELEVENLABS",
                                  "name": "Trixie - Young Gossip Girl",
                                  "description": "A youthful, energetic, gossip girl voice. Perfect for storytelling, conversations, podcasts, entertainment, and pop culture. ",
                                  "gender": "FEMALE",
                                  "accent": "AMERICAN",
                                  "tone": "CONVERSATIONAL",
                                  "age_category": "YOUNG",
                                  "category": "PREMADE",
                                  "use_case": "CONVERSATIONAL",
                                  "sample_url": "https://storage.googleapis.com/eleven-public-prod/custom/voices/c7XGL37TTXR5zdorzHX9/wDROlBcDXPW683m7wN1v.mp3",
                                  "created_at": "2024-08-02T00:45:50.696860",
                                  "updated_at": "2024-08-02T00:45:50.696860"
                              },
                              {
                                  "label": "Christopher - friendly guy next door",
                                  "value": "RPEIZnKMqlQiZyZd1Dae",
                                  "id": "5435c18c-80c3-4d80-b92e-47b0ac4591eb",
                                  "voice_id": "RPEIZnKMqlQiZyZd1Dae",
                                  "voice_provider": "ELEVENLABS",
                                  "name": "Christopher - friendly guy next door",
                                  "description": "A middle age American male with a slight raspiness in his voice and a mix of an East Coast and Midwestern accent. Great for conversations & Explainer videos.",
                                  "gender": "MALE",
                                  "accent": "AMERICAN",
                                  "tone": "CONVERSATIONAL",
                                  "age_category": "MIDDLE_AGED",
                                  "category": "PREMADE",
                                  "use_case": "CONVERSATIONAL",
                                  "sample_url": "https://storage.googleapis.com/eleven-public-prod/custom/voices/RPEIZnKMqlQiZyZd1Dae/P8rxSTTRjB7TxLxZ89Cd.mp3",
                                  "created_at": "2024-08-02T00:45:44.647374",
                                  "updated_at": "2024-08-02T00:45:44.647374"
                              },
                              {
                                  "label": "Raju - Relatable Hindi Voice",
                                  "value": "zT03pEAEi0VHKciJODfn",
                                  "id": "b4e2c67d-7ade-40bc-9b62-ef61edc74a39",
                                  "voice_id": "zT03pEAEi0VHKciJODfn",
                                  "voice_provider": "ELEVENLABS",
                                  "name": "Raju - Relatable Hindi Voice",
                                  "description": "This voice has the familiar warmth and approachability of a regular Indian speaker, yet it carries a professionalism that makes it versatile for a range of applications. It's clear and articulate enough to engage audiences on social media, provide guidance through customer care bots, and serve in various other communication roles. Its authentic Indian tone adds a layer of relatability without compromising on clarity or effectiveness, making it an excellent choice for connecting with audiences.",
                                  "gender": "MALE",
                                  "accent": "INDIAN",
                                  "tone": "CONVERSATIONAL",
                                  "age_category": "MIDDLE_AGED",
                                  "category": "PREMADE",
                                  "use_case": "CONVERSATIONAL",
                                  "sample_url": "https://storage.googleapis.com/eleven-public-prod/custom/voices/zT03pEAEi0VHKciJODfn/E1SwTL1qIKPkIxyZQQfk.mp3",
                                  "created_at": "2024-08-02T00:45:48.628098",
                                  "updated_at": "2024-08-02T00:45:48.628098"
                              },
                              {
                                  "label": "Muskaan - Casual Hindi Voice",
                                  "value": "xoV6iGVuOGYHLWjXhVC7",
                                  "id": "5f82ad42-7f0e-4e0e-ad4a-7795598bf25d",
                                  "voice_id": "xoV6iGVuOGYHLWjXhVC7",
                                  "voice_provider": "ELEVENLABS",
                                  "name": "Muskaan - Casual Hindi Voice",
                                  "description": "Muskaan is the pen name of a very experienced financial trainer in India. Her voice is very natural and feels relatable, unlike the corporate tone that often feels disconnected. This voice will be very good for use cases like social media, conversational contexts, and e-learning content.",
                                  "gender": "FEMALE",
                                  "accent": "INDIAN",
                                  "tone": "CONVERSATIONAL",
                                  "age_category": "MIDDLE_AGED",
                                  "category": "PREMADE",
                                  "use_case": "CONVERSATIONAL",
                                  "sample_url": "https://storage.googleapis.com/eleven-public-prod/custom/voices/xoV6iGVuOGYHLWjXhVC7/HLJ6hooUJSQwgFVGM32b.mp3",
                                  "created_at": "2024-08-02T00:45:31.477778",
                                  "updated_at": "2024-08-02T00:45:31.477778"
                              }
                          ]
                          },
                          (id: string) => {
                            navigate(
                              `/app/${organizationCache?.id || organisation.data.id}/assistants/${id}`
                            );
                            saveOrganizationCache({})
                            saveAssistantCache({})
                            saveStage(0)
                          }
                        )
                      );
                    }}
                    initialValues={{
                      name: "",
                      summary: "",
                    }}
                  >
                    {({ errors, touched, values }) => (
                      <Form
                        style={{
                          width: "100%",
                        }}
                        onChange={() => {}}
                      >
                        <Stack gap={4} mt={4}>
                          <FormControl
                            isInvalid={!!errors.name && touched.name}
                          >
                            <FormLabel fontWeight={500} fontSize={"sm"}>
                              Agent Name{" "}
                              <Tooltip hasArrow label="info icon">
                                <InfoOutlineIcon cursor={"pointer"} />
                              </Tooltip>
                            </FormLabel>
                            <Field
                              name="name"
                              fontWeight={500}
                              value={values.name}
                              as={Input}
                              validate={(v: string) => {
                                let error;
                                if (!v) {
                                  error = "Agent name is required";
                                }
                                return error;
                              }}
                              fontSize={"sm"}
                              placeholder="Dannie"
                            />
                            <FormErrorMessage fontWeight={500}>{errors.name}</FormErrorMessage>
                          </FormControl>
                          <FormControl
                            isInvalid={!!errors.summary && touched.summary}
                          >
                            <FormLabel fontWeight={500} fontSize={"sm"}>
                              What do you need your agent to do ?{" "}
                              <Tooltip hasArrow label="info icon">
                                <InfoOutlineIcon cursor={"pointer"} />
                              </Tooltip>
                            </FormLabel>
                            <Field
                              fontWeight={500}
                              name="summary"
                              value={values.summary}
                              rows={8}
                              as={Textarea}
                              validate={(v: string) => {
                                let error;
                                if (!v) {
                                  error = "Description is required";
                                }
                                return error;
                              }}
                              fontSize={"sm"}
                              placeholder="Tell us in detail about what do you want to your agent to be like"
                            />
                            <FormErrorMessage fontWeight={500}>
                              {errors.summary}
                            </FormErrorMessage>
                          </FormControl>
                          <Flex
                            justifyContent={"space-between"}
                            w={"full"}
                            mt={6}
                          >
                            <Button
                              isLoading={agent.state === "loading"}
                              onClick={() => {
                                saveStage(1);
                                setCurrentTab(1);
                              }}
                              size={"sm"}
                              leftIcon={<ArrowLeftIcon size={16} />}
                            >
                              Prev
                            </Button>
                            <Button
                              isLoading={agent.state === "loading"}
                              type="submit"
                              rounded={"full"}
                              isDisabled={currentTemplate < 0}
                              colorScheme={"primary"}
                            >
                              Finish Setup
                            </Button>
                          </Flex>
                        </Stack>
                      </Form>
                    )}
                  </Formik>
                </TabPanel>
              </TabPanels>
            </Tabs>
          ) : (
            <>
              <Stack mt={-16} gap={16} alignItems={"center"}>
                <Box height={100}>
                  <Timer
                    timerData={initialTabs[0]}
                    statusHandler={statusHandler}
                    countingStatus={countingStatus}
                    setCountingStatus={setCountingStatus}
                  />
                </Box>
                <Box>
                  <Lottie
                    style={{ height: "84px" }}
                    animationData={LoadingAnimation}
                    loop={true}
                  />
                  <Text mt={2} fontSize={"lg"}>
                    Please wait while we are setting up your assistant
                  </Text>
                </Box>
              </Stack>
            </>
          )}
        </Stack>
      </Center>
    </Stack>
  );
}
