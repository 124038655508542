import { RESET_STATE, SET_SIGNUP_ERROR, SET_SIGNUP_LOADING, SET_SIGNUP_SUCCESS } from "./constants";

export function setSignupLoading () {
    return {
        type: SET_SIGNUP_LOADING
    }
}

export function setSignupSuccess (payload: any) {
    return {
        type: SET_SIGNUP_SUCCESS,
        payload
    }
}

export function setSignupError (error: string, details?: any) {
    return {
        type: SET_SIGNUP_ERROR,
        error,
        details
    }
}

export function resetState () {
    return {
        type: RESET_STATE
    }
}