import {
  Box,
  Button,
  Divider,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Skeleton,
  Stack,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../lib/hooks";
import _ from "lodash";
import moment from "moment-timezone";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { Badge } from "~/components/ui/badge";
import { getFeedbackByAssistant } from "~/lib/app/feedback/thunk";

interface AssitantLogProps {
  id: string;
  onSetFeedback?: (fn: any) => void
}

export default function Feedback(props: AssitantLogProps) {
  const dispatch = useAppDispatch();
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(15);
  const toast = useToast();
  const organization = useAppSelector((state) => state.app.organizations.get);
  const feedbacks = useAppSelector((state) => state.app.feedback.list);
  const create = useAppSelector((state) => state.app.feedback.create);
  const update = useAppSelector((state) => state.app.feedback.update);
  const deleteObj = useAppSelector((state) => state.app.feedback.delete);
  const [selectedDates, setSelectedDates] = useState([
    moment().startOf("month").toDate(),
    moment().endOf("year").toDate(),
  ]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isChatOpen, onOpen: onChatOpen, onClose: onChatClose } = useDisclosure();
  const { isOpen: isDeleteOpen, onOpen: onDeleteOpen, onClose: onDeleteClose } = useDisclosure();
  // const [currentMessage, setCurrentMessage] = useState<{feedback?: string, id?: string}>({})
  const [messages, setMessages] = useState<{
    role?: string,
    content?: string,
    type?: string
  }[]>([])
  const startsWithValidJSON = useRef<boolean>(false)
  const scrollContainer = useRef<HTMLDivElement | null>(null);
  const session = useRef<string | boolean | null>(null);
  const [text, setText] = useState<string>('');
  const scrollRef = useRef<HTMLDivElement>(null);
  const socket = useRef<WebSocket | null>(null);
  const [message, setCurrentMessage] = useState<string>('');
  const messageRef = useRef<string>('');
  const jsonRef = useRef<{
    output?: string,
    status?: string,
    feedback_summary?: string
  }>({});
  const agent = "Neo";
  const [history, setHistory] = useState<
    { role: string; content?: string; metadata?: any; image?: string }[]
  >([
    {
      role: "assistant",
      "content": "Hi there"
    },
  ]);
  const navigate = useNavigate();
  const [displayHeader, setDisplayHeader] = useState(false);
  const [cookies] = useCookies(["accessToken"]);
  const chunk = useRef<any[]>([]);
  const stream = useRef<string[]>([]);
  const stack = useRef<string[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const endCursor = useRef<boolean>(false);
  const [isReadOnly, setReadOnly] = useState(false);


  // useEffect(() => {
  //   if (socket.current) {
  //     socket.current.close();
  //     socket.current = null;
  //   }
  // }, [ initiateConnection ]);


  useEffect(() => {
    dispatch(getFeedbackByAssistant(props.id));
  }, []);

  // useEffect(() => {
  //   props.onSetFeedback?.(() => {
  //     initiateConnection()
  //     onChatOpen()
  //   })
  // }, [props.onSetFeedback])


  useEffect(() => {
    if (isChatOpen) {
      // if (!currentMessage?.id && create.state === "success") {
      //   setCurrentMessage(create.data)
      //   setMessages([
      //     ...messages,
      //     {
      //       "role": "ai",
      //       "content": "Got it! I have recieved your feedback. Your agent will be updated in 48 hrs"
      //     }
      //   ])
      // } else if (currentMessage.id && update.state === "success") {
      //   setCurrentMessage(update.data)
      //   setMessages([
      //     ...messages,
      //     {
      //       "role": "ai",
      //       "content": "Got it! I have recieved your feedback. Your agent will be updated in 48 hrs"
      //     }
      //   ])
      // }
    }
  }, [create.state, update.state])

  return (
    <Stack>
      <Modal isCentered onClose={() => {

      }} isOpen={isDeleteOpen}>
          <ModalOverlay backgroundColor={"transparent"} backdropFilter={'blur(5px)'} />
        <ModalContent>
          <ModalHeader>Delete feedback</ModalHeader>
          <ModalBody fontWeight={500} fontSize={"sm"}>
          Are you sure you want to delete your feedback submitted ?
          </ModalBody>
          <ModalFooter gap={2}>
            <Button variant={"outline"} size={"sm"} onClick={() => {
              onDeleteClose()
            }}>Cancel</Button>
            <Button isLoading={deleteObj.state === "loading"} colorScheme="red" size={"sm"} onClick={() => {
              // dispatch(deleteFeedback(organization.data.id, currentMessage?.id!, () => {
              //   onDeleteClose()
              //   setCurrentMessage({})
              //   dispatch(getFeedbackByAssistant(props.id));
              // }))
            }}>Yes, Delete</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Stack>
          <Flex justifyContent={"space-between"} alignItems={"center"}>
            <Text fontSize={"lg"} fontWeight={600}>Your feedbacks</Text>
          </Flex>
          <Divider my={2} />
      {feedbacks.state === "loading" ? <Stack gap={4}>
          {[...Array(6)].map((e) => (
            <Skeleton h={16} borderRadius={10} />
          ))}
        </Stack> : <></>}
        {feedbacks.data?.length ? (
            feedbacks.data.map((d: any) => (
              <Stack
              gap={1}
              pb={2}
                className="border-b last:border-b-0"
                onClick={() => {
                  setLoading(false)
                  setReadOnly(true)
                  setMessages((d.messages?.messages || []).map((e: any) => {
                    try {
                      const content = JSON.parse(e.content)
                      if (content.action_input) {
                        return {
                          content: content.action_input?.output || content.action_input,
                          role: "ai"
                        }
                      }
                    } catch (err) {
                      return e
                    }
                    return e
                  }).filter((e: any) => e.content))
                  onChatOpen()
                }}
                cursor={"pointer"}
                _hover={{
                  backgroundColor: "#fcfbff",
                }}
              >
                <Flex justifyContent={"space-between"}>
                <Text fontWeight={600}>
                    {d.feedback}
                    </Text>
                  <Badge className="h-fit" variant={"primary-subtle"}>
                    {_.capitalize(d.status)}
                  </Badge>
                </Flex>
                <Flex justifyContent={"space-between"} alignItems={"center"}>
                <Box>
                    <Text mt={1} fontSize={"sm"} fontWeight={500}>
                      {d.created_by?.name}
                    </Text>
                  </Box>
                  <Text fontSize={"sm"}>
                    {moment(d.created_at).format("DD MMM, YYYY, hh:mm A")}
                  </Text>
                </Flex>
              </Stack>
            ))
        ) : (
          <></>
        )}
      </Stack>
      {feedbacks.data?.length === 0 && feedbacks.state === "success" ? (
        <Text px={2}>No logs found</Text>
      ) : (
        <></>
      )}
      {/* <Flex ml={2} justifyContent={"space-between"}>
      <Flex align={"center"} gap={2}>
        <Text whiteSpace={"nowrap"} fontSize={"small"}>
          
          Showing{" "}
          {metadata?.total_count < page * size
            ? metadata?.total_count
            : page * size}{" "}
          of {metadata?.total_count}
        </Text>
        <Select
          onChange={(v) => {
            setSize(Number.parseInt(v.target.value, 10));
          }}
          defaultValue={size}
          size="sm"
          borderRadius={8}
        >
          <option value={15} selected>
            15
          </option>
          <option value={30}>25</option>
          <option value={50}>50</option>
          <option value={100}>100</option>
        </Select>
      </Flex>
      <Flex gap={2} align={"center"}>
        <IconButton
          isDisabled={page === 1}
          onClick={() => {
            setPage(1);
          }}
          rounded={"full"}
          variant={"outline"}
          icon={<ChevronsLeftIcon size={16} />}
          aria-label=""
        />
        <IconButton
          isDisabled={page === 1}
          onClick={() => {
            setPage(page - 1);
          }}
          rounded={"full"}
          variant={"outline"}
          icon={<ChevronLeftIcon size={16} />}
          aria-label=""
        />
        <IconButton
          // @ts-ignore
          isDisabled={metadata?.total_count <= page * size}
          onClick={() => {
            setPage(page + 1);
          }}
          rounded={"full"}
          variant={"outline"}
          icon={<ChevronRightIcon size={16} />}
          aria-label=""
        />
        <IconButton
          // @ts-ignore
          isDisabled={metadata?.total_count <= page * size}
          onClick={() => {
            // @ts-ignore
            setPage(Math.ceil(metadata?.total_count / size));
          }}
          rounded={"full"}
          variant={"outline"}
          icon={<ChevronsRightIcon size={16} />}
          aria-label=""
        />
      </Flex>
    </Flex> */}
    </Stack>
  );
}
