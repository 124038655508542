import { RESET_STATE, SET_LOGIN_ERROR, SET_LOGIN_LOADING, SET_LOGIN_SUCCESS } from "./constants";

export function setLoginLoading () {
    return {
        type: SET_LOGIN_LOADING
    }
}

export function setLoginSuccess (payload: any) {
    return {
        type: SET_LOGIN_SUCCESS,
        payload
    }
}

export function setLoginError (error: string, details?: any) {
    return {
        type: SET_LOGIN_ERROR,
        error,
        details
    }
}

export function resetState () {
    return {
        type: RESET_STATE
    }
}