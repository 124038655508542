import { BaseObjectState, BaseArrayState } from "../../types";
import { 
    SET_ORGANIZATION_CREATE_LOADING,
    SET_ORGANIZATION_CREATE_SUCCESS,
    SET_ORGANIZATION_CREATE_ERROR,
    SET_ORGANIZATION_LIST_LOADING,
    SET_ORGANIZATION_LIST_SUCCESS,
    SET_ORGANIZATION_LIST_ERROR,
    SET_ORGANIZATION_UPDATE_LOADING,
    SET_ORGANIZATION_UPDATE_SUCCESS,
    SET_ORGANIZATION_UPDATE_ERROR,
    SET_ORGANIZATION_GET_LOADING,
    SET_ORGANIZATION_GET_SUCCESS,
    SET_ORGANIZATION_GET_ERROR
} from "./constants";

interface PlatformState {
    create: BaseObjectState,
    update: BaseObjectState,
    list: BaseArrayState,
    get: BaseObjectState
}

const INITIAL_STATE: PlatformState = {
    create: {
        state: "loading",
        data: {},
        error: {}
    },
    update: {
        state: "pending",
        data: {},
        error: {}
    },
    list: {
        state: "loading",
        data: [],
        error: {}
    },
    get: {
        state: "loading",
        data: {},
        error: {}
    }
}

export const OrganizationReducer = (state = INITIAL_STATE, action: any) => {
    switch (action.type) {
        case SET_ORGANIZATION_CREATE_LOADING:
            return {
                ...state,
                create: {
                    state: "loading",
                    data: {},
                    error: ''
                }
            }
        case SET_ORGANIZATION_CREATE_SUCCESS:
            return {
                ...state,
                create: {
                    state: "success",
                    data: action.payload,
                    error: ''
                }
            }
        case SET_ORGANIZATION_CREATE_ERROR:
            return {
                ...state,
                create: {
                    state: "error",
                    data: {},
                    error: action.error || 'Something went wrong'
                }
            }
        case SET_ORGANIZATION_LIST_LOADING:
            return {
                ...state,
                list: {
                    state: "loading",
                    data: [],
                    error: ''
                }
            }
        case SET_ORGANIZATION_LIST_SUCCESS:
            return {
                ...state,
                list: {
                    state: "success",
                    data: action.payload,
                    error: ''
                }
            }
        case SET_ORGANIZATION_LIST_ERROR:
            return {
                ...state,
                list: {
                    state: "error",
                    data: [],
                    error: action.error || 'Something went wrong'
                }
            }
        case SET_ORGANIZATION_UPDATE_LOADING:
            return {
                ...state,
                update: {
                    state: "loading",
                    data: {},
                    error: ''
                }
            }
        case SET_ORGANIZATION_UPDATE_SUCCESS:
            return {
                ...state,
                update: {
                    state: "success",
                    data: action.payload,
                    error: ''
                }
            }
        case SET_ORGANIZATION_UPDATE_ERROR:
            return {
                ...state,
                update: {
                    state: "error",
                    data: {},
                    error: action.error || 'Something went wrong'
                }
            }
        case SET_ORGANIZATION_GET_LOADING:
            return {
                ...state,
                get: {
                    state: "loading",
                    data: {},
                    error: ''
                }
            }
        case SET_ORGANIZATION_GET_SUCCESS:
            return {
                ...state,
                get: {
                    state: "success",
                    data: action.payload,
                    error: ''
                }
            }
        case SET_ORGANIZATION_GET_ERROR:
            return {
                ...state,
                get: {
                    state: "error",
                    data: {},
                    error: action.error || 'Something went wrong'
                }
            }
        default:
            return state;
    }
}
