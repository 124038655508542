import { BaseObjectState, BaseArrayState } from "../../types";
import { 
    SET_CREATE_FILE_LOADING,
    SET_CREATE_FILE_SUCCESS,
    SET_CREATE_FILE_ERROR,
    SET_LIST_FILES_LOADING,
    SET_LIST_FILES_SUCCESS,
    SET_LIST_FILES_ERROR,
    SET_UPDATE_FILE_LOADING,
    SET_UPDATE_FILE_SUCCESS,
    SET_UPDATE_FILE_ERROR,
    SET_GET_FILE_LOADING,
    SET_GET_FILE_SUCCESS,
    SET_GET_FILE_ERROR
} from "./constants";

interface FilesState {
    create: BaseObjectState,
    list: BaseArrayState,
    update: BaseObjectState,
    get: BaseObjectState
}

const INITIAL_STATE: FilesState = {
    create: {
        state: "pending",
        data: {},
        error: {}
    },
    list: {
        state: "loading",
        data: [],
        error: {}
    },
    update: {
        state: "pending",
        data: {},
        error: {}
    },
    get: {
        state: "pending",
        data: {},
        error: {}
    }
}

export const FilesReducer = (state = INITIAL_STATE, action: any) => {
    switch (action.type) {
        case SET_CREATE_FILE_LOADING:
            return {
                ...state,
                create: {
                    state: "loading",
                    data: {},
                    error: ''
                }
            }
        case SET_CREATE_FILE_SUCCESS:
            return {
                ...state,
                create: {
                    state: "success",
                    data: action.payload,
                    error: ''
                }
            }
        case SET_CREATE_FILE_ERROR:
            return {
                ...state,
                create: {
                    state: "error",
                    data: {},
                    error: action.error || 'Something went wrong'
                }
            }
        case SET_LIST_FILES_LOADING:
            return {
                ...state,
                list: {
                    state: "loading",
                    data: [],
                    error: ''
                }
            }
        case SET_LIST_FILES_SUCCESS:
            return {
                ...state,
                list: {
                    state: "success",
                    data: action.payload,
                    error: ''
                }
            }
        case SET_LIST_FILES_ERROR:
            return {
                ...state,
                list: {
                    state: "error",
                    data: [],
                    error: action.error || 'Something went wrong'
                }
            }
        case SET_UPDATE_FILE_LOADING:
            return {
                ...state,
                update: {
                    state: "loading",
                    data: {},
                    error: ''
                }
            }
        case SET_UPDATE_FILE_SUCCESS:
            return {
                ...state,
                update: {
                    state: "success",
                    data: action.payload,
                    error: ''
                }
            }
        case SET_UPDATE_FILE_ERROR:
            return {
                ...state,
                update: {
                    state: "error",
                    data: {},
                    error: action.error || 'Something went wrong'
                }
            }
        case SET_GET_FILE_LOADING:
            return {
                ...state,
                get: {
                    state: "loading",
                    data: {},
                    error: ''
                }
            }
        case SET_GET_FILE_SUCCESS:
            return {
                ...state,
                get: {
                    state: "success",
                    data: action.payload,
                    error: ''
                }
            }
        case SET_GET_FILE_ERROR:
            return {
                ...state,
                get: {
                    state: "error",
                    data: {},
                    error: action.error || 'Something went wrong'
                }
            }
        default:
            return state;
    }
}
