'use client'

import * as React from 'react'
import * as CheckboxPrimitive from '@radix-ui/react-checkbox'
import { CheckIcon } from 'lucide-react'

import { ny } from '~/lib/utils'


interface CheckboxProps extends React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root> {
   iconSize?: number;
   readonly?: boolean
 }

const Checkbox = React.forwardRef<
   React.ElementRef<typeof CheckboxPrimitive.Root>,
   CheckboxProps
>(({ className, iconSize, ...props }, ref) => (
   <CheckboxPrimitive.Root
      ref={ref}
      className={ny(
         'border-primary ring-offset-background focus-visible:ring-ring data-[state=checked]:bg-primary data-[state=checked]:text-primary-foreground peer size-4 shrink-0 rounded-sm border focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50',
         className
      )}
      {...props}>
      <CheckboxPrimitive.Indicator className={ny('flex items-center justify-center text-current')}>
         <CheckIcon size={iconSize} className={"size-2"} />
      </CheckboxPrimitive.Indicator>
   </CheckboxPrimitive.Root>
))
Checkbox.displayName = CheckboxPrimitive.Root.displayName

export { Checkbox }
