export const SET_FEEDBACK_CREATE_LOADING = "feedback/create/loading";
export const SET_FEEDBACK_CREATE_SUCCESS = "feedback/create/success";
export const SET_FEEDBACK_CREATE_ERROR = "feedback/create/error";

export const SET_FEEDBACK_UPDATE_LOADING = "feedback/update/loading";
export const SET_FEEDBACK_UPDATE_SUCCESS = "feedback/update/success";
export const SET_FEEDBACK_UPDATE_ERROR = "feedback/update/error";

export const SET_FEEDBACK_DELETE_LOADING = "feedback/delete/loading";
export const SET_FEEDBACK_DELETE_SUCCESS = "feedback/delete/success";
export const SET_FEEDBACK_DELETE_ERROR = "feedback/delete/error";

export const SET_FEEDBACK_LIST_LOADING = "feedback/list/loading";
export const SET_FEEDBACK_LIST_SUCCESS = "feedback/list/success";
export const SET_FEEDBACK_LIST_ERROR = "feedback/list/error";

export const SET_FEEDBACK_GET_LOADING = "feedback/get/loading";
export const SET_FEEDBACK_GET_SUCCESS = "feedback/get/success";
export const SET_FEEDBACK_GET_ERROR = "feedback/get/error";
