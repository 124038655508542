export const SET_CREATE_TEAMS_LOADING = "teams/create/loading";
export const SET_CREATE_TEAMS_SUCCESS = "teams/create/success";
export const SET_CREATE_TEAMS_ERROR = "teams/create/error";
export const SET_UPDATE_TEAMS_LOADING = "teams/update/loading";
export const SET_UPDATE_TEAMS_SUCCESS = "teams/update/success";
export const SET_UPDATE_TEAMS_ERROR = "teams/update/error";
export const SET_DELETE_TEAMS_LOADING = "teams/delete/loading";
export const SET_DELETE_TEAMS_SUCCESS = "teams/delete/success";
export const SET_DELETE_TEAMS_ERROR = "teams/delete/error";
export const SET_GET_TEAMS_LOADING = "teams/get/loading";
export const SET_GET_TEAMS_SUCCESS = "teams/get/success";
export const SET_GET_TEAMS_ERROR = "teams/get/error";
export const SET_LIST_TEAMS_LOADING = "teams/list/loading";
export const SET_LIST_TEAMS_SUCCESS = "teams/list/success";
export const SET_LIST_TEAMS_ERROR = "teams/list/error";
