export const SET_TEAM_CREATE_LOADING = "team/create/loading";
export const SET_TEAM_CREATE_SUCCESS = "team/create/success";
export const SET_TEAM_CREATE_ERROR = "team/create/error";

export const SET_TEAM_LIST_LOADING = "team/list/loading";
export const SET_TEAM_LIST_SUCCESS = "team/list/success";
export const SET_TEAM_LIST_ERROR = "team/list/error";

export const SET_TEAM_UPDATE_LOADING = "team/update/loading";
export const SET_TEAM_UPDATE_SUCCESS = "team/update/success";
export const SET_TEAM_UPDATE_ERROR = "team/update/error";

export const SET_TEAM_DELETE_LOADING = "team/delete/loading";
export const SET_TEAM_DELETE_SUCCESS = "team/delete/success";
export const SET_TEAM_DELETE_ERROR = "team/delete/error";

export const SET_TEAM_GET_LOADING = "team/get/loading";
export const SET_TEAM_GET_SUCCESS = "team/get/success";
export const SET_TEAM_GET_ERROR = "team/get/error";
