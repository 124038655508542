import { RESET_STATE, SET_LOGIN_ERROR, SET_LOGIN_LOADING, SET_LOGIN_SUCCESS } from "./constants"

const INITIAL_STATE = {
    state: "pending",
    data: {},
    error: ''
}

export const LoginReducer = (state = INITIAL_STATE, action: any) => {
    switch (action.type) {
        case SET_LOGIN_LOADING:
            return {
                ...state,
                state: "loading",
                data: {},
                error: ''
            }
        case SET_LOGIN_SUCCESS:
            return {
                ...state,
                state: "success",
                data: action.payload,
                error: ''
            }
        case SET_LOGIN_ERROR:
            return {
                ...state,
                state: "error",
                data: {},
                error: action.error
            }
        case RESET_STATE:
            return {
                ...state,
                state: "pending",
                data: {},
                error: ''
            }
        default:
            return state
    }
}