import {
  Box,
  Center,
  CircularProgress,
  CircularProgressLabel,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerOverlay,
  Flex,
  IconButton,
  Skeleton,
  Spinner,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tag,
  TagLabel,
  TagLeftIcon,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import Page from "../../@components/Page";
import { RefObject, useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../lib/hooks";
import {
  Building2Icon,
  CheckIcon,
  ChevronsUpDownIcon,
  CloudDownloadIcon,
  DownloadIcon,
  GlobeIcon,
  LineChartIcon,
  MoreVerticalIcon,
  PauseIcon,
  PhoneIcon,
  PhoneOutgoingIcon,
  PlayIcon,
  RotateCcwIcon,
  SubtitlesIcon,
  ZapIcon,
} from "lucide-react";
import CircleIcon from "../../@components/CircleIcon";
import moment from "moment-timezone";
import JsonView from "@uiw/react-json-view";
import { githubDarkTheme } from '@uiw/react-json-view/githubDark';
import { getAllLogs, getSpecificLog } from "../../lib/app/logs/thunk";
import WavesurferPlayer from "@wavesurfer/react";
// @ts-ignore
import Timeline from 'wavesurfer.js/dist/plugins/timeline.esm.js'
// @ts-ignore
import Regions from 'wavesurfer.js/dist/plugins/regions.esm.js'
import WaveSurfer from "wavesurfer.js";
import { useParams } from "react-router-dom";
import { generateCanonicalNames, generateCanonicalVariants } from "../../utils/helper.utils";
import _ from "lodash";
import { Select, chakraComponents } from "chakra-react-select";
import { loadAgents } from "../../lib/app/platform/thunk";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "~/components/ui/table";
import { Badge } from "~/components/ui/badge";

const dropdownStyles: any = {
  dropdownIndicator: (b: any) => ({
    ...b,
    backgroundColor: "transparent",
    borderColor: "transparent",
    border: 0,
  }),
  control: (b: any) => ({
    ...b,
    borderRadius: 8,
  }),
  menuList: (b: any) => ({
    ...b,
    borderRadius: 8,
  }),
};

const dropdownComponents = {
  DropdownIndicator: (props: any) => (
    <chakraComponents.DropdownIndicator {...props}>
      <ChevronsUpDownIcon size={12} />
    </chakraComponents.DropdownIndicator>
  ),
};

function TableAudio({
  url,
  onInteraction,
}: {
  url: string;
  onInteraction: (
    state: "playing" | "paused",
    ref?: RefObject<HTMLAudioElement> | null
  ) => void;
}) {
  const [duration, setDuration] = useState(0);
  const [track, setTrack] = useState(0);
  const [isPaused, setPaused] = useState(true);
  const ref = useRef<HTMLAudioElement>(null);

  return (
    <Flex alignItems={"center"} gap={3}>
      <audio
        onEnded={() => {
          onInteraction("paused", ref);
          setTrack(0);
          setPaused(true);
        }}
        onTimeUpdate={(e) => {
          setTrack(Math.ceil(e.currentTarget.currentTime));
        }}
        hidden
        ref={ref}
        src={url}
        onLoadedMetadata={(e) => {
          setDuration(Math.ceil(ref.current?.duration || 0));
        }}
      />

      <CircularProgress
        max={duration}
        value={track}
        color="hsl(var(--primary))"
        size={6}
      >
        <CircularProgressLabel mx={"auto"}>
          {isPaused ? (
            <PlayIcon
              size={10}
              style={{ marginLeft: "auto", marginRight: "auto" }}
              cursor={"pointer"}
              onClick={() => {
                if (ref.current?.paused) {
                  ref.current?.play();
                  setPaused(false);
                } else {
                  ref.current?.pause();
                  setPaused(true);
                }
              }}
            />
          ) : (
            <PauseIcon
              color="hsl(var(--primary))"
              size={10}
              style={{ marginLeft: "auto", marginRight: "auto" }}
              cursor={"pointer"}
              onClick={() => {
                if (ref.current?.paused) {
                  ref.current?.play();
                  setPaused(false);
                } else {
                  ref.current?.pause();
                  setPaused(true);
                }
              }}
            />
          )}
        </CircularProgressLabel>
      </CircularProgress>
      {/* <Slider max={duration} value={track} min={0} colorScheme="primary" defaultValue={0}>
                <SliderTrack>
                    <SliderFilledTrack />
                </SliderTrack>
                <SliderThumb />
            </Slider> */}
    </Flex>
  );
}

export default function AuditLogs() {
  const logs = useAppSelector((state) => state.app.logs.list);
  const log = useAppSelector((state) => state.app.logs.get);
  const metadata = useAppSelector((state) => state.app.voices.list.metadata);
  const dispatch = useAppDispatch();
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(15);
  const toast = useToast();
  const [assistantId, setAssistantId] = useState<string | undefined>(undefined)
  const [selectedAgent, setSelectedAgent] = useState<{
    label?: string,
    value?: string
  }>({})
  const [selectedDates, setSelectedDates] = useState([
    moment().startOf("month").toDate(),
    moment().endOf("year").toDate(),
  ]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const organization = useAppSelector(state => state.app.organizations.get)
  const [range, setRange] = useState<moment.unitOfTime.StartOf>("day")
  const assistants = useAppSelector((state) => state.app.platform.agents.list)
  const wavesurfer = useRef<WaveSurfer | null>(null)
  const isPlaying = useRef<boolean>(false)
  const [playing, setPlaying] = useState(false)
  const timer = useRef<NodeJS.Timeout | number | null>(null)
  const { log: logId } = useParams()

  useEffect(() => {
    if (!assistants.data.length && organization.data.id) {
      dispatch(loadAgents(organization.data.id!, page, size, '', {}))
    }
  }, [organization, page, size])

  useEffect(() => {
    if (organization.data?.providers?.vapi?.api_key) {
      const key = organization.data?.id
      dispatch(getAllLogs(key, selectedAgent.value))
    }
  }, [organization, selectedAgent])

  useEffect(() => {
    if (assistantId && organization.data?.providers?.vapi?.api_key) {
      const key = organization.data?.id
      dispatch(getSpecificLog(key, assistantId))
    }
  }, [assistantId])

  useEffect(() => {
    if (logId) {
      setAssistantId(logId)
    } else if (logs.data?.length) {
      setAssistantId(logs.data[0].id)
    }
  }, [ logs ])

  return (
    <Page subtitle="Find all your assistant communications here" title="Conversations">
      <Drawer
        size={"xl"}
        onClose={() => {
          onClose();
        }}
        isOpen={isOpen}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerBody p={2}>
          <Flex display={logs.state === "success" && !logs.data.length ? "none" : "flex"} overflow={"scroll"} h={'calc(100vh)'} flex={0.7}>
          <Stack p={4} flex={0.65} borderRight={"1px solid #e4e4e4"}>
            <Box mb={3}>
              <Text fontWeight={600}>Recording</Text>
              {log.data.stereoRecordingUrl ? <Box>
                <WavesurferPlayer
                waveColor={"violet"}
                progressColor={"purple"}
                cursorWidth={1}
                url={log.data.stereoRecordingUrl}
                cursorColor={"#e4e4e4"}
                height={50}
                normalize={true}
                autoScroll={true}
                //@ts-ignore
                onReady={(w) => {
                  wavesurfer.current = w
                }}
                onPlay={() => {
                  isPlaying.current = true
                }}
                onPause={() => {
                  isPlaying.current = false
                }}
                backend={"WebAudio"}
                splitChannels={[{
                    progressColor: "#fde047",
                    waveColor: "#854d0e"
                }, {
                    progressColor: "#67e8f9",
                    waveColor: "#115e59"
                }]} plugins={[
                  Timeline.create(),
                  Regions.create(),
                ]} />
                <Flex align={"center"} mt={4} justify={"space-between"}>
                  <IconButton onClick={() => {
                    wavesurfer.current?.playPause()
                  }} variant={"outline"} size={"sm"} aria-label="" icon={wavesurfer.current?.isPlaying() ? <PauseIcon size={20} /> : <PlayIcon size={20} />} />
                  <IconButton onClick={() => {
                    window.open(`${process.env.REACT_APP_API_V2_URL}/agent/organization/${organization.data.id}/conversations/${log.data.id}/recording`, "_blank")
                  }} variant={"outline"} size={"sm"} aria-label="" icon={<DownloadIcon size={20} />} />
                </Flex>
              </Box> : <Text fontSize={"sm"} color={"gray"}>No Recording available</Text>}
            </Box>
            <Tabs size={"sm"} variant={"enclosed"} colorScheme="primary" isLazy>
              <TabList gap={log.state === "loading" ? 2 : 0} borderColor={log.state === "loading" ? "transparent" : "inherit"}>
                <Skeleton rounded={8} isLoaded={log.state === "success"}>
                  <Tab fontWeight={600} gap={2}>
                    <SubtitlesIcon size={16} />
                    Transcripts
                  </Tab>
                </Skeleton>
                <Skeleton rounded={8} isLoaded={log.state === "success"}>
                  <Tab fontWeight={600} gap={2}>
                    <LineChartIcon size={16} />
                    Analysis
                  </Tab>
                </Skeleton>
              </TabList>
              <TabPanels>
                <TabPanel px={0}>
                  <Skeleton rounded={8} isLoaded={log.state === "success"}>
                    <Stack p={4} borderRadius={8} border={"1px solid #e4e4e4"}>
                      <Text fontWeight={"bold"} fontSize={"sm"}>Transcript</Text>
                      {(log.data?.messages || []).filter((v: any) => v.role !== "system").map((message: any) => (
                        <Text fontSize={"sm"}>
                          <Text fontWeight={600} as={"span"}>{message.role === "bot" ? "AI" : "User"}: </Text>
                          <Text as={"span"}>{message.message}</Text>
                        </Text>
                      ))}
                      {(log.data?.messages || []).length === 0 ? <Text fontSize={"sm"}>No Transcript</Text> : <></>}
                    </Stack>
                  </Skeleton>
                </TabPanel>
                <TabPanel px={0}>
                  <Stack p={4} borderRadius={8} border={"1px solid #e4e4e4"}>
                    <Skeleton rounded={8} isLoaded={log.state === "success"}>
                      <Stack gap={0} pb={3} fontSize={"sm"} borderBottom={"1px solid #e4e4e4"}>
                        <Text fontWeight={600}>Summary</Text>
                        <Text>{log.data?.analysis?.summary || 'No summary'}</Text>
                      </Stack>
                    </Skeleton>
                    <Skeleton rounded={8} isLoaded={log.state === "success"}>
                      <Stack gap={0} pb={3} fontSize={"sm"} borderBottom={"1px solid #e4e4e4"}>
                        <Text fontWeight={600}>Conversation Success Evaluation</Text>
                        <Text>{log.data?.analysis?.successEvaluation || 'None'}</Text>
                      </Stack>
                    </Skeleton>
                    <Skeleton rounded={8} isLoaded={log.state === "success"}>
                      <Stack gap={0} pb={3} fontSize={"sm"} borderBottom={"1px solid #e4e4e4"}>
                        <Text fontWeight={600}>Data</Text>
                        <Text fontSize={"xs"} color={"gray"}>This is the data extracted based on the Conversation.</Text>
                        <JsonView value={log.data?.analysis?.structuredData || {}} style={githubDarkTheme} />
                      </Stack>
                    </Skeleton>
                  </Stack>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Stack>
          <Stack  flex={0.35} p={4} py={4}>
           <Skeleton hidden rounded={8} isLoaded={log.state === "success"}>
            <Stack pb={3} fontSize={"sm"} borderBottom={"1px solid #e4e4e4"}>
                <Text fontWeight={600}>ID</Text>
                <Text>{log.data.id}</Text>
              </Stack>
           </Skeleton>
            <Skeleton rounded={8} isLoaded={log.state === "success"}>
              <Stack pb={3} fontSize={"sm"} borderBottom={"1px solid #e4e4e4"}>
                <Text fontWeight={600}>Ended Reason</Text>
                <Text>{generateCanonicalNames(log.data?.endedReason)}</Text>
              </Stack>
            </Skeleton>
            <Skeleton rounded={8} isLoaded={log.state === "success"}>
            <Stack pb={3} fontSize={"sm"} borderBottom={"1px solid #e4e4e4"}>
              <Text fontWeight={600}>Agent Name</Text>
              <Text>{log.data?.assistant?.name}</Text>
            </Stack>
            </Skeleton>
            <Skeleton rounded={8} isLoaded={log.state === "success"}>
            <Stack pb={3} fontSize={"sm"} borderBottom={"1px solid #e4e4e4"}>
              <Text fontWeight={600}>User Phone Number</Text>
              <Text>{log.data.customer?.number || "Unknown number"}</Text>
            </Stack>
            </Skeleton>
            <Skeleton rounded={8} isLoaded={log.state === "success"}>
            <Stack pb={3} fontSize={"sm"} borderBottom={"1px solid #e4e4e4"}>
              <Text fontWeight={600}>Phone Number</Text>
              <Text>{log.data.phoneNumber || "Unknown"}</Text>
            </Stack>
            </Skeleton>
            <Skeleton rounded={8} isLoaded={log.state === "success"}>
            <Stack pb={3} fontSize={"sm"} borderBottom={"1px solid #e4e4e4"}>
              <Text fontWeight={600}>Conversation Channel</Text>
              <Text>{_.capitalize(log.data.type || '')}</Text>
            </Stack>
            </Skeleton>
            <Skeleton rounded={8} isLoaded={log.state === "success"}>
            <Stack pb={3} fontSize={"sm"} borderBottom={"1px solid #e4e4e4"}>
              <Text fontWeight={600}>Started At</Text>
              <Text>{moment(log.data.startedAt).format("DD MMM, YYYY, HH:mm A")}</Text>
            </Stack></Skeleton>
            <Skeleton rounded={8} isLoaded={log.state === "success"}>
            <Stack pb={3} fontSize={"sm"} borderBottom={"1px solid #e4e4e4"}>
              <Text fontWeight={600}>Ended At</Text>
              <Text>{moment(log.data.endedAt).format("DD MMM, YYYY, HH:mm A")}</Text>
            </Stack>
            </Skeleton>
          </Stack>
        </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
      {/* <Flex mb={4} ml={3} gap={2}>
        <RangeDatepicker
          configs={{
            dateFormat: "dd MMM",
          }}
          propsConfigs={{
            dayOfMonthBtnProps: {
              selectedBtnProps: {
                colorScheme: "primary",
                background: "primary.400",
                color: "white",
                rounded: "full",
              },
              isInRangeBtnProps: {
                colorScheme: "primary",
                background: "primary.400",
                rounded: "full",
                color: "white",
              },
            },
            inputProps: {
              rounded: "full",
              w: 120,
              p: 0,
              margin: 0,
              border: 0,
              outline: 0,
              textAlign: "end",
              cursor: "pointer",
              isReadOnly: true,
              _focusVisible: {
                border: 0,
              },
            },
          }}
          selectedDates={selectedDates}
          onDateChange={setSelectedDates}
        />
      </Flex> */}
      {!organization.data.providers?.vapi?.api_key ? <Center h={"80vh"}>
        <Stack align={"center"}>
            <Building2Icon size={30} />
            <Text fontWeight={600} fontSize={"lg"}>We are setting up your organisation</Text>
            <Text>Kindly re-visit after 24 hours</Text>
        </Stack>
      </Center> : <Stack py={3} className="border">
      <Stack mb={2} ml={4} w={200} gap={1}>
          <Text fontSize={"sm"}>
            Choose agent
          </Text>
          <Select
            colorScheme="primary"
            chakraStyles={dropdownStyles}
            components={dropdownComponents}
            size={"sm"}
            variant="outline"
            value={selectedAgent}
            onChange={(v) => {
              // @ts-ignore
              setSelectedAgent(v)
            }}
            options={assistants.data.map((d: any) => ({
              label: d.name,
              value: d.provider_id
            }))}
          />
        </Stack>
        <Flex>

        <Table className="rounded-sm border border-separate table-fixed border-spacing-0">
          <TableHeader className="!bg-slate-50">
            <TableRow>
              <TableHead className="w-[300px] font-semibold">Id</TableHead>
              <TableHead className="font-semibold">Type</TableHead>
              <TableHead colSpan={2} className="font-semibold">Ended Reason</TableHead>
              <TableHead className="font-semibold">Call Sentiment</TableHead>
              <TableHead className="font-semibold">Recording</TableHead>
              <TableHead className="font-semibold text-right pr-6">Actions</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {logs.data.map((call: any) => {
              const score = call?.analysis?.structuredData?.score
              return (
                <TableRow className="cursor-pointer" onClick={() => {
                  setAssistantId(call.id)
                  onOpen()
                }}>
                  <TableCell className="font-medium border-b">{call.id}</TableCell>
                  <TableCell className="border-b">{call.type}</TableCell>
                  <TableCell colSpan={2}  className="border-b">{<Badge variant={generateCanonicalVariants(call.endedReason) as any}>{generateCanonicalNames(call.endedReason)}</Badge>}</TableCell>
                  <TableCell className="font-semibold border-b">{<Badge
                        variant="transparent"
                        className={"text-sm text-medium gap-2"}
                      >
                        {score > 7 ? (
                          <ZapIcon size={16} fill="#22c55e" stroke={"#22c55e"} />
                        ) : (
                          <div
                            className={`w-2.5 h-2.5 rounded-sm ${
                              score > 7
                                ? "bg-green-500"
                                : score > 5
                                ? "bg-blue-500"
                                : score > 3
                                ? "bg-yellow-500"
                                : "bg-red-500"
                            }`}
                          />
                        )}
                        {score > 7
                          ? "Very Positive"
                          : score > 5
                          ? "Positive"
                          : score > 3
                          ? "Good"
                          : score > 1
                          ? "Poor"
                          : "Very Poor"}
                      </Badge>}</TableCell>
                  <TableCell className="font-semibold pr-6 border-b">
                   {call.recordingUrl ? <Flex alignItems={"center"} gap={1}><TableAudio url={call.recordingUrl} onInteraction={(state) => {}} /><Text>2 mins</Text></Flex> : <Text className="whitespace-nowrap text-muted-foreground"></Text>}
                  </TableCell>
                  <TableCell className="text-right pr-6 border-b">
                    <IconButton onClick={() => {
                      window.open(`${process.env.REACT_APP_API_V2_URL}/agent/organization/${organization.data.id}/conversations/${log.data.id}/recording`, "_blank")
                    }} variant={"outline"} size={"xs"} aria-label="" icon={<DownloadIcon size={14} />} />
                  </TableCell>
                </TableRow>
              )
            })}
            {logs.state === "loading" ? <>
            {[...Array(18)].map((e: any) => {
              return ( <TableRow>
               <TableCell ><Skeleton h={4} rounded={8} /></TableCell>
               <TableCell><Skeleton h={4} rounded={8} /></TableCell>
               <TableCell colSpan={2} ><Skeleton h={4} rounded={8} /></TableCell>
               <TableCell><Skeleton h={4} rounded={8} /></TableCell>
               <TableCell><Skeleton h={4} rounded={8} /></TableCell>
               <TableCell><Skeleton h={4}  rounded={8} /></TableCell>
           </TableRow>)
            })}
             
              
            
            </> : <></>}
          </TableBody>
        </Table>
        {logs.state === "loading" ? <></> : logs.state === "success" && !logs.data.length ? <>
        <Center h={'calc(100vh - 54px)'}borderRight={"1px solid #e4e4e4"} flex={1}>
          <Stack align={"center"}>
              <PhoneOutgoingIcon size={30} />
              <Text fontWeight={600} fontSize={"lg"}>
                No conversations found
              </Text>
              <Text>Your logs will show up as soon as you speak with any agent</Text>
            </Stack>
        </Center>
        </> : <Stack h={'calc(100vh - 54px)'} overflow={"scroll"} borderRight={"1px solid #e4e4e4"} flex={0.3}>
        {logs.data.map((d: any, i: number) => (
            <Stack
              background={d.id === assistantId ? 'var(--chakra-colors-primary-50)' : 'white'}
              p={4}
              py={2}
              onClick={() => {
                setAssistantId(d.id)
              }}
              cursor={"pointer"}
              borderBottom={"1px solid #e4e4e4"}
              _hover={{
                backgroundColor: d.id === assistantId ? 'var(--chakra-colors-primary-50)' : "#fcfbff",
                borderRadius: 8
              }}
            >
              <Flex justifyContent={"space-between"}>
                <Box>
                  <Text>{d.assistant?.name}</Text>
                  <Flex mt={2} gap={2} flexWrap={"wrap"}>
                    <Tag colorScheme="primary" variant={"solid"} size={"sm"}>
                      {d.type === "webCall" ? <> <TagLeftIcon as={GlobeIcon} boxSize={2.5} />
                      <TagLabel>Web</TagLabel></> : <><TagLeftIcon as={PhoneIcon} boxSize={2.5} />
                      <TagLabel>Call</TagLabel></>}
                    </Tag>
                    <Tag colorScheme={d.type === "outboundPhoneCall" ? "red" : "teal"} variant={"solid"} size={"sm"}>
                      <TagLeftIcon as={CircleIcon} boxSize={2.5} />
                      {d.type === "outboundPhoneCall" ? <TagLabel>Outbound</TagLabel> : <TagLabel>Inbound</TagLabel>}
                    </Tag>
                    {/* <Tag colorScheme="purple" variant={"solid"} size={"sm"}>
                      <TagLabel>${Number(d.cost || 0).toFixed(2)}</TagLabel>
                    </Tag> */}
                  </Flex>
                </Box>
                <Flex gap={2}>
                  {/* <IconButton
                    aria-label=""
                    icon={<EllipsisIcon size={16} />}
                    variant={"outline"}
                    size={"xs"}
                  />
                  <IconButton
                    aria-label=""
                    icon={<DownloadCloudIcon size={16} />}
                    variant={"outline"}
                    size={"xs"}
                  /> */}
                </Flex>
              </Flex>
              {/* <Flex gap={2} alignItems={"center"}>
                    <Avatar
                      src="https://i.ibb.co/7bqv4xq/stacy.png"
                      size={"xs"}
                    />
                    <Text fontWeight={500}>Stacy</Text>
                  </Flex> */}
              <Text fontSize={"sm"}>{moment(d.createdAt).format("DD MMM YYYY, HH:mm a")}</Text>
              <Text></Text>
            </Stack>
          ))}
        </Stack>}
        {/*  */}
      </Flex></Stack>}
    </Page>
  );
}
