// Constants for tasks
export const SET_CREATE_TASKS_LOADING = "tasks/create/loading";
export const SET_CREATE_TASKS_SUCCESS = "tasks/create/success";
export const SET_CREATE_TASKS_ERROR = "tasks/create/error";
export const SET_UPDATE_TASKS_LOADING = "tasks/update/loading";
export const SET_UPDATE_TASKS_SUCCESS = "tasks/update/success";
export const SET_UPDATE_TASKS_ERROR = "tasks/update/error";
export const SET_DELETE_TASKS_LOADING = "tasks/delete/loading";
export const SET_DELETE_TASKS_SUCCESS = "tasks/delete/success";
export const SET_DELETE_TASKS_ERROR = "tasks/delete/error";
export const SET_GET_TASKS_LOADING = "tasks/get/loading";
export const SET_GET_TASKS_SUCCESS = "tasks/get/success";
export const SET_GET_TASKS_ERROR = "tasks/get/error";
export const SET_LIST_TASKS_LOADING = "tasks/list/loading";
export const SET_LIST_TASKS_SUCCESS = "tasks/list/success";
export const SET_LIST_TASKS_ERROR = "tasks/list/error";
