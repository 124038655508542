import { Center, Spinner, Stack, Text } from "@chakra-ui/react";
import { useAppDispatch, useAppSelector } from "../../lib/hooks";
import { getOrganization, listOrganizations } from "../../lib/app/organization/thunk";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { loadConfig, loadUserProfile } from "../../lib/app/platform/thunk";

export default function Loader () {
    const dispatch = useAppDispatch()
    const organizations = useAppSelector((state) => state.app.organizations.list)
    const navigate = useNavigate()
    const profile = useAppSelector(state => state.app.platform.profile)

    useEffect(() => {
        dispatch(loadConfig())
        dispatch(loadUserProfile())
    }, [])

    useEffect(() => {
        if (profile.state === "success" && profile.data.id) {
            dispatch(listOrganizations(profile.data.id))
        }
    }, [ profile ])

    useEffect(() => {
        if (organizations.state === "error") {
            navigate('/welcome')   
        }
    }, [ organizations ])

    useEffect(() => {
        if (organizations.state === "success" && organizations.data?.length) {
            navigate(`/app/${organizations.data[0].id}/home`)
        } else if (organizations.state === "error" || (organizations.state === "success" && !organizations.data?.length)) {
            navigate("/welcome")
        }
    }, [ organizations ])

    return (
        <Center height={"100vh"}>
            <Stack alignItems={"center"}>
                <Spinner size={"sm"} />
                <Text>Please wait</Text>
            </Stack>
        </Center>
    )
}