import { VapiAPIManager } from "../../../utils/vapi.manager";
import { AppDispatch } from "../../store";
import { setLogsGetLoading, setLogsGetSuccess, setLogsListLoading, setLogsListSuccess } from "./actions";

export const getAllLogs = (organization_id: string, assistant?: string) => async (dispatch: AppDispatch) => {
    dispatch(setLogsListLoading())
    try {
        const manager = new VapiAPIManager(organization_id)
        const response = await manager.getCallLogs(assistant)
        dispatch(setLogsListSuccess(response))
    } catch (err) {
        
    }
}

export const getSpecificLog = (organization_id: string, log: string) => async (dispatch: AppDispatch) => {
    dispatch(setLogsGetLoading())
    try {
        const manager = new VapiAPIManager(organization_id)
        const response = await manager.getSpecificCallLog(log)
        dispatch(setLogsGetSuccess(response))
    } catch (err) {

    }
}