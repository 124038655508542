// Constants for task_runs
export const SET_CREATE_TASK_RUNS_LOADING = "task_runs/create/loading";
export const SET_CREATE_TASK_RUNS_SUCCESS = "task_runs/create/success";
export const SET_CREATE_TASK_RUNS_ERROR = "task_runs/create/error";
export const SET_UPDATE_TASK_RUNS_LOADING = "task_runs/update/loading";
export const SET_UPDATE_TASK_RUNS_SUCCESS = "task_runs/update/success";
export const SET_UPDATE_TASK_RUNS_ERROR = "task_runs/update/error";
export const SET_DELETE_TASK_RUNS_LOADING = "task_runs/delete/loading";
export const SET_DELETE_TASK_RUNS_SUCCESS = "task_runs/delete/success";
export const SET_DELETE_TASK_RUNS_ERROR = "task_runs/delete/error";
export const SET_GET_TASK_RUNS_LOADING = "task_runs/get/loading";
export const SET_GET_TASK_RUNS_SUCCESS = "task_runs/get/success";
export const SET_GET_TASK_RUNS_ERROR = "task_runs/get/error";
export const SET_LIST_TASK_RUNS_LOADING = "task_runs/list/loading";
export const SET_LIST_TASK_RUNS_SUCCESS = "task_runs/list/success";
export const SET_LIST_TASK_RUNS_ERROR = "task_runs/list/error";
