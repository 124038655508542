import { 
    SET_LOGS_LIST_LOADING,
    SET_LOGS_LIST_SUCCESS,
    SET_LOGS_LIST_ERROR,
    SET_LOGS_GET_LOADING,
    SET_LOGS_GET_SUCCESS,
    SET_LOGS_GET_ERROR
} from './constants';

// Logs List Actions
export function setLogsListLoading() {
    return {
        type: SET_LOGS_LIST_LOADING
    }
}

export function setLogsListSuccess(payload: any, metadata?: any) {
    return {
        type: SET_LOGS_LIST_SUCCESS,
        payload,
        metadata
    }
}

export function setLogsListError(error: any) {
    return {
        type: SET_LOGS_LIST_ERROR,
        error
    }
}

// Logs Get Actions
export function setLogsGetLoading() {
    return {
        type: SET_LOGS_GET_LOADING
    }
}

export function setLogsGetSuccess(payload: any, metadata?: any) {
    return {
        type: SET_LOGS_GET_SUCCESS,
        payload,
        metadata
    }
}

export function setLogsGetError(error: any) {
    return {
        type: SET_LOGS_GET_ERROR,
        error
    }
}
